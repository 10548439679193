import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
const queryString = require("query-string");

import { ssTokenAuth } from "@utilities";
import { PATH_HOME, PATH_SHARE_ORDER_SUCCESS } from "@routes";
import { useDispatch } from "react-redux";
import { openToast } from "@redux";
import { DURATION_TIME } from "@constants";

export const ShareSuccess = () => {
    //page hooks
    const { token } = useParams<{ token: string }>();
    const history = useHistory();
    const dispatch = useDispatch();

    // set TokenInfoAuth vào sessionStorage
    useEffect(() => {
        // kiểm tra xem đã thanh toán thành công chưa , nếu chưa thì đẩy về màn login
        const params = window.location.search.substring(1);
        const paramsObj = queryString.parse(params);
        if (paramsObj.status && paramsObj.status === "1") {
            const tokenInfoAuth = window.atob(token);
            ssTokenAuth.setItem(tokenInfoAuth);
            history.push(PATH_SHARE_ORDER_SUCCESS);
        } else {
            const message = `Booking failed. Please contact support@freelancetravel.com to finalise this booking.`;
            dispatch(
                openToast({
                    open: true,
                    type: "error",
                    message,
                    autoHideDuration: DURATION_TIME,
                })
            );
            // logger.error("Booking fail", message);
            history.push(PATH_HOME);
        }
    }, []);
    return <></>;
};
