import styled from "styled-components";

import { IconHeart } from "@components";
import { breakpoint } from "@utilities";

interface IProps {
    onClick: () => void;
    active: boolean;
}

interface IWrapperProps {
    active: boolean;
}

export const HeartCommon = (props: IProps) => {
    const { onClick, active } = props;
    return (
        <Wrapper active={active} onClick={onClick}>
            <span className="iconWrapper">
                <IconHeart />
            </span>
        </Wrapper>
    );
};

const Wrapper = styled.div<IWrapperProps>`
    width: 42px;
    height: 54px;
    background-color: ${(props) => props.theme.colors.main};
    position: relative;
    cursor: pointer;

    ${breakpoint.breakMobileMedium`
        width: 34px;
        height: 42px;
    `}

    ${breakpoint.breakIpadPro`
        width: 42px;
        height: 54px;
    `}

    .iconWrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        svg {
            fill: ${(props) => (props.active ? "#ffffff" : "none")};

            ${breakpoint.breakMobileMedium`
                width: 24px;
            `}

            ${breakpoint.breakTablet`
                width: 18px;
            `}

            ${breakpoint.breakIpadPro`
                width: 24px;
            `}

            path {
                stroke: #ffffff;
            }
        }
    }
`;
