import { Formik } from "formik";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import { LoginFormComponent, StyledLink, LoginLogo } from "@components";
import { ILogin } from "@interfaces";
import { loginSchema } from "@validations";
import { login, openToast, toggleLoading } from "@redux";
import { lsEmail } from "@utilities";
import { DURATION_TIME } from "@constants";

export const Login = () => {
    // const history = useHistory();
    const dispatch = useDispatch();

    const initialValues: ILogin = {
        username: process.env.REACT_APP_EMAIL || "",
        password: process.env.REACT_APP_PASSWORD || "",
    };

    return (
        <>
            <LoginLogo src="/images/logos/FreelanceTravel_Primary_Logo_(1).svg" />
            <Formik
                initialValues={initialValues}
                validationSchema={loginSchema}
                onSubmit={async (values, { setSubmitting, setErrors }) => {
                    try {
                        dispatch(toggleLoading(true));
                        // const res = await authApi.login({
                        //     username: "james.nguyen@adamodigital.com",
                        //     password: "4T5BcfW!2FMi4aC",
                        // });
                        const res = await dispatch(login(values));
                        // @ts-ignore
                        unwrapResult(res);
                        // window.history.pushState(null, document.title, `/`);
                        //@ts-ignore
                        if (!res.payload.access_token) {
                            dispatch(
                                openToast({
                                    message: "Please check username and password are correct",
                                    type: "error",
                                    autoHideDuration: DURATION_TIME,
                                })
                            );
                            //@ts-ignore
                            setErrors({
                                password: "Please check username and password are correct",
                            });
                        } else {
                            lsEmail.setItem(values.username);
                        }
                    } catch (error: any) {
                        const { code } = error;

                        if (code === 401) {
                            setErrors({ username: error.message });
                        } else if (code === 422) {
                            //validation error
                            setErrors(error.errors);
                        }
                    } finally {
                        setSubmitting(false);
                        dispatch(toggleLoading(false));
                    }
                }}
            >
                {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => {
                    return (
                        <LoginFormComponent
                            values={values}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            handleSubmit={handleSubmit}
                            isSubmitting={isSubmitting}
                        />
                    );
                }}
            </Formik>
            <StyledLink>
                Don't have an account? <a href="https://freelancetravel.com/">Sign up</a>, to start
                selling today.
            </StyledLink>
        </>
    );
};
