import { Link } from "react-router-dom";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import { unwrapResult } from "@reduxjs/toolkit";

import {
    BookingItemComponent,
    BreadcrumbsCommon,
    ClipLoaderCommon,
    MenuAccountComponent,
} from "@components";
import { AppModuleLayout } from "@layouts";
import { PATH_BOOKING, PATH_BOOKING_DETAIL, PATH_HOME } from "@routes";
import { breakpoint, useWindowDimensions } from "@utilities";
import {
    getListBooking,
    selectBooking,
    selectStatusApp,
    useAppSelector,
    getListBookingCancelRequest,
} from "@redux";
import { FilterBookingModule, PaginationModule } from "@module";
import { IFilterBooking, IListBookings, IPagination } from "@interfaces";
import { PAGINATION } from "@constants";

const FILTER_BY_DATE = 1;
const FILTER_BY_COMMISSION_DATE = 2;
const FILTER_BY_COMMISSION_DATE_DAY = 10;

const optionModels: IFilterBooking[] = [
    {
        title: "Filter By Booking Date",
        value: FILTER_BY_DATE,
    },
    {
        title: "Filter By Commission Payout Date",
        value: FILTER_BY_COMMISSION_DATE,
    },
];

interface IListBookingParam {
    page: number;
    limit: number;
    dateFrom?: Date;
    dateTo?: Date;
    filterType: number;
    search?: string;
}

export const Booking = () => {
    // page redux
    const statusApp = useAppSelector(selectStatusApp).status;
    const { listBooking } = useAppSelector(selectBooking);
    // page hooks
    const { width } = useWindowDimensions();
    const dispatch = useDispatch();
    //page state
    const [bookings, setBookings] = useState<IListBookings[]>([]);
    const [listBookingParams, setListBookingParams] = useState<IListBookingParam>({
        page: 1,
        limit: PAGINATION,
        filterType: FILTER_BY_DATE,
    });
    const [data, setData] = useState<IListBookings[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    // page variable
    const listPagePoints: IPagination[] = [];
    const pages = Math.ceil(bookings.length / PAGINATION);
    const breadcrumbsModel = [
        {
            title: "Home",
            link: PATH_HOME,
        },
        {
            title: "Bookings",
            link: PATH_BOOKING,
        },
    ];

    for (let i = 1; i <= pages; i++) {
        listPagePoints.push({ id: i });
    }

    // gọi list booking mới nhất.
    useEffect(() => {
        fetchBookings();
    }, []);

    useEffect(() => {
        setData(searchList(listBookingParams));
    }, [listBooking]);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newListBookingParams = {
            ...listBookingParams,
            search: event.target?.value,
        };
        setListBookingParams(newListBookingParams);
        setData(searchList(newListBookingParams));
    };
    const handleChangeFilterDate = (
        event: React.ChangeEvent<{ name?: string; value: unknown }>
    ) => {
        const newListBookingParams = {
            ...listBookingParams,
            filterType: event.target?.value as number,
        };
        setListBookingParams(newListBookingParams);
        setData(searchList(newListBookingParams));
    };

    // xử lý khi dateForm thay đổi
    const handleChangeDateFrom = (date: Date) => {
        const newListBookingParams: IListBookingParam = { ...listBookingParams, dateFrom: date };
        if (date.toDateString() === listBookingParams.dateFrom?.toDateString()) {
            // WHAT: clear old date
            delete newListBookingParams.dateFrom;
        }
        setListBookingParams(newListBookingParams);
        setData(searchList(newListBookingParams));
    };
    // xử lý khi dateTo thay đổi

    const handleChangeDateTo = (date: Date) => {
        const newListBookingParams: IListBookingParam = { ...listBookingParams, dateTo: date };
        if (date.toDateString() === listBookingParams.dateTo?.toDateString()) {
            // WHAT: clear old date
            delete newListBookingParams.dateTo;
        }
        setListBookingParams(newListBookingParams);
        setData(searchList(newListBookingParams));
    };

    const handleSetCurrentPage = (page: number) => {
        const newListBookingParams: IListBookingParam = { ...listBookingParams, page };
        setListBookingParams(newListBookingParams);
        setData(searchList(newListBookingParams));
    };

    const handleLoadMoreMobile = () => {
        if (data.length >= bookings.length) return;
        if (bookings.length - data.length < PAGINATION) {
            setData([...data, ...bookings.slice(data.length)]);
        } else {
            setData([...data, ...bookings.slice(data.length, data.length + PAGINATION)]);
        }
    };

    const clearDate = () => {
        const newListBookingParams: IListBookingParam = {
            ...listBookingParams,
            dateFrom: undefined,
            dateTo: undefined,
        };
        setListBookingParams(newListBookingParams);
        setData(searchList(newListBookingParams));
    };

    const fetchBookings = async () => {
        try {
            const res = await dispatch(getListBooking());
            await dispatch(getListBookingCancelRequest());
            //@ts-ignore
            unwrapResult(res);
            //@ts-ignore
            setBookings(res.payload.data);
        } catch {
            setBookings([]);
        } finally {
            setLoading(false);
        }
    };

    const searchList = (params: IListBookingParam) => {
        const { limit, page, dateFrom, dateTo, search, filterType } = params;
        let newBookings: IListBookings[] = [...listBooking];
        // WHAT: filter booking
        if (dateFrom || dateTo) {
            newBookings = newBookings.filter((booking) => {
                // WHAT: name, phone, order id and email check
                let passNameCheck = true;
                const keyword = search?.toLowerCase();
                if (keyword) {
                    passNameCheck = false;
                    const name = (booking.firstName + " " + booking.lastName).toLocaleLowerCase();
                    if (name.includes(keyword)) {
                        passNameCheck = true;
                    } else if (booking.email.includes(keyword)) {
                        passNameCheck = true;
                    } else if (booking.orderId?.toString().includes(keyword)) {
                        passNameCheck = true;
                    } else if (booking.phone?.toString().includes(keyword)) {
                        passNameCheck = true;
                    }
                }
                // WHAT: date check
                let passDateCheck = true;
                const bookingDate =
                    filterType === FILTER_BY_COMMISSION_DATE
                        ? moment(booking.latestTravelDate)
                        : moment(booking.datePurchased);
                const shiftToCommission =
                    filterType === FILTER_BY_COMMISSION_DATE ? -FILTER_BY_COMMISSION_DATE_DAY : 0;
                if (bookingDate.isValid()) {
                    passDateCheck = false;
                    if (dateFrom && dateTo) {
                        passDateCheck = bookingDate.isBetween(
                            moment(dateFrom).add(-1 + shiftToCommission, "days"),
                            moment(dateTo).add(shiftToCommission, "days")
                        );
                    } else if (dateFrom) {
                        passDateCheck = bookingDate.isSameOrAfter(
                            moment(dateFrom).add(-1 + shiftToCommission, "days")
                        );
                    } else if (dateTo) {
                        passDateCheck = bookingDate.isSameOrBefore(
                            moment(dateTo).add(shiftToCommission, "days")
                        );
                    } else {
                        passDateCheck = true;
                    }
                }

                return passDateCheck && passNameCheck;
            });
        } else {
            newBookings = newBookings.filter((booking) => {
                // WHAT: name, phone, order id and email check
                let passNameCheck = true;
                const keyword = search?.toLowerCase();
                if (keyword) {
                    passNameCheck = false;
                    const name = (booking.firstName + " " + booking.lastName).toLocaleLowerCase();
                    if (name.includes(keyword)) {
                        passNameCheck = true;
                    } else if (booking.email.includes(keyword)) {
                        passNameCheck = true;
                    } else if (booking.orderId?.toString().includes(keyword)) {
                        passNameCheck = true;
                    } else if (booking.phone?.toString().includes(keyword)) {
                        passNameCheck = true;
                    }
                }
                return passNameCheck;
            });
        }
        setBookings([...newBookings]);
        // WHAT: load page
        if (newBookings.length <= limit) {
            return newBookings;
        } else if (newBookings.length < limit * (page - 1)) {
            return [];
        } else {
            return newBookings.slice((page - 1) * limit, page * limit);
        }
    };

    return (
        <AppModuleLayout>
            {loading ? (
                <ClipLoaderCommon color="#31B4B9" size={70} margin={150} />
            ) : (
                <Main>
                    <div className="wrapper">
                        <div className="breadCrumbs">
                            <BreadcrumbsCommon data={breadcrumbsModel} />
                        </div>
                        <div className="subWrapper">
                            <div className="menu">
                                <MenuAccountComponent />
                            </div>
                            <div className="listItem">
                                {width >= 767 && <h2 className="heading">Bookings</h2>}
                                <FilterBookingModule
                                    data={bookings}
                                    handleChange={handleChangeFilterDate}
                                    optionModels={optionModels}
                                    filterBookingOption={listBookingParams.filterType}
                                    handleChangeDateFrom={handleChangeDateFrom}
                                    dateFrom={listBookingParams?.dateFrom || null}
                                    dateTo={listBookingParams?.dateTo || null}
                                    handleChangeDateTo={handleChangeDateTo}
                                    clearDate={clearDate}
                                />
                                <div className="searchBox">
                                    <div className="icon">
                                        <img src="/images/fi_search.png" alt="" />
                                    </div>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        value={listBookingParams.search}
                                        onChange={handleSearch}
                                    />
                                </div>
                                {bookings.length === 0 ? (
                                    listBookingParams.filterType === FILTER_BY_COMMISSION_DATE ? (
                                        <div className="searchBox">
                                            <p>
                                                There is no commission due for payment between the
                                                dates you have selected. Please note that commission
                                                will be paid out <b>within 10 days</b> from the{" "}
                                                <b>last travel date</b> on each order
                                            </p>
                                        </div>
                                    ) : (
                                        <div className="searchBox center">
                                            You have not made any bookings yet.
                                        </div>
                                    )
                                ) : width >= 767 ? (
                                    <div className="row">
                                        {data.map((item, index) => {
                                            return (
                                                <div className="col c-12 m-6 l-6" key={index}>
                                                    <Link
                                                        to={`${PATH_BOOKING_DETAIL}/${item.orderId}`}
                                                    >
                                                        <BookingItemComponent
                                                            data={item}
                                                            statusApp={statusApp}
                                                        />
                                                    </Link>
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <InfiniteScroll
                                        dataLength={data.length}
                                        next={handleLoadMoreMobile}
                                        hasMore={true}
                                        loader={<></>}
                                    >
                                        <div className="row">
                                            {data.map((item, index) => {
                                                return (
                                                    <div className="col c-12 m-6 l-6" key={index}>
                                                        <Link
                                                            to={`${PATH_BOOKING_DETAIL}/${item.orderId}`}
                                                        >
                                                            <BookingItemComponent
                                                                data={item}
                                                                statusApp={statusApp}
                                                            />
                                                        </Link>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </InfiniteScroll>
                                )}
                            </div>
                        </div>
                    </div>
                    {bookings.length > PAGINATION && width >= 767 && (
                        <div className="pagination">
                            <PaginationModule
                                onSetCurrentPage={handleSetCurrentPage}
                                listPagePoints={listPagePoints}
                                currentPage={listBookingParams.page}
                            />
                        </div>
                    )}
                </Main>
            )}
        </AppModuleLayout>
    );
};

const Main = styled.div`
    background-color: #f2f2f2;
    padding: 24px 20px 80px 20px;
    flex: 1;

    .wrapper {
        margin: 0 auto;

        .breadCrumbs {
            display: none;
            margin-bottom: 20px;
        }

        .subWrapper {
            .menu {
                display: none;
            }

            .heading {
                font-size: 28px;
                font-weight: 700;
                line-height: 36px;
                margin-bottom: 30px;
            }
        }
    }
    .listItem {
        position: relative;
        top: 0px;
        .searchBox {
            ${breakpoint.breakTablet`
                display: flex;
                font-size: 16px;    
            `}/* ${breakpoint.breakOnlyMobile`
                display: none;
            `} */
        }
    }

    .center {
        text-align: center;
        display: inherit !important;
    }

    .searchBox {
        display: flex;
        background-color: #fff;
        padding: 18px 23px;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 30px;

        ${breakpoint.breakTablet`
            display: none;
        `}

        .icon {
            margin-right: 17px;
        }

        input {
            flex: 1;

            &::placeholder {
                color: ${(props) => props.theme.colors.gray_3};
            }
        }
    }
    ${breakpoint.breakTablet`
        .wrapper {
            width: ${(p) => p.theme.widths.wide_700};

            .breadCrumbs {
                display: block;
            }

            .subWrapper {
                display: flex;
                .menu {
                    display: block;
                }

                .heading {
                    font-size: 21px;
                    line-height: 30px;
                    margin-bottom: 8px;
                }
            }
        }
    `}

    ${breakpoint.breakIpadPro`
        .wrapper {
            width: ${(p) => p.theme.widths.wide_900};
        }
    `}

    ${breakpoint.breakLarge`
        .wrapper {
            width: ${(p) => p.theme.widths.wide_1110};

            .breadCrumbs {
                margin-bottom: 30px;
            }

            .subWrapper {
                .heading {
                    font-size: 28px;
                    line-height: 36px;
                    margin-bottom: 30px;
                }
            }
        }
    `}

    .pagination {
        margin: auto;

        ${breakpoint.breakTablet`
            width: ${(p) => p.theme.widths.wide_700};
        `}

        ${breakpoint.breakIpadPro`
            width: ${(p) => p.theme.widths.wide_900};
        `}

        ${breakpoint.breakLarge`
            width: ${(p) => p.theme.widths.wide_1110};
        `}
    }
    .NotFound {
        font-size: 32px;
    }
`;
