import styled from "styled-components";

import { breakpoint } from "@utilities";

export const StyledNeedToKnow = styled.div`
    .listTags {
        display: flex;
        border: 1px solid ${(p) => p.theme.colors.gray_5};

        ${breakpoint.breakTablet`
            height: 60px;
        `}

        ${breakpoint.breakIpadPro`
            height: 79px;
        `}
        
        ${breakpoint.breakLarge`
            height: 79px;
        `}
    }

    .listContentWrapper {
        position: relative;

        &::before,
        &::after {
            content: "";
            position: absolute;
            left: 0;
            height: 25px;
            width: calc(100% - 7px);
            background-color: #fff;
        }

        &::before {
            top: 0;
        }

        &::after {
            bottom: 0;
        }
    }

    .listContents {
        height: 100%;
        max-height: 500px;
        overflow-y: scroll;
        padding: 25px;
        div {
            ${breakpoint.breakTablet`
                font-size: 75%;
            `}
            ${breakpoint.breakIpadPro`
                font-size: 100%;
            `}
        }
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            appearance: none;
            width: 7px;
        }

        &::-webkit-scrollbar-track {
            appearance: none;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            -webkit-appearance: none;
            appearance: none;
            background-color: rgba(0, 0, 0, 0.3);
            transition: 0.3s;
            border-radius: 5px;
        }
    }
`;
