const qs = require("query-string");

import { ISearch, IOptionSearch, IFareAvail } from "@interfaces";
import { diffDate } from "@utilities";
import axiosClient from "./axiosClient";

export const toursApi = {
    search: (searches: ISearch, option?: IOptionSearch) => {
        let queryString = qs.stringify(searches) ? qs.stringify(searches) : "all=\\s";
        if (searches.day !== undefined && Object.keys(searches).length === 1) {
            queryString = qs.stringify(searches) + "&all=\\s";
        } else if (searches.sortDirection && Object.keys(searches).length === 2) {
            // nếu chỉ có sortDirection
            queryString = qs.stringify(searches) + "&all=\\s";
        }
        const optionQuery = option ? "&" + qs.stringify(option) : "";
        const url = "/products?" + queryString + optionQuery;
        return axiosClient.get(url);
    },
    getById: (productId: number | string) => {
        const url = "/product/" + productId;
        return axiosClient.get(url);
    },
    getMediasById: (productId: number | string) => {
        const url = "/media/images/product/" + productId;
        return axiosClient.get(url);
    },
    getBookingDetail: (productPriceId: number | string) => {
        const url = "/bookingdetails/" + productPriceId;
        return axiosClient.get(url);
    },
    checkAvail: (params: IFareAvail) => {
        const startDate = new Date(params.from || new Date()).getDate();
        const startMonth = new Date(params.from || new Date()).toLocaleString("en-us", {
            month: "short",
        });
        const startYear = new Date(params.from || new Date()).getFullYear();
        const dateCount = diffDate(params.from || new Date(), params.to || new Date());

        const url = `/checkavailabilityrange/${params.productPricesDetailsId}/${
            params.bookingTime
        }/${startDate}-${startMonth}-${startYear}/${dateCount + 1}`;
        return axiosClient.get(url);
    },
};
