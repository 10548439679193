import styled from "styled-components";
import { Link } from "react-router-dom";

import { breakpoint } from "@utilities";

interface IProps {
    data: {
        title: string;
        link: string;
    }[];
}

export const BreadcrumbsCommon = (props: IProps) => {
    const { data } = props;
    return (
        <StyledContainer>
            {data.map((item, index) => {
                return index !== data.length - 1 ? (
                    <StyledLink to={item.link} className="breadcrumbsItem" key={index}>
                        <span>{item.title}</span>
                        <img src="/images/fi_chevron-right.png" alt="" />
                    </StyledLink>
                ) : (
                    <StyledLink to={item.link} className="breadcrumbsItem" key={index}>
                        <span className="active">{item.title}</span>
                    </StyledLink>
                );
            })}
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
`;

const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
    font-size: 10px;
    font-weight: 700;
    line-height: 22px;
    color: ${props => props.theme.colors.black};

    ${breakpoint.breakIpadPro`
        font-size: 14px;
    `}

    img {
        margin: 0;

        ${breakpoint.breakIpadPro`
            margin: 0 5px;
        `}

        ${breakpoint.breakLarge`
            margin: 0 12px;
        `}
    }

    .active {
        color: ${props => props.theme.colors.main};
    }
`;