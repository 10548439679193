import { BrowserRouter, useHistory, useLocation } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { Provider, useDispatch } from "react-redux";
import { useEffect } from "react";
import { PersistGate } from "redux-persist/integration/react";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const queryString = require("query-string");

import { ssPathname, theme, storageVersion } from "@utilities";
import { AuthLayout, AppLayout } from "@layouts";
import {
    store,
    persistor,
    useAppSelector,
    toggleSplash,
    setHomepage,
    getPaymentMethod,
    selectAuth,
    selectLoading,
    selectSplash,
    selectExternal,
    selectToast,
    closeToast,
    suppliers,
    setAgentInfo,
    fetchFavoriteTour,
} from "@redux";
import {
    PATH_HOME,
    PATH_LOGIN,
    normalPath,
    authPath,
    PATH_ACCOUNT,
    PATH_PAYMENT_FAIL,
} from "@routes";
import { LoadingCommon, SplashCommon } from "@components";
import { externalApi } from "@api";
import { phpHelper } from "./Utilities/phpHelper";

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function App() {
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <PersistGate loading={null} persistor={persistor}>
                    <div className="App ">
                        <BrowserRouter>
                            <Main />
                        </BrowserRouter>
                    </div>
                </PersistGate>
            </ThemeProvider>
        </Provider>
    );
}

function Main() {
    //hook
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    //redux state
    const { auth } = useAppSelector(selectAuth);
    const isLoading = useAppSelector(selectLoading).isLoading;
    const isDisplaySplash = useAppSelector(selectSplash).isSplash;
    const toast = useAppSelector(selectToast).toast;
    const { time, date } = useAppSelector(selectExternal);
    //page variable
    const token = auth?.access_token;
    const currentPathName = location.pathname;
    let isNormalRoute = false;
    let isAuthRoute = false;
    const prevPathname = ssPathname.getItem();

    // WHAT: check storage version, clear all data if have update about local storage
    const appVersion = storageVersion.getItem();
    const currentVersion = "2320061530";

    if (!appVersion || appVersion !== currentVersion) {
        localStorage.clear();
        localStorage.removeItem("persist:root");
        dispatch({ type: "RESET" });
        storageVersion.setItem(currentVersion);
    }
    const params = window.location.search.substring(1);
    const paramsObj = queryString.parse(params);

    //return old page if logout
    useEffect(() => {
        // WHAT: check normal path
        isNormalRoute =
            currentPathName === PATH_HOME
                ? true
                : normalPath.some((item: string) => currentPathName.includes(item));

        // WHAT: check a uth path
        isAuthRoute = authPath.some((item: string) => currentPathName.includes(item));

        // WHAT: check xem đã login lần nào chưa nếu chưa thì đẩy về màn term
        if (!token && isNormalRoute) {
            //check nếu payment lỗi khi chưa đăng nhập
            if (paramsObj.error) {
                history.push(PATH_PAYMENT_FAIL, paramsObj);
            } else {
                history.push(PATH_LOGIN);
            }
        }

        if (token && isAuthRoute) {
            history.push(PATH_HOME);
        }

        window.scrollTo({
            top: 0,
        });
    }, [currentPathName, token]);

    // WHAT: load splash first time
    useEffect(() => {
        (async () => {
            const newDate = new Date();

            // WHAT: update every day or first time
            if (!time || !date || (time < newDate.getTime() && date !== newDate.getDate())) {
                const data = await externalApi.getDataHomePage();
                dispatch(setHomepage(data.data));
            }
            const filter = null;
            // WHAT: dump params
            if (token) {
                fetchAgent();
                // WHAT: load supplier filter
                await dispatch(suppliers({ filter }));
                //reload payment method
                await dispatch(getPaymentMethod());

                // WHAT: fetch favorite
                await dispatch(fetchFavoriteTour());
            }
            dispatch(toggleSplash(false));
        })();
    }, [token]);

    const fetchAgent = async () => {
        if (prevPathname) {
            ssPathname.removeItem();
            history.push(prevPathname);
        }

        const agentInfo = await phpHelper.getAccount();
        const agentData = agentInfo?.data?.json;
        if (agentData && agentData.firstName !== "") {
            dispatch(setAgentInfo(agentData));
        } else {
            history.push(PATH_ACCOUNT);
        }
    };

    if (isDisplaySplash) {
        return <SplashCommon />;
    }

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }
        dispatch(closeToast());
    };

    return (
        <>
            {isLoading && <LoadingCommon />}
            {/* {token && loggedIn ? <AppLayout /> : <AuthLayout />} */}
            {token ? <AppLayout /> : <AuthLayout />}
            <Snackbar
                anchorOrigin={{
                    vertical: toast.vertical || "top",
                    horizontal: toast.horizontal || "right",
                }}
                open={toast.open}
                autoHideDuration={toast.autoHideDuration}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={toast.type}>
                    {toast.message}
                </Alert>
            </Snackbar>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
                limit={1}
            />
        </>
    );
}

export default App;
