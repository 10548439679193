import styled from "styled-components";
import { memo } from "react";
import { NavLink } from "react-router-dom";

import { StyledBookingItem, IconEllipsisHorizontal, IconCheck } from "@components";
import { ICart } from "@interfaces";
import { breakpoint } from "@utilities";
import { useAppSelector, selectStatusApp } from "@redux";
import { PATH_SAVED_CART } from "@routes";

interface IProps {
    dateFormat: string;
    togglePopUp: () => void;
    handleEdit: () => void;
    handleRemove: () => void;
    isShowPopUp: boolean;
    name: string;
    cart: ICart;
    isList?: boolean;
    id: string;
}

export const SavedCartMobileComponent = (props: IProps) => {
    const {
        dateFormat,
        togglePopUp,
        handleEdit,
        handleRemove,
        isShowPopUp,
        name,
        cart,
        id,
        isList,
    } = props;
    //redux state
    const statusApp = useAppSelector(selectStatusApp).status;

    return (
        <StyledBookingItem>
            <div className="date">
                <span>Date: {dateFormat}</span>
                {isList && (
                    <div className="btn-button">
                        <StyledPopup>
                            <button className="toggle" onClick={togglePopUp}>
                                <IconEllipsisHorizontal />
                            </button>
                            {isShowPopUp && (
                                <>
                                    <div className="overlay" onClick={togglePopUp}></div>
                                    <div className="popup">
                                        {cart.isShare && (
                                            <div className="desc">
                                                <p>
                                                    *This quote has a pending payment link. If you
                                                    edit the quote, the payment link will be
                                                    cancelled and you will need to send a new link
                                                    to your customer
                                                </p>
                                            </div>
                                        )}
                                        <div className="popup_container">
                                            <button className="editBtn" onClick={handleEdit}>
                                                <img src="/images/editSvg.svg" alt=""></img>{" "}
                                                <span>Edit</span>
                                            </button>
                                            <button className="deleteBtn" onClick={handleRemove}>
                                                <img src="/images/deleteSvg.svg" alt=""></img>{" "}
                                                <span>Delete</span>
                                            </button>
                                        </div>
                                        <div className="popup_cancel">
                                            <button onClick={togglePopUp}>Cancel</button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </StyledPopup>
                    </div>
                )}
            </div>
            {isList && (
                <>
                    <div className="info">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <NavLink to={`${PATH_SAVED_CART}/${id}`}>
                                            <h2 className="title">{name}</h2>
                                        </NavLink>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="field">Number of items</span>
                                    </td>
                                    <td></td>
                                    <td className="value">
                                        <span>
                                            {cart.carts.length === 1
                                                ? cart.carts.length + " item"
                                                : cart.carts.length + " items"}
                                        </span>
                                    </td>
                                </tr>
                                {statusApp && (
                                    <>
                                        <tr>
                                            <td>
                                                <span className="field">Total Net</span>
                                            </td>
                                            <td></td>
                                            <td className="value">
                                                <span>${cart.totalNet?.toFixed(2)}</span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <span className="field">Commission</span>
                                            </td>
                                            <td>
                                                <span className="commission">
                                                    {" "}
                                                    {Number.isInteger(cart.commissionPercent)
                                                        ? cart.commissionPercent
                                                        : cart.commissionPercent?.toFixed(2) || 0}
                                                    %
                                                </span>
                                            </td>
                                            <td className="value commission">
                                                <span>${cart.commission.toFixed(2)}</span>
                                            </td>
                                        </tr>
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="price">
                        <span className="text">Total Sell Price</span>
                        <span className="value">${cart.totalPrice?.toFixed(2)}</span>
                    </div>
                </>
            )}
            {cart.isShare && (
                <div className="isShare">
                    <div className="icon">
                        <IconCheck />
                    </div>
                    <div className="title">Payment Link Sent - Payment Pending</div>
                </div>
            )}
        </StyledBookingItem>
    );
};

export const SavedCartWebComponent = memo((props: IProps) => {
    const {
        dateFormat,
        togglePopUp,
        handleEdit,
        handleRemove,
        isShowPopUp,
        name,
        cart,
        id,
        isList,
    } = props;
    //redux state
    const statusApp = useAppSelector(selectStatusApp).status;

    const totalItem = cart.carts.reduce((total, cart) => {
        return total + cart.quantity;
    }, 0);

    return (
        <StyledSavedCartWrapper isList={isList}>
            <div className="date">
                <div className="content">Date: {dateFormat}</div>

                {isList && (
                    <div className="icon" onClick={togglePopUp}>
                        <IconEllipsisHorizontal />
                    </div>
                )}
                {isShowPopUp && (
                    <>
                        <div className="overlay" onClick={togglePopUp}></div>
                        <div className="popup">
                            {cart.isShare && (
                                <div className="desc">
                                    <p>
                                        *This quote has a pending payment link. If you edit the
                                        quote, the payment link will be cancelled and you will need
                                        to send a new link to your customer
                                    </p>
                                </div>
                            )}
                            <div className="popup_container">
                                <button className="editBtn" onClick={handleEdit}>
                                    <img src="/images/editSvg.svg" alt=""></img> <span>Edit</span>
                                </button>
                                <button className="deleteBtn" onClick={handleRemove}>
                                    <img src="/images/deleteSvg.svg" alt=""></img>{" "}
                                    <span>Delete</span>
                                </button>
                            </div>
                            <div className="popup_cancel">
                                <button onClick={togglePopUp}>Cancel</button>
                            </div>
                        </div>
                    </>
                )}
            </div>
            {isList && (
                <>
                    <div className="fernandoTorres">
                        <NavLink to={`${PATH_SAVED_CART}/${id}`}>
                            <h2 className="title">{name}</h2>
                        </NavLink>

                        <div className="price">
                            <div className="price_item">
                                <div className="price_item--title">Number of items:</div>
                                <div className="price_item--amount">
                                    {totalItem} {totalItem === 1 ? " item" : " items"}
                                </div>
                            </div>
                            {statusApp && (
                                <div className="price_item">
                                    <div className="price_item--title">Total Net:</div>
                                    <div className="price_item--amount">
                                        ${cart.totalNet.toFixed(2)}
                                    </div>
                                </div>
                            )}
                            <div className="price_item">
                                <div className="price_item--title">Total RRP:</div>
                                <div className="price_item--amount">
                                    ${cart.totalRRP.toFixed(2)}
                                </div>
                            </div>
                            {statusApp && (
                                <div className="price_item">
                                    <div className="price_item--title">Commission:</div>
                                    <div className="price_item--amount colorRed">
                                        {Number.isInteger(cart.commissionPercent)
                                            ? cart.commissionPercent
                                            : cart.commissionPercent?.toFixed(2) || 0}
                                        %
                                    </div>
                                    <div className="colorRed">${cart.commission.toFixed(2)}</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="totalPrice">
                        <div className="title">Total Sell Price</div>
                        <div className="price">${cart.totalPrice?.toFixed(2)}</div>
                    </div>
                </>
            )}

            {cart.isShare && (
                <div className="isShare">
                    <div className="icon">
                        <IconCheck />
                    </div>
                    <div className="title">Payment Link Sent - Payment Pending</div>
                </div>
            )}
        </StyledSavedCartWrapper>
    );
});

const StyledSavedCartWrapper = styled.div<{
    isList?: boolean;
}>`
    background-color: white;
    width: 100%;
    margin-bottom: 24px;
    .date {
        padding: 16px 0;
        padding-left: 20px;
        padding-right: 25px;
        display: flex;
        margin-bottom: ${(props) => !props.isList && "28px"};
        justify-content: space-between;
        border-bottom: 1px solid #e0e0e0;
        align-items: center;
        position: relative;
        .content {
            color: #4f4f4f;
            font-style: italic;
            font-size: 14px;
        }
        .icon {
            cursor: pointer;
        }
    }
    .fernandoTorres {
        padding-top: 18px;
        padding-bottom: 18px;
        padding-left: 20px;
        padding-right: 25px;
        font-family: DM Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 23px;
        border-bottom: 1px solid #e0e0e0;
        .title {
            margin-bottom: 8px;
        }
        .price {
            display: flex;
            justify-content: space-between;
            /* ${breakpoint.breakLarge`
                justify-content: inherit;
           `} */
            flex-wrap: wrap;
            .price_item {
                display: flex;
                justify-content: space-between;
                /* ${breakpoint.breakLarge`
                justify-content: inherit;
           `} */
                .price_item--title {
                    font-family: DM Sans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 160%;
                    color: #4f4f4f;
                    margin-right: 4px;
                }
                .price_item--amount {
                    font-family: DM Sans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 160%;
                    margin-right: 4px;
                }
            }
        }

        .price_item {
            width: 48%;
            color: #090a18;

            /* ${breakpoint.breakLarge`
                width: 22%;
            `} */
        }

        .price_item:nth-child(1) {
            width: 48%;
            color: #090a18;

            /* ${breakpoint.breakLarge`
                width: 28%;
           `} */
        }
        .price_item:nth-child(4) {
            width: 48%;
            color: #090a18;

            /* ${breakpoint.breakLarge`
                width: 24%;
           `} */
        }
        .price_item:nth-child(2) {
            width: 48%;

            /* ${breakpoint.breakLarge`
                width: 22%;
            `} */
        }
        .colorRed {
            color: ${(p) => p.theme.colors.red};
            font-size: 16px;
            font-weight: 500;
            text-align: end;
        }
    }
    .totalPrice {
        padding-left: 20px;
        padding-right: 25px;
        padding-top: 18px;
        padding-bottom: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
            font-family: DM Sans;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 160%;
            color: #090a18;
        }
        .price {
            color: ${(props) => props.theme.colors.main};
            font-weight: 500;
            font-size: 20px;
        }
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        opacity: 0;
        z-index: 10;
    }
    .desc {
        padding: 24px 20px 6px 20px;
        width: 100%;
        p {
            padding: 16px 16px 23px 16px;
            background: #f6f6f6;
            font-family: DM Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 140%;
            color: #4f4f4f;
        }
    }
    .popup {
        position: absolute;
        top: 43px;
        right: 25px;
        width: 50%;
        background-color: white;
        z-index: 11;
        display: flex;
        flex-direction: column;
        filter: drop-shadow(0px 4px 43px rgba(0, 0, 0, 0.1));
        .popup_container {
            display: flex;
            flex-direction: column;
            align-items: start;
            flex: 6;
            font-size: 16px;
            .editBtn,
            .deleteBtn {
                flex: 1;
                display: flex;
                align-items: center;
                width: 100%;
                padding: 24px 0;
                border-bottom: 1px solid #eef4f8;
                img {
                    width: 24px;
                    height: 24px;
                    margin-left: 20px;
                    margin-right: 20px;
                }
            }
            .editBtn {
                font-size: 16px;
                color: ${(props) => props.theme.colors.main};
            }
            .deleteBtn {
                font-size: 16px;
                color: #f64158;
            }
        }
        .popup_cancel {
            flex: 4;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 24px 0;
            z-index: 10;
            button {
                width: 90%;
                padding: 15px 0;
                border: 2px solid ${(props) => props.theme.colors.main};
                font-size: 16px;
                line-height: 140%;
                color: ${(props) => props.theme.colors.main};
            }
        }
    }
    .isShare {
        display: flex;
        padding-left: 20px;
        padding-right: 25px;
        align-items: center;
        padding-bottom: 28px;
        .icon {
            margin-right: 8px;
        }
        .title {
            color: ${(props) => props.theme.colors.main};
        }
    }
`;

const StyledPopup = styled.div`
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.38);
        z-index: 10;
    }
    .desc {
        padding: 24px 20px 6px 20px;
        width: 100%;
        p {
            padding: 16px 16px 23px 16px;
            background: #f6f6f6;
            font-family: DM Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 140%;
            color: #4f4f4f;
        }
    }
    .popup {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: white;
        z-index: 11;
        display: flex;
        flex-direction: column;
        .popup_container {
            display: flex;
            flex-direction: column;
            align-items: start;
            flex: 6;
            .editBtn,
            .deleteBtn {
                flex: 1;
                display: flex;
                align-items: center;
                width: 100%;
                border-bottom: 1px solid #eef4f8;
                padding: 20px 0;
                img {
                    width: 24px;
                    height: 24px;
                    margin-left: 20px;
                    margin-right: 20px;
                }
            }
            .deleteBtn {
                font-size: 16px;
                color: #f64158;
            }
        }
        .popup_cancel {
            flex: 4;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 12px;
            z-index: 10;
            button {
                width: 90%;
                padding: 15px 0;
                border: 2px solid #31b4b9;
                font-size: 16px;
                line-height: 140%;
                color: #31b4b9;
            }
        }
    }
`;
