import styled from "styled-components";

import { breakpoint } from "@utilities";

export const StyledBookingItem = styled.div`
    background-color: #fff;
    margin-bottom: 24px;
    color: ${(p) => p.theme.colors.black};

    .date {
        font-size: 14px;
        font-style: italic;
        font-weight: 500;
        line-height: 18px;
        color: ${(props) => props.theme.colors.gray_2};
        padding: 20px;
        border-bottom: 1px solid ${(props) => props.theme.colors.gray_5};
        display: flex;
        justify-content: space-between;
        align-items: center;

        ${breakpoint.breakTablet`
            padding: 8px 20px;
        `}

        ${breakpoint.breakIpadPro`
            font-size: 14px;
            font-style: italic;
            font-weight: 500;
            line-height: 18px;
            padding: 19px 26px;
        `}

        .editBtn {
            font-size: 16px;
            font-weight: 700;
            line-height: 21px;
            color: ${(props) => props.theme.colors.main};
            margin-right: 12px;
        }
    }

    .info {
        padding: 13px 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;

        ${breakpoint.breakTablet`
            font-size: 10px;
            line-height: 15px;
            padding: 3px 20px;
        `}

        ${breakpoint.breakIpadPro`
            font-size: 13px;
            line-height: 17px;
        `}

        ${breakpoint.breakLarge`
            padding: 17px 20px 24px 20px;
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
        `}

        .booking-ref {
            font-size: 18px;
            font-weight: 700;
            line-height: 23px;
            margin-bottom: 9px;
        }

        .title {
            font-size: 18px;
            font-weight: 700;
            line-height: 23px;
            margin-bottom: 9px;

            ${breakpoint.breakTablet`
                font-size: 12px;
                line-height: 22px;
                font-weight: 700;
                margin-bottom: 2px;
            `}

            ${breakpoint.breakIpadPro`
                font-size: 15px;
                margin-bottom: 7px;
                margin-top: 4px;
            `}

            ${breakpoint.breakLarge`
                font-size: 18px;
                font-weight: 700;
                line-height: 23px;
            `}
        }
    }

    table {
        width: 100%;

        .value {
            text-align: right;
        }

        td {
            span {
                display: inline-block;
                margin-bottom: 4px;
            }
        }

        .commission {
            color: #f43f3f;
        }
    }

    .price {
        padding: 20px;
        font-size: 20px;
        border-top: 1px solid ${(props) => props.theme.colors.gray_5};
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        color: ${(props) => props.theme.colors.black};
        display: flex;
        justify-content: space-between;

        ${breakpoint.breakTablet`
            padding: 2px 20px;
            font-size: 13px;
        `}

        ${breakpoint.breakIpadPro`
            font-size: 15px;
            padding: 6px 20px;
        `}

        ${breakpoint.breakLarge`
            padding: 10px 20px;
            font-size: 20px;
            font-weight: 500;
            line-height: 32px;
        `}

        .value {
            color: ${(props) => props.theme.colors.main};
            font-size: 20px;

            ${breakpoint.breakTablet`
                font-size: 15px;
            `}

            ${breakpoint.breakIpadPro`
                font-size: 18px;
            `}
        }
    }
    .cancel-request {
        color: red;
        padding: 20px;
        padding-top: 0px;
        font-size: 16px;
    }

    .isShare {
        display: flex;
        padding: 0 20px 24px;
        align-items: center;

        .title {
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            margin-left: 12px;
            color: ${(p) => p.theme.colors.main};
        }
    }
`;
