import styled from "styled-components";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";

import { CheckBoxComponent, StyledChecker, StyledButton, TotalPriceComponent } from "@components";
import { breakpoint, lsEmail, ssPathname } from "@utilities";
import {
    PAYMENT_OPTION,
    SHARE_OPTION,
    CartConstants,
    PHP_CUSTOMER_PATH,
    PHP_AGENT_PATH,
    DURATION_TIME,
} from "@constants";
import { bookingApi } from "@api";
import {
    selectBooking,
    setBookingReference,
    useAppSelector,
    setPaymentMethod,
    openToast,
    selectCart,
    selectAuth,
    share,
    selectSavedCarts,
    updateSavedCart,
    addCart,
    selectAgent,
} from "@redux";
import {
    IBooking,
    IBookingOrder,
    IOptionalFieldId,
    IOrderData,
    IPaymentMethod,
    IProductOrder,
    ICustomerWithCart,
    IRedeemersOrder,
    ICustomerCart,
    IShareBookingRequest,
} from "@interfaces";
import { PATH_ORDER_SUCCESS } from "@routes";
import { phpHelper } from "src/Utilities/phpHelper";

interface IProps {
    setLoading: (isLoad: boolean) => void;
    moveTo: (step: number) => void;
}

export const PaymentModule = (props: IProps) => {
    //page hook
    const dispatch = useDispatch();
    //props
    const { setLoading, moveTo } = props;
    //redux state
    const cartRedux = useAppSelector(selectCart);
    const availablePrice = cartRedux.totalPrice >= cartRedux.totalNet;
    const paymentMethod = useAppSelector(selectBooking).paymentMethod;
    const auth = useAppSelector(selectAuth);
    const agent = useAppSelector(selectAgent);
    const savedCartRedux = useAppSelector(selectSavedCarts);
    //page state
    const [paymentOption, setPaymentOption] = useState(PAYMENT_OPTION);
    const [isAgreeTerm, setIsAgreeTerm] = useState<boolean>(false);
    const bookingRef = useRef("");
    const agentEmail = lsEmail.getItem();

    //page variable

    useEffect(() => {
        return () => {
            setLoading(false);
        };
    }, []);

    // WHAT: process data before making order
    const processData = (bookingReference: string, paymentMethod: string) => {
        const carts = cartRedux.carts;

        // WHAT: merge all productPricesDetailsId to one array.
        const productPricesDetailsIds = [
            ...Array.from(new Set(carts.map((cartItem) => cartItem.productPricesDetailsId))),
        ];

        // for qua các productPricesDetailsId của mảng cart để xử lý dữ liệu chung cho productPricesDetailsId
        const products: IProductOrder[] = carts.reduce((preCarts, cart) => {
            const preCartIndex = preCarts.findIndex((preCart) => {
                return (
                    preCart.datePriceCacheId === cart.datePriceCacheId &&
                    preCart.productPricesDetailsId === cart.productPricesDetailsId
                );
            });
            if (preCartIndex >= 0) {
                preCarts[preCartIndex] = {
                    ...preCarts[preCartIndex],
                    qty: cart.quantity + preCarts[preCartIndex].qty,
                };
                return preCarts;
            } else {
                return [
                    ...preCarts,
                    {
                        productPricesDetailsId: cart.productPricesDetailsId,
                        qty: cart.quantity,
                        datePriceCacheId: cart.datePriceCacheId,
                    },
                ];
            }
        }, [] as IProductOrder[]);

        // WHAT: clone customer
        const customers: ICustomerWithCart[] = cartRedux.customers.reduce(
            (exitCustomers, customer) => {
                const cloneCustomers: ICustomerWithCart[] = [];
                const carts: ICustomerCart[] = customer.carts.map((cart) => {
                    // WHAT: add clone customer
                    if (cart.quantity > 1) {
                        for (let index = 0; index < cart.quantity - 1; index++) {
                            if (cloneCustomers[index]) {
                                cloneCustomers[index].carts.push({ ...cart, quantity: 1 });
                            } else {
                                cloneCustomers.push({
                                    ...customer,
                                    carts: [{ ...cart, quantity: 1 }],
                                    lastName: `${customer.lastName} ${index + 2}`,
                                });
                            }
                        }
                        return { ...cart, quantity: 1 };
                    }
                    return { ...cart };
                });
                return [...exitCustomers, { ...customer, carts }, ...cloneCustomers];
            },
            [] as ICustomerWithCart[]
        );

        //add booking data to customers
        const redeemers: IRedeemersOrder[] = customers.map((customer) => {
            const bookingProducts = productPricesDetailsIds
                .map((productPricesDetailsId: string | number | null) => {
                    const bookings: IBookingOrder[] = [];
                    const redeemerQty = customer.carts
                        .filter(
                            (customerCart) =>
                                customerCart.productPricesDetailsId === productPricesDetailsId
                        )
                        .reduce((totalQuantity, customerCart) => {
                            const cart = carts.find((cartItem) => cartItem.id === customerCart.id);
                            if (cart) {
                                const bookingCart: IBookingOrder = {
                                    bookingComment: "",
                                    travelDate: moment(cart.date).format("DD-MMM-YYYY"),
                                    timeId: cart.BookingTimeID,
                                    pickupId: cart.pickupLocation?.PickupID,
                                    pickupTime: cart.pickupLocation?.PickupTime,
                                    pickupLocation: cart.pickupLocation?.PickupLocation,
                                    datePriceCacheId: cart.datePriceCacheId,
                                };
                                bookings.push(...Array(customerCart.quantity).fill(bookingCart));
                                return totalQuantity + customerCart.quantity;
                            } else {
                                return totalQuantity;
                            }
                        }, 0);

                    // WHY: get only one option answers of all duplicated productPricesDetailsId
                    const cartItem = carts.find(
                        (cartSearchItem) =>
                            cartSearchItem.productPricesDetailsId === productPricesDetailsId
                    );

                    const optionalFields: IOptionalFieldId[] =
                        cartItem && cartItem.optionalFields
                            ? cartItem.optionalFields
                                  .map((optionalField) => {
                                      return {
                                          optionalFieldId: optionalField.optionalFieldId,
                                          fieldText:
                                              optionalField.type === "date"
                                                  ? moment(optionalField.value).format(
                                                        "DD-MMM-YYYY"
                                                    )
                                                  : optionalField.value,
                                      };
                                  })
                                  .filter((optionFiled) => !!optionFiled.fieldText)
                            : [];
                    return {
                        productPricesDetailsId,
                        redeemerQty,
                        bookings,
                        optionalFields,
                    };
                })
                .filter((bookingProduct) => bookingProduct.redeemerQty > 0);

            // if (index === 0) {
            //     setCustomerName(customer.firstName + " " + customer.lastName);
            //     setCustomerMail(customer.email);
            // }
            return {
                emailAddress: customer.email,
                firstName: customer.firstName,
                lastName: customer.lastName,
                phone: customer.phone,
                redeemerCountry: customer.country?.id,
                dateOfBirth: moment(customer.dateOfBirth).format("DD-MMM-YYYY"),
                postcode: customer.postCode,
                products: bookingProducts,
            };
        });

        // data được gửi đi
        const data: IOrderData = {
            bookingReference,
            agentReference: "test",
            paymentMethod,
            emailVouchers: 0,
            totalCharged: cartRedux.totalPrice,
            processAsQuote: true,
            products,
            redeemers,
        };
        return data;
    };

    const onChangePaymentOption = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPaymentOption((event.target as HTMLInputElement).value);
    };

    const order = async (paymentType: string) => {
        const message =
            "Booking failed. Please contact support@freelancetravel.com to finalise this booking.";
        try {
            setLoading(true);
            // WHAT: make new booking reference number for order
            const bookingRefRes = await bookingApi.getBookingReference();
            const bookingReference = bookingRefRes.data.bookingReference;
            // WHY: to matching with booking reference when payment return back the website
            dispatch(setBookingReference(bookingReference));
            bookingRef.current = bookingReference;

            // WHAT: setup method code and return url
            let paymentMethodCode = paymentMethod?.code || "";
            let returnUrl = paymentMethod?.paymentReturnUrl || "";

            // WHY: Payment method can be null -> call get payment method api to make order
            if (!paymentMethod) {
                const paymentMethodRes = await bookingApi.getPaymentMethod();
                if (paymentMethodRes.data?.paymentMethods) {
                    const onlinePaymentMethod = paymentMethodRes.data.paymentMethods.find(
                        (item: IPaymentMethod) => item.supportsOnlinePayment === true
                    );
                    dispatch(setPaymentMethod(onlinePaymentMethod));
                    paymentMethodCode =
                        onlinePaymentMethod?.code || paymentMethodRes.data.paymentMethods[0].code;
                    returnUrl =
                        onlinePaymentMethod?.paymentReturnUrl ||
                        paymentMethodRes.data.paymentMethods[0].paymentReturnUrl;
                } else {
                    dispatch(
                        openToast({
                            open: true,
                            type: "error",
                            message,
                            autoHideDuration: DURATION_TIME,
                        })
                    );
                    // logger.error("Booking error", message);
                    return null;
                }
            }

            // WHAT: setup data
            const body = processData(bookingReference, paymentMethodCode);

            const res = await bookingApi.order(body);

            if (res.status === 200) {
                returnUrl = "http://" + returnUrl;
                const bookingData: IBooking = {
                    booking_id: 1,
                    bookingReference: bookingReference,
                    order_id: res?.data?.id,
                    accessToken: auth.auth?.access_token,
                    request_url: process.env.REACT_APP_API_URL,
                    return_url: window.location.origin + PATH_ORDER_SUCCESS,
                };

                if (paymentType === SHARE_OPTION) {
                    returnUrl += PHP_CUSTOMER_PATH;
                } else {
                    returnUrl += PHP_AGENT_PATH;
                }

                await phpHelper.booking(bookingData);

                const getGateWayRes = await bookingApi.getPaymentGatewayUrl(
                    bookingReference,
                    cartRedux.totalPrice,
                    returnUrl
                );

                if (getGateWayRes.data?.authenticated && paymentOption === "Payment") {
                    return getGateWayRes.data.redirectUrl;
                } else if (getGateWayRes.data?.authenticated && paymentOption === "Share") {
                    return getGateWayRes.data.quoteUrl;
                } else {
                    dispatch(
                        openToast({
                            open: true,
                            type: "error",
                            message,
                            autoHideDuration: DURATION_TIME,
                        })
                    );
                    // logger.error("Booking error", message);
                }
            }
        } catch (err: any) {
            if (err.response) {
                dispatch(
                    openToast({
                        open: true,
                        type: "error",
                        message,
                        autoHideDuration: DURATION_TIME,
                    })
                );
                // logger.error("Booking error", message);
            } else {
                dispatch(
                    openToast({
                        open: true,
                        type: "error",
                        message: `Something went wrong`,
                        autoHideDuration: DURATION_TIME,
                    })
                );
                // logger.error("Booking error", "Something went wrong");
            }
        } finally {
            setLoading(false);
        }
    };

    const handleSwitchPayment = useCallback(() => {
        dispatch(
            share({
                isShare: false,
                redirectUrl: "",
            })
        );
    }, []);

    const handleGetShare = () => {
        // const url = await order(SHARE_OPTION);
        // const redirectUrl = `${window.location.origin}${PATH_PAYMENT_WRAPPER}?url=${url}`;
        // if (url) {
        //     setPaymentLink(redirectUrl);
        dispatch(
            share({
                isShare: true,
                redirectUrl: "",
            })
        );
        // }
    };

    const handleShare = async () => {
        // WHAT: make order
        const url = await order(SHARE_OPTION);
        // const redirectUrl = `${window.location.origin}${PATH_PAYMENT_WRAPPER}?url=${url}`;

        if (url) {
            setLoading(true);
            // WHAT: build Booking request
            const carts = cartRedux.carts;
            const quantity = carts.reduce((totalQuantity, cart) => {
                return totalQuantity + cart.quantity;
            }, 0);
            const customers = cartRedux.customers;
            const leadCustomerEmail = customers[0]?.email || "james.nguyen@adamosoft.com";

            const shareBookingRequest: IShareBookingRequest = {
                email: leadCustomerEmail,
                agent: { ...agent.agentInfo, emailAddress: agentEmail },
                bookingReference: bookingRef.current,
                paymentLink: url,
                products: carts,
                purchaseDate: new Date(),
                quantity,
                totalCharged: cartRedux.totalPrice,
                redeemers: customers,
            };

            await phpHelper.shareToCustomer(shareBookingRequest);
            const savedCartId = savedCartRedux.currentSavedCartId;
            if (savedCartId && savedCartId !== "") {
                dispatch(updateSavedCart(cartRedux, savedCartId));
            } else {
                dispatch(addCart(cartRedux));
            }
            moveTo(CartConstants.ORDER_SUCCESS);
        }

        // if (paymentLink) {
        //     // WHY: content fix from client demand
        //     const agentName = agent.agentInfo.firstName + " " + agent.agentInfo.lastName;
        //     const title = `Freelance Travel Secure Payment Link from ${agentName}`;
        //     const shareSuccess = () => {
        //         const savedCartId = savedCartRedux.currentSavedCartId;
        //         if (savedCartId && savedCartId !== "") {
        //             dispatch(updateSavedCart(cartRedux, savedCartId));
        //         } else {
        //             dispatch(addCart(cartRedux));
        //         }
        //         setLoading(false);
        //         moveTo(CartConstants.ORDER_SUCCESS);
        //     };
        //     setLoading(true);
        //     if (navigator.share) {
        //         const shareData = {
        //             title,
        //             text: "Please click on the secure payment link below to pay and confirm your Freelance Travel Booking. Please note you are only able to click on the link once.",
        //             url: paymentLink,
        //         };
        //         navigator.share(shareData).then(shareSuccess);
        //     } else {
        //         const body = `Hi ${customerName}, please click on the secure payment link below to pay and confirm your Freelance Travel Booking.
        //                     Please note you are only able to click on the link once. ${paymentLink}`;
        //         const mail = `mailto:${customerMail}?subject=${title}&body=${body}`;
        //         //@ts-ignore
        //         window.open(mail, "_blank").focus();
        //         setTimeout(shareSuccess, 2000);
        //     }
        // }
    };

    const handlePayment = async () => {
        // console.log(JSON.stringify(processData("test", "test")));
        const redirectUrl = await order(PAYMENT_OPTION);
        ssPathname.removeItem();
        if (redirectUrl) {
            setLoading(true);
            window.location.href = redirectUrl;
        }
    };

    const handleToggleAgree = () => {
        setIsAgreeTerm(!isAgreeTerm);
    };

    return (
        <Container>
            <div className="payment-wrapper">
                <div className="priceWrapper">
                    <TotalPriceComponent paymentOption={paymentOption} page="payment" />
                </div>
                <ListOptions>
                    <FormControl component="fieldset">
                        <RadioGroup
                            aria-label="gender"
                            name="gender1"
                            value={paymentOption}
                            onChange={onChangePaymentOption}
                        >
                            <FormControlLabel
                                value={PAYMENT_OPTION}
                                control={<Radio onClick={handleSwitchPayment} />}
                                label="Pay now by credit card"
                            />
                            <FormControlLabel
                                value={SHARE_OPTION}
                                control={<Radio onClick={handleGetShare} />}
                                label="Email secure payment link to customer"
                            />
                        </RadioGroup>
                    </FormControl>
                    <label htmlFor="check" className="checkBox">
                        <StyledChecker>
                            <CheckBoxComponent
                                id="check"
                                isChecked={isAgreeTerm}
                                onChange={handleToggleAgree}
                            />
                        </StyledChecker>
                        <p className="checkBoxText">
                            I have informed my client of the{" "}
                            <a
                                href="https://freelancetravel.com/booking-terms-and-conditions/"
                                target="_blank"
                            >
                                booking terms and conditions
                            </a>
                        </p>
                    </label>
                    {paymentOption === PAYMENT_OPTION ? (
                        <PaymentButton
                            onClick={handlePayment}
                            disabled={!isAgreeTerm || !availablePrice}
                        >
                            {PAYMENT_OPTION}
                        </PaymentButton>
                    ) : (
                        <PaymentButton
                            onClick={handleShare}
                            disabled={!isAgreeTerm || !availablePrice}
                        >
                            {
                                //@ts-ignore
                                !navigator.share ? SHARE_OPTION : "Email Payment Link"
                            }
                        </PaymentButton>
                    )}
                </ListOptions>
            </div>
        </Container>
    );
};

const Container = styled.div`
    background-color: #f2f2f2;
    padding: 16px 16px 0 16px;
    height: 100%;
    flex: 1;
    width: 100%;

    ${breakpoint.breakTablet`
        background-color: #F2F2F2;
        padding: 0px 16px 60px 16px;
    `}

    /* ${breakpoint.breakLarge`
        padding: 16px 16px 60px 16px;
    `} */

    .payment-wrapper {
        ${breakpoint.breakTablet`
            margin: 0 auto;
            width: 530px;
            display: flex;
            flex-direction: row-reverse;

            .priceWrapper {
                flex: 350;
            }
        `}

        ${breakpoint.breakIpadPro`
            width: 800px;
        `}

        ${breakpoint.breakLarge`
            width: 920px;
        `}

        .priceWrapper {
            margin-bottom: 17px;

            ${breakpoint.breakTablet`
                margin-bottom: 0;
            `}

            ${breakpoint.breakLarge`
                min-width: 350px;
            `}
        }
    }

    .checkBox {
        margin-top: 40px;
        display: flex;

        /* ${breakpoint.breakTablet`
            
        `} */

        .checkBoxText {
            font-size: 13px;
            font-weight: normal;
            line-height: 18px;
            color: ${(p) => p.theme.colors.gray_2};
            position: relative;
            top: -2px;

            ${breakpoint.breakIpadPro`
                font-size: 14px;
            `}

            ${breakpoint.breakLarge`
                .checkBoxText {
                    margin-left: 14px;
                }
            `}
        }
        a {
            color: ${(props) => props.theme.colors.main};
            font-weight: 600;
        }
    }
`;

const ListOptions = styled.ul`
    background-color: #fff;
    margin: 0 -16px;
    padding: 0 16px 1px 16px;

    ${breakpoint.breakTablet`
        flex: 610;
        margin-right: 30px;
        padding: 0 25px;
        padding-top: 30px;
    `}

    ${breakpoint.breakIpadPro`
        flex: 410;
        margin-right: 100px;
        padding: 0 35px;
        padding-top: 25px;
    `}

    ${breakpoint.breakLarge`
        margin-right: 76px;
        padding: 34px 65px 0 65px;
    `}
    @media (min-width: 768px) and (max-width: 1023px) {
        padding: 0 20px;
        padding-top: 30px;
    }

    .MuiRadio-colorSecondary.Mui-checked {
        color: ${(props) => props.theme.colors.main} !important;
    }
    .MuiTypography-body1 {
        font-family: DM Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;

        ${breakpoint.breakTablet`
            font-size: 14px;
        `}
    }

    .PrivateSwitchBase-root-1 {
        padding: 9px 12px;
    }
`;

const PaymentButton = styled(StyledButton)`
    margin-bottom: 45px;
    margin-top: 20px;

    @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) and (max-width: ${(p) =>
            p.theme.breakPoints.breakLarge}) {
        height: 40px;
        margin-bottom: 20px;
        margin-top: 15px;
    }
`;
