import styled from "styled-components";

export const StyledChecker = styled.div`
    margin-top: 0;
    margin-right: 8px;

    .MuiCheckbox-colorPrimary.Mui-checked {
        color: ${(props) => props.theme.colors.main} !important;
    }

    .PrivateSwitchBase-root-1 {
        padding: 0 !important;
        margin-right: 12px !important;
    }

    .MuiButtonBase-root {
        padding-left: 0 !important;
        padding-top: 0 !important;
    }

    @media (min-width: ${(props) => props.theme.breakPoints.breakTablet}) {
        .PrivateSwitchBase-root-1 {
            margin-top: 0 !important;
            margin-right: 0 !important;
        }
    }
`;
