import styled from "styled-components";

import { StyledTourDetail } from "@components";
import { BOOKING_SYSTEMS } from "@constants";
import { ITourFare } from "@interfaces";
import { breakpoint } from "@utilities";
import { TourFareModule } from ".";

interface IProps {
    tourFare: ITourFare[];
    tourId: number | string;
}

export const PriceDetailTourModule = (props: IProps) => {
    const { tourFare, tourId } = props;
    let noFare = true;

    return (
        <StyledTourDetail className="mt-5" statusApp>
            <div className="heading">
                <h3>Price</h3>
            </div>
            <Content>
                {!!tourFare &&
                    tourFare.map((type: ITourFare) => {
                        if (
                            BOOKING_SYSTEMS.some((system) => system === type.bookingSystem) &&
                            type.availabilityInfoUrl
                        ) {
                            noFare = false;
                            return (
                                <TourFareModule
                                    tourFare={type}
                                    tourFares={tourFare}
                                    tourId={tourId}
                                    key={type.productPricesDetailsId}
                                />
                            );
                        }
                    })}
                {noFare && <p className="noFare">No fare available!</p>}
            </Content>
        </StyledTourDetail>
    );
};

const Content = styled.div`
    .content--children {
        background-color: #fff;
        display: flex;
        width: 100%;
        padding: 25px;
        border-bottom: 1.5px solid #e5e5e5;
        justify-content: space-between;

        .date {
            display: flex;
            flex-direction: column;
            justify-content: end;
        }

        .fareType,
        .date,
        .listBtn {
            width: 32%;
        }
        .fareType {
            background-color: transparent;
            .title {
                font-weight: bold;
                color: #090a18;
                margin-bottom: 8px;
                font-size: 16px;
                ${breakpoint.breakIpad` 
                    font-size: 12px;
                `}
                ${breakpoint.breakIpadPro`
                    font-size: 16px;
                `}
            }
            .text {
                font-weight: normal;
                line-height: 21px;
                font-size: 16px;
                ${breakpoint.breakIpad` 
                    font-size: 12px;
                `}
                ${breakpoint.breakIpadPro`
                    font-size: 16px;
                `}
            }
            .travel-time {
                font-weight: normal;
                line-height: 21px;
                font-size: 14px;
                color: ${(props) => props.theme.colors.gray_3};
                ${breakpoint.breakIpad` 
                    font-size: 10px;
                `}
                ${breakpoint.breakIpadPro`
                    font-size: 14px;
                `}
            }
        }
        .date {
            .choseDate {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin-bottom: 30px;
                .from,
                .to {
                    width: 47%;
                }
                ${breakpoint.breakIpad`
                      margin-bottom: 20px;
                `}
            }
            .rrp {
                color: #828282;
                font-size: 16px;
                padding-bottom: 5px;
                ${breakpoint.breakIpad` 
                    font-size: 12px;
                `}
                ${breakpoint.breakIpadPro`
                    font-size: 16px;
                `}
                ${breakpoint.breakLarge`
                    padding-bottom: 10px;
                `}

                span {
                    color: ${(props) => props.theme.colors.main};
                    font-weight: 600;
                    display: inline-block;
                    transform: scale(1.2);
                    margin-left: 10px;
                    font-size: 24px;
                    ${breakpoint.breakIpad` 
                        font-size: 16px;
                    `}
                    ${breakpoint.breakIpadPro`
                        font-size: 24px;
                    `}
                }
                .note {
                    font-size: 14px;
                    margin-top: 7px;
                }
            }
        }
        .listBtn {
            .MuiFormLabel-root {
                font-weight: bold;
                color: #090a18;
                font-size: 16px;
                ${breakpoint.breakIpad` 
                    font-size: 12px;
                `}
                ${breakpoint.breakIpadPro`
                    font-size: 16px;
                `}
            }
            .MuiFormControl-root {
                width: 100%;
                position: relative;
                left: 6px;
            }
            .MuiFormGroup-root {
                .MuiFormControlLabel-root {
                    width: fit-content;
                    ${breakpoint.breakIpad`
                        margin-right:40px;
                    `}
                    ${breakpoint.breakIpadPro`
                        margin-right:16px;
                    `}
                    .MuiTypography-root {
                        font-size: 16px;
                        ${breakpoint.breakIpad` 
                            font-size: 12px;
                        `}
                        ${breakpoint.breakIpadPro`
                            font-size: 16px;
                        `}
                    }
                    .MuiSvgIcon-root {
                        font-size: 1em;
                        ${breakpoint.breakIpad` 
                            font-size: 0.75em;
                        `}
                        ${breakpoint.breakIpadPro`
                            font-size: 1em;
                        `}
                    }
                }
            }
            .MuiRadio-colorSecondary.Mui-checked {
                color: ${(props) => props.theme.colors.main};
            }
        }
    }
    .MuiFormControl-marginNormal {
        margin: 0;
    }
    .MuiInputBase-input {
        font-size: 14px;
    }
`;
