import moment from "moment";

import { StyledBookingItem } from "@components";
import { IListBookings } from "@interfaces";
import { selectBooking, useAppSelector } from "@redux";

interface IProps {
    data: IListBookings;
    statusApp: boolean;
}

export const BookingItemComponent = (props: IProps) => {
    const { data, statusApp } = props;

    const { listBCR } = useAppSelector(selectBooking);

    const date = new Date(data.datePurchased);
    const payoutDate = new Date(data.latestTravelDate);
    const dateFormat = moment(date).format("DD/MM/YYYY");
    const payoutDateFormat = moment(payoutDate).add(10, "d").format("DD/MM/YYYY");
    const name = data.firstName + " " + data.lastName;

    const cancelRequest = listBCR.some((bcr) => bcr.bookingReference === data.bookingReference);

    return (
        <StyledBookingItem>
            <div className="date">Sale Date: {dateFormat}</div>
            <div className="info">
                <h2 className="booking-ref">{data.bookingReference}</h2>
                <h3 className="title">{name}</h3>
                <table>
                    <tbody>
                        {/* <tr>
                            <td>
                                <span className="field">Order ID</span>
                            </td>
                            <td></td>
                            <td className="value">
                                <span>{data.orderId}</span>
                            </td>
                        </tr> */}
                        <tr>
                            <td>
                                <span className="field">Total Sell Price</span>
                            </td>
                            <td></td>
                            <td className="value">
                                <span>${data.totalAmountExcludeCCFee.toFixed(2)}</span>
                            </td>
                        </tr>
                        {statusApp && (
                            <>
                                <tr>
                                    <td>
                                        <span className="field">Commission</span>
                                    </td>
                                    <td>
                                        {/* <span className="commission">{data.commissionAmount}%</span> */}
                                    </td>
                                    <td className="value ">
                                        <span>${data.commissionAmount.toFixed(2)}</span>
                                    </td>
                                </tr>
                            </>
                        )}
                        <tr>
                            <td>
                                <span className="field">Commission Payout Due Date</span>
                            </td>
                            <td></td>
                            <td className="value">
                                <span>{payoutDateFormat}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="price">
                <span className="text">My Payout Amount</span>
                <span className="value">${data.commissionAmount.toFixed(2)}</span>
            </div>
            {cancelRequest && <div className="cancel-request">Refund Requested</div>}
        </StyledBookingItem>
    );
};
