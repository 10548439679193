import styled from "styled-components";

import { breakpoint } from "@utilities";

interface IProps {
    color?: string;
    disabled?: boolean;
}

function backgroundColor(props: IProps) {
    if (props.disabled) {
        return "#BDBDBD";
    } else {
        return props.color ?? "#31b4b9";
    }
}

export const StyledButton = styled.button<IProps>`
    border: none;
    width: 100%;
    height: 54px;
    background: ${props => backgroundColor(props)};
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 160%;
    margin-top: 40px;
    border-radius: 0;

    ${breakpoint.breakTablet`
        font-size: 12px;
    `}

    ${breakpoint.breakIpadPro`
        font-size: 16px;
    `}
`;

export const StyledErrorButton = styled(StyledButton)`
    background: ${(props) => props.theme.colors.red};
`;

export const StyledBorderButton = styled(StyledButton)`
    background: #fff;
    border: ${(props) => `${props.theme.colors.main} 2px solid`};
    color: ${(props) => props.theme.colors.main};
`;

export const StyledUnBackgroundButton = styled(StyledButton)`
    background-color: transparent;
    color: ${(p) => p.theme.colors.main};
`;

export const CancelButton = styled(StyledButton)`
    background-color: rgba(244, 63, 63, 0.1);
    color: ${p => p.theme.colors.red};
`;