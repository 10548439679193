import styled from "styled-components";

import { StyledPagePoint } from "@components";
import { IPagination } from "@interfaces";
import { breakpoint } from "@utilities";

interface IProps {
    onSetCurrentPage: (page: number) => void;
    listPagePoints: IPagination[];
    currentPage: number;
}

export const PaginationModule = (props: IProps) => {
    const { onSetCurrentPage, listPagePoints, currentPage } = props;
    let isBroke = false;

    const renderListPoints = (
        <>
            {listPagePoints.map((item, index) => {
                const gap = 3;
                if (currentPage === 1) {
                    if (isBroke && index !== listPagePoints.length - 1) {
                        return null;
                    } else if (index === listPagePoints.length - 1) {
                        return (
                            <StyledPagePoint
                                onClick={() => onSetCurrentPage(item.id)}
                                key={item.id}
                                active={currentPage === item.id}
                            >
                                {item.id}
                            </StyledPagePoint>
                        );
                    }

                    if (index + 1 > gap) {
                        isBroke = true;
                        return (
                            <div className="breakPagination" key={item.id}>
                                ...
                            </div>
                        );
                    }

                    return (
                        <StyledPagePoint
                            onClick={() => onSetCurrentPage(item.id)}
                            key={item.id}
                            active={currentPage === item.id}
                        >
                            {item.id}
                        </StyledPagePoint>
                    );
                } else if (currentPage === listPagePoints.length) {
                    if (index === 0) {
                        return (
                            <StyledPagePoint
                                onClick={() => onSetCurrentPage(item.id)}
                                key={item.id}
                                active={currentPage === item.id}
                            >
                                {item.id}
                            </StyledPagePoint>
                        );
                    } else if (index < listPagePoints.length - gap) {
                        if (isBroke) {
                            return null;
                        } else {
                            isBroke = true;
                            return (
                                <div className="breakPagination" key={item.id}>
                                    ...
                                </div>
                            );
                        }
                    } else {
                        return (
                            <StyledPagePoint
                                onClick={() => onSetCurrentPage(item.id)}
                                key={item.id}
                                active={currentPage === item.id}
                            >
                                {item.id}
                            </StyledPagePoint>
                        );
                    }
                } else {
                    if (index === 0) {
                        return (
                            <StyledPagePoint
                                onClick={() => onSetCurrentPage(item.id)}
                                key={item.id}
                                active={currentPage === item.id}
                            >
                                {item.id}
                            </StyledPagePoint>
                        );
                    } else if (index === listPagePoints.length - 1) {
                        isBroke = false;
                        return (
                            <StyledPagePoint
                                onClick={() => onSetCurrentPage(item.id)}
                                key={item.id}
                                active={currentPage === item.id}
                            >
                                {item.id}
                            </StyledPagePoint>
                        );
                    } else {
                        if (index + 1 < currentPage - 1) {
                            if (!isBroke) {
                                isBroke = true;
                                return (
                                    <div className="breakPagination" key={item.id}>
                                        ...
                                    </div>
                                );
                            } else {
                                return null;
                            }
                        } else if (index + 1 >= currentPage - 1 && index + 1 <= currentPage + 1) {
                            isBroke = false;
                            return (
                                <StyledPagePoint
                                    onClick={() => onSetCurrentPage(item.id)}
                                    key={item.id}
                                    active={currentPage === item.id}
                                >
                                    {item.id}
                                </StyledPagePoint>
                            );
                        } else if (index + 1 > currentPage + 1) {
                            if (!isBroke) {
                                isBroke = true;
                                return (
                                    <div className="breakPagination" key={item.id}>
                                        ...
                                    </div>
                                );
                            } else {
                                return null;
                            }
                        }
                    }
                }
            })}
        </>
    );

    return (
        <Container>
            <div className="currentPage">
                Showing <span>{currentPage}</span>/<span>{listPagePoints.length}</span>
            </div>
            <div className="listPoints">
                <StyledPagePoint
                    onClick={() => {
                        if (currentPage === 1) {
                            return;
                        } else {
                            onSetCurrentPage(currentPage - 1);
                        }
                    }}
                    disabled={currentPage === 1}
                >
                    <img src="/images/cil_arrow-bottom-left.svg" alt="cil_arrow-bottom-left" />
                </StyledPagePoint>
                {renderListPoints}
                <StyledPagePoint
                    onClick={() => {
                        if (currentPage === listPagePoints.length) {
                            return;
                        } else {
                            onSetCurrentPage(currentPage + 1);
                        }
                    }}
                    disabled={currentPage === listPagePoints.length}
                >
                    <img src="/images/cil_arrow-bottom-right.svg" alt="cil_arrow-bottom-right" />
                </StyledPagePoint>
            </div>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 48px;
    margin-bottom: 120px;

    .currentPage {
        color: ${(props) => props.theme.colors.gray_3};
        font-weight: 400;
        font-size: 12px;

        ${breakpoint.breakIpadPro`
            font-size: 16px;
        `}

        span:nth-child(1) {
            margin: 0 5px 0 10px;
        }

        span:nth-child(2) {
            margin-left: 5px;
        }
    }

    .listPoints {
        display: flex;
    }
    .breakPagination {
        display: flex;
        align-items: center;
        position: relative;
        top: -5px;
    }
`;
