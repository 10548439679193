import { useDispatch } from "react-redux";
import { InputBase, Select } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import React, { memo, useState } from "react";
// import debounce from "lodash/debounce";

import { DatePickerCommon, StyledError } from "@components";
import { saveAnswersCartItem } from "@redux";
import { OptionalField } from "@interfaces";

interface IPropsCartItem {
    optionalField: OptionalField;
    itemId: string | number | null;
}
interface ISwitchInputComponent {
    optionalField: OptionalField;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeDate: (date: Date | null) => void;
    onChangeSelect: (
        event: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>,
        child: React.ReactNode
    ) => void;
}

export const SwitchInputComponent = memo((props: ISwitchInputComponent) => {
    const { optionalField, onChange, onChangeDate, onChangeSelect } = props;

    switch (optionalField.type) {
        case "text":
            return (
                <InputBase
                    onChange={onChange}
                    value={optionalField.value ? optionalField.value : ""}
                />
            );
        case "select":
            return (
                <Select
                    labelId="label"
                    value={optionalField.value ? optionalField.value : optionalField.options[0]}
                    IconComponent={KeyboardArrowDownIcon}
                    onChange={onChangeSelect}
                >
                    {optionalField.options.map((option: string, index: number) => {
                        return (
                            <MenuItem value={option} key={index}>
                                {option}
                            </MenuItem>
                        );
                    })}
                </Select>
            );
        case "number": {
            return (
                <InputBase
                    onChange={onChange}
                    type="number"
                    inputProps={{ inputProps: { min: 0 } }}
                    value={optionalField.value ? optionalField.value : 0}
                />
            );
        }
        case "date":
            return (
                <DatePickerCommon
                    value={optionalField.value ? optionalField.value : null}
                    onChange={onChangeDate}
                />
            );
        default:
            return <> </>;
    }
});

export const OptionalFieldModule = memo((props: IPropsCartItem) => {
    //props
    const { optionalField, itemId } = props;
    //page hook
    const dispatch = useDispatch();
    //page state
    const [error, setError] = useState<string>("");
    //page variable
    const optionalFieldId = optionalField.optionalFieldId;

    //normal input change with debounce
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        //check validate mandatory
        if (!value && optionalField.mandatory) {
            setError("This field is required");
        } else {
            setError("");
        }
        // lấy ra type của thẻ input
        const type = e.target.attributes[1].textContent;
        // check , nếu là number == -1 thì set value = 0
        if (type === "number" && +value === -1) {
            dispatch(saveAnswersCartItem({ itemId, optionalFieldId, value: "0" }));
            return;
        }
        // check , nếu là number thì value > =0
        if (type === "number" && +value >= 0) {
            dispatch(saveAnswersCartItem({ itemId, optionalFieldId, value }));
            return;
        }
        // check không pải number thì dispatch
        if (type !== "number") {
            dispatch(saveAnswersCartItem({ itemId, optionalFieldId, value }));
        }
    };

    //normal input change with debounce
    const handleChangeSelect = (
        e: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ) => {
        const value = e.target.value;
        //check validate mandatory
        if (!value && optionalField.mandatory) {
            setError("This field is required");
        } else {
            setError("");
        }
        dispatch(saveAnswersCartItem({ itemId, optionalFieldId, value }));
    };

    //date input change with debounce
    const handleDateChange = (date: Date | null) => {
        //check validate mandatory
        if (!date && optionalField.mandatory) {
            setError("This field is required");
        } else {
            setError("");
        }
        dispatch(saveAnswersCartItem({ itemId, optionalFieldId, value: date }));
    };

    return (
        <>
            <SwitchInputComponent
                optionalField={optionalField}
                onChangeDate={handleDateChange}
                onChange={handleChange}
                onChangeSelect={handleChangeSelect}
            />
            <StyledError>{error}</StyledError>
        </>
    );
});
