import styled from "styled-components";

import { ClipLoaderCommon } from "./LoaderCommon";

export const LoadingCommon = () => {
    return (
        <StyledLoading>
            <ClipLoaderCommon color="#31B4B9" size={70} margin={150} />
        </StyledLoading>
    );
};


const StyledLoading = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    img {
        width: 70px;
        height: 70px;
    }
`;
