import * as Yup from "yup";

export const accountSchema = Yup.object().shape({
    firstName: Yup.string()
        .trim()
        .required("This field is required!")
        .max(50, "This field is cannot be longer than 50 characters!"),
    lastName: Yup.string()
        .trim()
        .required("This field is required!")
        .max(50, "This field is cannot be longer than 50 characters!"),
    businessnumber: Yup.string()
        .trim()
        .required("This field is required!")
        .max(50, "This field is cannot be longer than 50 characters!"),
    tradingname: Yup.string()
        .trim()
        .required("This field is required!")
        .max(50, "This field is cannot be longer than 50 characters!"),
    bankBsb: Yup.string()
        .trim()
        .required("This field is required!")
        .max(50, "This field is cannot be longer than 50 characters!"),
    bankAccount: Yup.string()
        .trim()
        .required("This field is required!")
        .max(50, "This field is cannot be longer than 50 characters!"),
});
