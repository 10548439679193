import styled from "styled-components";

import { breakpoint } from "@utilities";

export const StyledSavedCartPage = styled.div`
    padding: 16px;
    background-color: #F2F2F2;
    flex: 1;
    padding-bottom: 80px;

    .wrapper {
        margin: 0 auto;

        ${breakpoint.breakTablet`
            width: ${(p) => p.theme.widths.wide_700};
        `}

        ${breakpoint.breakIpadPro`
            width: ${(p) => p.theme.widths.wide_900};
        `}

        ${breakpoint.breakLarge`
            width: ${(p) => p.theme.widths.wide_1110};
        `}
    }

    .breadCrumbs,
    .menu {
        display: none;

        ${breakpoint.breakTablet`
            display: block;
        `}
    }
    .breadCrumbs {
        ${breakpoint.breakTablet`
            margin-top: 14px;
        `}
    }

    .subWrapper {
        ${breakpoint.breakTablet`
            display: flex;
            margin-top: 30px;
        `}
    }

    .searchBox {
        display: flex;
        background-color: #fff;
        padding: 18px 23px;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;

        ${breakpoint.breakTablet`
            display: none;
        `}

        .icon {
            margin-right: 17px;
        }

        input {
            flex: 1;

            &::placeholder {
                color: ${(props) => props.theme.colors.gray_3};
            }
        }
    }

    .listCarts {
        margin-top: 16px;
        flex: 1;
        position: relative;
        .searchBox {
            ${breakpoint.breakTablet`
                display: flex;
                font-size: 16px;
                margin-bottom: 30px; 
            `}
            ${breakpoint.breakOnlyMobile`
                display: none;
            `}
        }
        ${breakpoint.breakTablet`
            margin-top: 0;
        `}

        .heading {
            font-size: 20px;
            font-weight: 700;
            line-height: 36px;
            margin-bottom: 15px;

            ${breakpoint.breakIpadPro`
                margin-bottom: 25px;
            `}

            ${breakpoint.breakIpadPro`
                font-size: 28px;
                margin-bottom: 30px;
            `}
        }
        .infinite-scroll-component__outerdiv {
            width: 100% !important;
        }
        .infinite-scroll-component {
            width: 100%;
        }
        .row {
            flex-direction: column !important;
        }
    }
`;
