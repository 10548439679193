import styled from "styled-components";

import { breakpoint } from "@utilities";

interface IProps {
    currentBlockIndex: number;
    width: number;
}

export const StyledWelcomePopup = styled.div<IProps>`
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100vw;
    min-height: 100vh;
    background: rgba(9, 10, 24, 0.4);
    z-index: 3;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .main {
        background-color: #fff;
        height: 550px;
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;

        ${breakpoint.breakTablet`
            width: 500px;  
            margin: 0 auto; 
            height: 606px;     
        `}

        .closeBtn {
            position: absolute;
            top: 14px;
            right: 14px;
            width: 40px;
            height: 40px;
            background-color: ${p => p.theme.colors.gray_6};
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            z-index: 1;

            button {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .block {
            flex: 1;
            position: relative;
            overflow-x: hidden;

            .blockSlider {
                position: absolute;
                display: flex;
                transition: 300ms;
                height: 100%;
                transform: ${p => p.width < 767 ? `translateX(-${p.currentBlockIndex * (p.width - 32)}px)` : `translateX(-${p.currentBlockIndex * 500}px)`}
            }
        }
    }

    .navigateDot {
        padding: 18px 0;
        display: flex;
        justify-content: center;

        .dot {
            width: 9px;
            height: 9px;
            display: inline-block;
            border: 1px solid ${p => p.theme.colors.main};
            border-radius: 50%;
            margin: 0 10px;

            &.active {
                background-color: ${p => p.theme.colors.main};
            }
        }
    }

    .listBtn {
        display: flex;
        padding: 25px;
        background-color: ${p => p.theme.colors.gray_6};

        button {
            margin-top: 0;
        }
    }
`;