import { MenuItem, Select } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import styled from "styled-components";

import { IFilterBooking, IListBookings } from "@interfaces";
import { DatePickerCommon } from "@components";
import { breakpoint } from "@utilities";

interface IProps {
    optionModels: IFilterBooking[];
    selectedOption: number;
    handleChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
    handleChangeDateFrom: (date: Date, value?: string | null) => void;
    dateFrom: Date | null;
    handleChangeDateTo: (date: Date, value?: string | null) => void;
    dateTo: Date | null;
    data: IListBookings[];
    clearDate: () => void;
}

export const FilterBookingComponent = (props: IProps) => {
    const {
        optionModels,
        selectedOption,
        handleChange,
        handleChangeDateFrom,
        dateFrom,
        handleChangeDateTo,
        dateTo,
        data,
        clearDate,
    } = props;

    const totalCommission = data.reduce((total, data) => {
        return total + data.commissionAmount;
    }, 0);

    const totalAmount = data.reduce((total, data) => {
        return total + data.totalAmount;
    }, 0);

    return (
        <Container>
            <div className="filterWrapper">
                <div className="filterSelect">
                    <SelectFilter>
                        <Select
                            fullWidth
                            value={selectedOption}
                            IconComponent={KeyboardArrowDownIcon}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                            onChange={handleChange}
                        >
                            {optionModels.map((option, index) => {
                                return (
                                    <MenuItem value={option.value} key={index}>
                                        {option.title}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </SelectFilter>
                </div>
                <div className="dateRange">
                    <div className="from">
                        <DatePickerCommon
                            value={dateFrom}
                            onChange={handleChangeDateFrom}
                            limitDate={{
                                minDate: new Date("01/01/1700"),
                                maxDate: dateTo,
                            }}
                            maxDateMessage="Start date should not be after end date"
                        />
                    </div>
                    <div className="to">
                        <DatePickerCommon
                            value={dateTo}
                            onChange={handleChangeDateTo}
                            limitDate={{
                                minDate: dateFrom || new Date("01/01/1700"),
                                maxDate: null,
                            }}
                            minDateMessage="End date should not be before start date"
                        />
                    </div>
                </div>
            </div>
            <div className="resetDate">
                <div onClick={clearDate} className="resetButton">
                    Clear Dates
                </div>
            </div>
            <div className="price">
                <div className="flexRow">
                    <div className="left"></div>
                    <div className="right">
                        <div className="priceItem">
                            <span className="fieldName">Total Number Of Sales</span>
                            <span className="value">{data.length}</span>
                        </div>
                        <div className="priceItem">
                            <span className="fieldName">Total Sales</span>
                            <span className="value">${totalAmount.toFixed(2)}</span>
                        </div>
                    </div>
                </div>
                <div className="totalCommission flexRow">
                    <div className="left"></div>
                    <div className="right flexRow justifySpaceBetween">
                        <span className="fieldName">Total Commission</span>
                        <span className="value">${totalCommission.toFixed(2)}</span>
                    </div>
                </div>
                <div className="note">
                    *Please note that commission will be paid out to your nominated bank account {" "}
                    <b>within 10 days</b> from the <b>last travel date</b> on each order
                </div>
            </div>
        </Container>
    );
};

const Container = styled.div`
    padding: 20px 17px;
    background-color: #fff;
    margin: -24px -20px 17px -20px;

    ${breakpoint.breakTablet`
        min-width:  495px; 
        margin: 0 0 17px 0;
        padding: 20px 17px 10px 17px;
    `}

    ${breakpoint.breakLarge`
        min-width: 730px; 
    `}

    .filterWrapper {
        ${breakpoint.breakTablet`
            display: flex;
        `}
    }

    .filterSelect {
        ${breakpoint.breakTablet`
            flex: 319;
            margin-right: 16px;
        `}
    }

    .dateRange {
        display: flex;

        ${breakpoint.breakTablet`
            flex: 344;
        `}

        .from,
        .to {
            flex: 1;

            .MuiInputBase-root {
                ${breakpoint.breakTablet`
                    padding: 9px 11px !important;
                `}

                ${breakpoint.breakLarge`
                    padding: 17px 21px !important;
                `}
            }
        }

        .from {
            margin-right: 14px;
        }

        .MuiFormControl-marginNormal {
            ${breakpoint.breakTablet`
                margin-top: 0;
            `}
        }
    }

    .price {
        margin-top: 18px;
    }

    .left {
        ${breakpoint.breakTablet`
            flex: 319;
        `}
    }

    .right {
        width: 100%;

        ${breakpoint.breakTablet`
            flex: 344;
            width: auto;
        `}
    }

    .priceItem {
        display: flex;
        justify-content: space-between;
        padding-right: 15px;
        padding-left: 15px;
    }

    .priceItem {
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        margin-top: 8px;

        ${breakpoint.breakTablet`
            font-size: 13px;
            margin-top: 3px;
        `}

        ${breakpoint.breakLarge`
            font-size: 16px;
            margin-top: 8px;
        `}
    }

    .totalCommission {
        font-size: 20px;
        font-weight: 700;
        line-height: 32px;
        padding-top: 14px;
        border-top: 1px solid ${(p) => p.theme.colors.gray_5};
        color: ${(p) => p.theme.colors.main};
        margin-top: 16px;

        .fieldName {
            padding-left: 15px;
        }

        .value {
            padding-right: 15px;
        }

        ${breakpoint.breakTablet`
            font-size: 13px;
            padding-top: 4px;
            margin-top: 10px;
        `}

        ${breakpoint.breakLarge`
            font-size: 20px;
            padding-top: 10px;
            margin-top: 14px;
        `}
    }
    .resetDate {
        text-align: right;
        .resetButton {
            cursor: pointer;
            color: ${(props) => props.theme.colors.main};
            font-size: bold;
        }
    }
    .note {
        font-size: 12px;
        font-weight: small;
        text-align: left;
        color: ${(prop) => prop.theme.colors.gray_3};
        padding: 15px;
        ${breakpoint.breakMobile`
            font-size: 14px; 
        `}
    }
`;

const SelectFilter = styled.div`
    .MuiInputBase-root {
        font-family: DM Sans, sans-serif !important;
    }

    .MuiInputBase-fullWidth {
        background-color: #ededed;
    }

    .MuiSelect-select:focus {
        background-color: #ededed;
    }

    .MuiSelect-icon {
        margin-right: 6px;
        /* font-size: 30px; */
    }

    .MuiSelect-select {
        font-size: 16px;
        font-weight: 700;
        line-height: 26px;
        padding: 5px 20px;

        ${breakpoint.breakTablet`
            font-size: 13px;
        `}

        ${breakpoint.breakLarge`
            font-size: 16px;
            padding: 14px 20px;
        `}
    }

    .MuiInput-underline:before {
        border: none;
    }

    .MuiInput-underline:after {
        border: none;
    }

    .MuiList-padding {
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 20px;
    }
`;
