import styled from "styled-components";

interface IProps {
    active: boolean;
}

export const StyledNavBar = styled.div<IProps>`
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    position: fixed;
    top: 95px;
    left: 0;
    background-color: #fff;
    width: 100vw;
    z-index: 2;
    height: ${props => props.active ? "60px" : "0"};
    transition: 300ms;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.04);
`;