import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

import { ICart, ISavedCart } from "@interfaces";
import { RootState } from ".";
import { bookingApi } from "@api";
import { store, AppDispatch } from "@redux";
import { phpHelper } from "src/Utilities/phpHelper";

interface ISavedCarts {
    carts: ISavedCart[];
    currentSavedCartId: string;
    isLoading: boolean;
    isShowPopup: boolean;
}

interface ICustomerOrder {
    email: string;
    firstName: string;
    lastName: string;
    phone: string | number;
    productTags: string | null;
    orderId: string | number;
    bookingReference: string;
    salesAgentName: string;
    salesAgentEmail: string;
    salesBranchCode: string;
    brand: string | null;
    datePurchased: string;
    earliestTravelDate: string;
    latestTravelDate: string;
    vouchersPDF: string | null;
    vouchersHTML: string | null;
    orderStatus: string;
    totalAmount: number;
    paidAmount: number;
    netTotal: string | null;
    commissionAmount: string | null;
}

const initialState: ISavedCarts = {
    carts: [],
    currentSavedCartId: "",
    isLoading: true,
    isShowPopup: false,
};

interface ICustomerOder {
    carts: ISavedCart[];
    res: { data: ICustomerOrder[] };
}

export const getCustomerOrder = createAsyncThunk<ICustomerOder, void>(
    "get/CustomerOrder",
    async (action, { rejectWithValue }) => {
        try {
            let res = {};
            try {
                res = await bookingApi.getListBooking();
            } catch (error) {
                console.log(error);
            }
            const resQuotes: any = await phpHelper.getQuotes();
            let carts = resQuotes?.data?.json;
            if (!carts) carts = store.getState().savedCart.carts;
            return {
                carts,
                res,
            } as ICustomerOder;
        } catch (err: any) {
            return rejectWithValue(err);
        }
    }
);
export const addCart = (cart: ICart) => (dispatch: AppDispatch) => {
    const carts = [
        {
            cart,
            id: uuidv4(),
            date: new Date(),
        },
        ...store.getState().savedCart.carts,
    ];
    phpHelper.saveQuotes(carts);
    dispatch(setSaveCart({ carts, currentSavedCartId: initialState.currentSavedCartId }));
};
export const removeSavedCart = (id: string) => (dispatch: AppDispatch) => {
    const carts = store.getState().savedCart.carts;
    const cartIndex = carts.findIndex((cart: ISavedCart) => cart.id === id);
    const newCarts = [...carts.slice(0, cartIndex), ...carts.slice(cartIndex + 1)];
    phpHelper.saveQuotes(newCarts);
    dispatch(setSaveCart({ carts: newCarts }));
};

export const updateSavedCart = (cart: ICart, id: string) => (dispatch: AppDispatch) => {
    const carts = store.getState().savedCart.carts;
    const cartIndex = carts.findIndex((cart: ISavedCart) => cart.id === id);
    if (cartIndex >= 0) {
        const newCart = [
            ...carts.slice(0, cartIndex),
            {
                cart,
                id: uuidv4(),
                date: new Date(),
            },
            ...carts.slice(cartIndex + 1),
        ];
        phpHelper.saveQuotes(newCart);
        dispatch(
            setSaveCart({ carts: newCart, currentSavedCartId: initialState.currentSavedCartId })
        );
    }
};

export const updateCurrentCartId = (id: string) => (dispatch: AppDispatch) => {
    const carts = store.getState().savedCart.carts;
    const cartIndex = carts.findIndex((cart: ISavedCart) => cart.id === id);
    if (cartIndex >= 0) {
        const cart = carts[cartIndex];
        const newCart = [
            ...carts.slice(0, cartIndex),
            { ...cart, cart: { ...cart.cart, isShare: false } },
            ...carts.slice(cartIndex + 1),
        ];
        phpHelper.saveQuotes(newCart);
        dispatch(setSaveCart({ carts: newCart, currentSavedCartId: id }));
    }
};

const savedCarts = createSlice({
    name: "savedCarts",
    initialState: initialState,
    reducers: {
        setSaveCart: (
            state,
            action: PayloadAction<{ carts: ISavedCart[]; currentSavedCartId?: string }>
        ) => {
            const { carts, currentSavedCartId } = action.payload;
            state.carts = carts;
            if (currentSavedCartId) state.currentSavedCartId = currentSavedCartId;
        },
        setShowPopup(state, action) {
            state.isShowPopup = action.payload;
        },
        resetSavedCart: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCustomerOrder.fulfilled, (state, action) => {
            const bookingReferences = action.payload.res?.data?.map(
                (item: ICustomerOrder) => item.bookingReference
            );
            let cartsQuote: ISavedCart[] = action.payload.carts;

            if (bookingReferences) {
                cartsQuote = action.payload.carts.filter((cart: ISavedCart) => {
                    return !bookingReferences.includes(
                        cart.cart.bookingReference?.toString() || "none"
                    );
                });
            }
            state.carts = cartsQuote;
            state.isLoading = false;
        });
        builder.addCase(getCustomerOrder.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getCustomerOrder.pending, (state) => {
            state.isLoading = true;
        });
    },
});

// export const { toggleFavorite } = savedCarts.actions;

export const selectSavedCarts = (state: RootState) => state.savedCart;

export const { setShowPopup, setSaveCart, resetSavedCart } = savedCarts.actions;

export default savedCarts.reducer;
