import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import styled from "styled-components";
import moment from "moment";

import {
    StyledBookingDetail,
    MenuAccountComponent,
    BreadcrumbsCommon,
    ClipLoaderCommon,
    BookingDetailGeneralComponent,
    StyledButton,
    LoadingCommon,
} from "@components";
import { AppModuleLayout } from "@layouts";
import { PATH_BOOKING, PATH_BOOKING_DETAIL, PATH_HOME } from "@routes";
import {
    getBookingDetail,
    openToast,
    selectBooking,
    selectStatusApp,
    useAppSelector,
} from "@redux";
import { BookingDetailItemModule } from "@module";
import { logger, useWindowDimensions } from "@utilities";
import { ICancelRequest, IListBookings } from "@interfaces";
import { phpHelper } from "src/Utilities/phpHelper";

export const BookingDetail = () => {
    // page hooks
    const { width } = useWindowDimensions();
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    // page redux
    const { bookingDetail, listBooking } = useAppSelector(selectBooking);
    const statusApp = useAppSelector(selectStatusApp).status;
    // page state
    const [bookingPriceDetail, setBookingPriceDetail] = useState<IListBookings>();
    const [loading, setLoading] = useState<boolean>(true);
    const [resendLoading, setResendLoading] = useState<boolean>(false);
    //page variable
    const breadcrumbsModel = [
        {
            title: "Home",
            link: PATH_HOME,
        },
        {
            title: "Bookings",
            link: PATH_BOOKING,
        },
        {
            title: "Details",
            link: PATH_BOOKING_DETAIL,
        },
    ];

    useEffect(() => {
        if (id) {
            fetchBookingDetail(id);
        }
    }, [id]);

    const fetchBookingDetail = async (id: string) => {
        setLoading(true);
        try {
            const res = await dispatch(getBookingDetail(id));

            //@ts-ignore
            unwrapResult(res);

            const bookingPrice = listBooking.find((item) => item.orderId === +id);
            setBookingPriceDetail(bookingPrice);
        } catch (e: any) {
            const message = e?.response?.data?.message || "Somethings went wrong!!";

            logger.error("Update agent info", message);
            dispatch(
                openToast({
                    type: "error",
                    message: message,
                    autoHideDuration: 30000,
                })
            );
            history.push(PATH_BOOKING);
        } finally {
            setLoading(false);
        }
    };
    if (!bookingDetail) {
        return (
            <AppModuleLayout>
                <ClipLoaderCommon color="#31B4B9" size={70} margin={150} />
            </AppModuleLayout>
        );
    }

    const onCancelRequest = (
        voucherNumber: string,
        quantity: string,
        reason: string,
        supplierName: string,
        productName: string,
        fareName: string,
        cancelDes: string,
        cancelRules: {
            daysBeforeTravelDate: string;
            feeAmount: string;
        }[]
    ) => {
        logger.cancelBooking(
            bookingDetail.agentUser?.email || "",
            bookingDetail.agentUser?.name || "",
            id,
            bookingDetail.bookingReference,
            bookingDetail.products[0]?.redeemers[0]?.name || "",
            bookingDetail.products[0]?.redeemers[0]?.email || "",
            quantity,
            reason,
            supplierName,
            productName,
            fareName,
            cancelDes,
            cancelRules
        );
        const cancelRequest: ICancelRequest = {
            bookingReference: bookingDetail.bookingReference,
            voucherNumber,
            quantity,
            reason,
        };

        phpHelper.cancelRequest(cancelRequest);
    };

    const onResendVoucher = async () => {
        setResendLoading(true);
        try {
            await phpHelper.resendVoucher(id);
        } catch {
            //
        } finally {
            setResendLoading(false);
        }
    };

    return (
        <AppModuleLayout>
            {loading ? (
                <ClipLoaderCommon color="#31B4B9" size={70} margin={150} />
            ) : (
                <StyledBookingDetail isLogin={true}>
                    {resendLoading && <LoadingCommon />}
                    <div className="wrapper">
                        <div className="breadCrumbs">
                            <BreadcrumbsCommon data={breadcrumbsModel} />
                        </div>
                        <div className="menu">
                            <MenuAccountComponent />
                        </div>
                        <div className="detailWrapper">
                            <Link to={PATH_BOOKING} className="navigateBtn">
                                <img src="/images/fi_arrow-left.png" alt="" />
                                <span>Bookings</span>
                            </Link>
                            <BookingDetailGeneralComponent
                                bookingInfo={bookingDetail}
                                bookingPriceDetail={bookingPriceDetail}
                                statusApp={statusApp}
                            />
                            {width > 1024 ? <p className="heading1">Booking Detail</p> : ""}
                            <div className="listFare">
                                {width <= 1024 ? <p className="heading">Booking Detail</p> : ""}
                                {bookingDetail.products.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <BookingDetailItemModule
                                                data={item}
                                                isShowCancel={true}
                                                onCancelRequest={onCancelRequest}
                                            />
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                            <StyledButton onClick={onResendVoucher} disabled={resendLoading}>
                                Resend Voucher to Customer
                            </StyledButton>
                            <StyledTerm>
                                <a href="https://freelancetravel.com/booking-terms-and-conditions/">
                                    <h3 className="term-condition">TERMS & CONDITIONS</h3>
                                </a>
                                <p className="term-text">
                                    These tickets expire on (
                                    {moment(bookingDetail.purchaseDate)
                                        .add(1, "years")
                                        .tz("Australia/Melbourne")
                                        .format("DD MMM YYYY")}
                                    )
                                </p>
                            </StyledTerm>
                        </div>
                    </div>
                </StyledBookingDetail>
            )}
        </AppModuleLayout>
    );
};

export const StyledTerm = styled.div`
    margin-top: 40px;
    background-color: #fff;
    padding: 20px;

    .term-condition {
        font-weight: bold;
        font-size: 16px;
        line-height: 160%;
        text-decoration-line: underline;
        color: #090a18;
    }

    .term-text {
        text-transform: none;
    }
`;
