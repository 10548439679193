import styled from "styled-components";
import { Formik, FormikHelpers } from "formik";
import { useDispatch } from "react-redux";

import { AccountComponent, BreadcrumbsCommon, MenuAccountComponent } from "@components";
import { AppModuleLayout } from "@layouts";
import { PATH_MY_ACCOUNT, PATH_HOME } from "@routes";
import { breakpoint, logger } from "@utilities";
import { accountSchema } from "@validations";
import { IAccountInfo, IAgentInfo } from "@interfaces";
import { openToast, selectAgent, setAgentInfo, useAppSelector } from "@redux";
import { authApi } from "@api";
import { phpHelper } from "src/Utilities/phpHelper";
import { DURATION_TIME } from "@constants";

export const MyAccountSetting = () => {
    //hooks
    const dispatch = useDispatch();
    //page redux
    const { agentInfo } = useAppSelector(selectAgent);

    const breadcrumbsData = [
        {
            title: "Home",
            link: PATH_HOME,
        },
        {
            title: "Update My Details",
            link: PATH_MY_ACCOUNT,
        },
    ];

    const handleSubmit = async (
        value: IAccountInfo,
        { setSubmitting }: FormikHelpers<IAccountInfo>
    ) => {
        const { tradingname, bankAccount, bankBsb, businessnumber } = value;

        // WHAT: call api update
        try {
            const agentInfoParams: IAgentInfo = {
                tradingname,
                bankAccount,
                bankBsb,
                businessnumber,
            };
            await authApi.updateAgentInfo(agentInfoParams);
            logger.agentInfo(agentInfoParams);
            // WHAT: save data to redux
            dispatch(setAgentInfo(value));
            await phpHelper.saveAccount(value);
            dispatch(
                openToast({
                    type: "success",
                    message: "Update successfully",
                    autoHideDuration: DURATION_TIME,
                })
            );
        } catch (error: any) {
            const message = error?.response?.data?.message || "Somethings went wrong!!";

            // logger.error("Update agent info", message);
            dispatch(
                openToast({
                    type: "error",
                    message: message,
                    autoHideDuration: DURATION_TIME,
                })
            );
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <AppModuleLayout>
            <StyledMyAccountPage>
                <div className="wrapper">
                    <div className="breadCrumbs">
                        <BreadcrumbsCommon data={breadcrumbsData} />
                    </div>
                    <div className="menu">
                        <MenuAccountComponent />
                    </div>
                    <div className="my_account">
                        <p className="heading1">Update My Details</p>
                        <FormContainer>
                            <Formik
                                initialValues={agentInfo}
                                validationSchema={accountSchema}
                                onSubmit={handleSubmit}
                            >
                                {({
                                    values,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                }) => {
                                    return (
                                        <AccountComponent
                                            values={values}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            handleSubmit={handleSubmit}
                                            isUpdateAccount={true}
                                            isSubmitting={isSubmitting}
                                        />
                                    );
                                }}
                            </Formik>
                        </FormContainer>
                    </div>
                </div>
            </StyledMyAccountPage>
        </AppModuleLayout>
    );
};

const StyledMyAccountPage = styled.div`
    background-color: #F2F2F2;
    min-height: calc(100vh - 95px);

    .wrapper {
        display: block;
        margin: 0 auto;
        position: relative;
        ${breakpoint.breakTablet`
        display: flex;
        width: ${(p) => p.theme.widths.wide_700};
        margin-top: 81px;
        padding-bottom: 20px;

    `}

        ${breakpoint.breakIpadPro`
        width: ${(p) => p.theme.widths.wide_900};
    `}

    ${breakpoint.breakLarge`
        width: ${(p) => p.theme.widths.wide_1110};
    `}

    ${breakpoint.breakOnlyMobile`
           background-color:#ffffff;
       `}
    .breadCrumbs {
            position: absolute;
            bottom: calc(100% + 30px);
            display: none;

            ${breakpoint.breakTablet`
            display: block;
        `}
        }

        .menu {
            display: none;

            ${breakpoint.breakTablet`
            display: block;
        `}
        }
    }

    .my_account {
        padding: 24px 16px;
        background-color: #F2F2F2;
        position: relative;
        top: 11px;
        margin-bottom: 11px;

        ${breakpoint.breakTablet`
        flex: 1;
        padding: 0;
       `}
        ${breakpoint.breakOnlyMobile`
           background-color:#ffffff;
       `}

        .heading1 {
            font-size: 28px;
            font-weight: 700;
            line-height: 36px;
            margin-bottom: 30px;
            margin-top: -10px;
            display: none;

            ${breakpoint.breakTablet`
            display: block;
        `}
        }
    }
`;

const FormContainer = styled.div`
    background-color: #fff;
    padding: 30px 30px 50px 30px;
`;
