import { useEffect } from "react";
import { useHistory } from "react-router-dom";
const queryString = require("query-string");

import { PATH_HOME } from "@routes";
import { LoadingCommon } from "@components";

export const PaymentWrapper = () => {
    //page hooks
    const history = useHistory();
    //payment wrapper => return url
    useEffect(() => {
        if (window.location.search) {
            //get url return param
            const params = window.location.search.substring(1);

            const paramsObj = queryString.parse(params);

            if (paramsObj.url) {
                window.location.href = paramsObj.url;
            } else {
                history.push(PATH_HOME);
            }
        } else {
            history.push(PATH_HOME);
        }
    }, []);

    return <LoadingCommon />;
};
