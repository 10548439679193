import { useState } from "react";
import { useHistory } from "react-router-dom";

import { useWindowDimensions } from "@utilities";
import { HeaderComponent, HeaderNavigatorComponent } from "@components";
import { PATH_MENU_SETTING, PATH_HOME } from "@routes";
import { selectCart, useAppSelector } from "@redux";

interface IHeaderModuleProps {
    onBack?: () => void;
}

export const HeaderModule = (props: IHeaderModuleProps) => {
    // page hook
    const { width } = useWindowDimensions();
    const history = useHistory();
    // page states
    const [isScrollUp, setOnScrollUp] = useState<boolean>(window.scrollY > 50);
    const [isShowMenu, setIsShowMenu] = useState<boolean>(false);
    // page redux
    const productCount = useAppSelector(selectCart).carts.reduce(
        (accumulator, cartItem) => accumulator + cartItem.quantity,
        0
    );
    // page variable
    const pathName = location.pathname;
    const isHeader = pathName === PATH_HOME;
    const headerFix = pathName === PATH_HOME;
    // const isHeaderText = pathName === PATH_SAVED_CART || pathName === PATH_BOOKING;

    window.onscroll = () => {
        setOnScrollUp(window.scrollY > 50);
    };

    const handleBack = () => {
        if (props.onBack) {
            props.onBack();
            return;
        }
        window.history.back();
    };

    const handleBackToHome = () => {
        history.push(PATH_HOME);
    };

    const handleToggleShowMenu = () => {
        setIsShowMenu(!isShowMenu);
    };

    if (width >= 767 || isHeader) {
        return (
            <HeaderComponent
                headerFix={headerFix}
                isScrollUp={isScrollUp}
                toggleShowMenu={handleToggleShowMenu}
                isShowMenu={isShowMenu}
                productCount={productCount}
            />
        );
    }

    if (width < 767 && pathName === PATH_MENU_SETTING) {
        return <></>;
    }

    // if (isHeaderText) {
    //     let headerTextTitle = "";
    //     if (pathName === PATH_SAVED_CART) {
    //         headerTextTitle = "Saved Quotes";
    //     } else if (pathName === PATH_BOOKING) {
    //         headerTextTitle = "My Booking";
    //     }
    //     return <StyledHeaderTitle>{headerTextTitle}</StyledHeaderTitle>;
    // }

    return (
        <HeaderNavigatorComponent
            onBack={handleBack}
            onBackToHome={handleBackToHome}
            productCount={productCount}
        />
    );
};
