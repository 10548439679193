import { Carousel } from "react-responsive-carousel";
import styled from "styled-components";

import { BreadcrumbsCommon, StyledButton, StyledRatio35Image, IconMapPin } from "@components";
import { breakpoint } from "@utilities";
import { IDetailTour, IBreadcrumbs } from "@interfaces";
import { HeartTourModule } from "@module";

interface IProps {
    breadcrumbsData: IBreadcrumbs[];
    data: IDetailTour;
    onClickCheckAvail?: () => void;
    handleClickSupplier?: () => void;
}
export const ProductDetailComponent = (props: IProps) => {
    const { breadcrumbsData, data, onClickCheckAvail, handleClickSupplier } = props;
    const pathname = window.location.pathname;
    return (
        <Container>
            <div className="backgroundInfo">
                <div className="info">
                    <div className="listImages">
                        <div className="breadcrumbs">
                            <BreadcrumbsCommon data={breadcrumbsData} />
                        </div>
                        <Carousel infiniteLoop showThumbs={false}>
                            {data.images?.map((img) => {
                                return <StyledRatio35Image key={img.mediaId} img={img.path} />;
                            })}
                        </Carousel>
                    </div>
                    <div className="main">
                        <div className="heart">
                            <HeartTourModule tour={data} />
                        </div>
                        <h1 className="title">{data.name}</h1>
                        <p className="place">
                            {/* <img
                                src="/images/fi_map-pin-main-color.png"
                                alt="fi_map-pin-main-color"
                            /> */}
                            <IconMapPin />
                            <span>{data.location}</span>
                        </p>
                        <p className="supplier" onClick={handleClickSupplier}>
                            Supplier: <span>{data.supplier}</span>
                        </p>
                        <div className="tripIdNTourCode">
                            <div className="tripId">
                                Trip ID: <span>{data.productId}</span>
                            </div>
                            <div className="tourCode">
                                Tour Code: <span>{data.tourCode}</span>
                            </div>
                        </div>
                        <div className="subWrapper">
                            <div className="durationNPrice">
                                {data.duration && (
                                    <div className="duration">
                                        <img src="/images/fi_clock.png" alt="clock_icon" />{" "}
                                        <span>{data.duration}</span>
                                    </div>
                                )}
                                <div className="price">
                                    From <span>${Number(data.rrp).toFixed(2)}</span>
                                </div>
                            </div>
                            {pathname.includes("detail-tour") && (
                                <div className="checkAvaiBtn" onClick={onClickCheckAvail}>
                                    <StyledButton>Check Availability</StyledButton>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
};

const Container = styled.div`
    color: ${(props) => props.theme.colors.black};

    ${breakpoint.breakTablet`
        width: 100%;
        background-color: #F2F2F2;
        flex: 1;
        padding-bottom: 60px;
    `}

    width: 100%;
    background-color: #F2F2F2;
    flex: 1;
    padding-bottom: 60px;

    .carousel .control-dots {
        display: none;
    }

    .carousel .thumbs-wrapper {
        display: none;
    }

    .carousel .carousel-status {
        display: none;
    }

    .carousel.carousel-slider .control-arrow:hover {
        background-color: transparent;
    }

    .backgroundInfo {
        background-color: #fff;
    }

    .info {
        width: ${(props) => props.theme.widths.wide_700};
        margin: 0 auto;
        padding: 60px 0;
        display: flex;

        ${breakpoint.breakIpadPro`
            width: ${(props) => props.theme.widths.wide_900};
        `}

        ${breakpoint.breakLarge`
            width: ${(props) => props.theme.widths.wide_1110};
        `}
        
        .listImages {
            flex: 1;
            position: relative;

            .breadcrumbs {
                position: absolute;
                left: 0;
                bottom: calc(100% + 10px);
            }
        }

        .main {
            flex: 1;
            margin-left: 10px;
            position: relative;

            ${breakpoint.breakIpadPro`
                margin-left: 18px;
            `}

            ${breakpoint.breakLarge`
                margin-left: 32px;
            `}
        }
    }

    .main {
        .title {
            font-size: 15px;
            font-weight: 500;
            max-width: 400px;
            margin-bottom: 6px;
            width: 85%;

            ${breakpoint.breakIpadPro`
                font-size: 17px;
                margin-bottom: 12px;
            `}

            ${breakpoint.breakLarge`
                font-size: 24px;
                max-width: 450px;
                margin-bottom: 17px;
            `}
        }

        .heart {
            position: absolute;
            top: 0;
            right: 0;
        }

        .place {
            display: flex;
            align-items: center;
            border-bottom: ${(props) => `1px solid ${props.theme.colors.gray_5}`};
            padding-bottom: 6px;
            max-width: 400px;

            ${breakpoint.breakIpadPro`
                padding-bottom: 12px;
            `}

            ${breakpoint.breakLarge`
                padding-bottom: 21px;
                max-width: 450px;
            `}

            svg {
                width: 17px;

                ${breakpoint.breakIpadPro`
                    width: auto;
                    margin-right: 10px;
                `}

                path {
                    stroke: ${(p) => p.theme.colors.black};
                }
            }

            span {
                font-weight: 400;
                font-size: 14px;
                color: ${(props) => props.theme.colors.black};

                ${breakpoint.breakLarge`
                    font-size: 16px;
                `}
            }
        }

        .supplier {
            font-weight: 700;
            font-size: 14px;
            margin-top: 6px;
            cursor: pointer;
            line-height: 1.5em;
            
            ${breakpoint.breakIpadPro`
                font-size: 15px;
                margin-top: 8px;
            `}

            ${breakpoint.breakLarge`
                font-size: 16px;
                margin-top: 20px;
            `}

            span {
                color: ${(props) => props.theme.colors.main};
            }
        }

        .tripIdNTourCode {
            font-weight: 400;
            font-size: 13px;
            border-bottom: ${(props) => `1px solid ${props.theme.colors.gray_5}`};
            margin-top: 6px;
            padding-bottom: 6px;

            ${breakpoint.breakIpadPro`
                margin-top: 15px;
                padding-bottom: 12px;
            `}

            ${breakpoint.breakLarge`
                margin-top: 8px;
                padding-bottom: 20px;
            `}
        }

        .tripId,
        .tourCode {
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;

            ${breakpoint.breakIpadPro`
                font-size: 15px;
            `}

            ${breakpoint.breakLarge`
                font-size: 16px;
                line-height: 25.6px;
            `}
        }

        .subWrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .durationNPrice {
                display: block;
                font-size: 16px;

                .price {
                    margin-top: 12px;

                    span {
                        font-weight: 500;
                    }
                }
            }

            .checkAvaiBtn {
                button {
                    margin-top: 5px;
                    width: 150px;
                    font-size: 14px;
                    height: 40px;

                    ${breakpoint.breakIpadPro`
                        margin-top: 10px;
                        width: 198px;
                        font-size: 16px;
                        height: 54px;
                    `}

                    ${breakpoint.breakLarge`
                        margin-top: 20px;
                    `}
                }
            }
        }

        .durationNPrice {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 5px;

            ${breakpoint.breakIpadPro`
                margin-top: 10px;
            `}

            ${breakpoint.breakIpadPro`
                margin-top: 20px;
            `}
        }

        .duration {
            display: flex;
            align-items: center;
            font-weight: 700;
            font-size: 14px;

            ${breakpoint.breakIpadPro`
                font-size: 17px;
            `}

            img {
                width: 17px;
                margin-right: 5px;

                ${breakpoint.breakIpadPro`
                    width: auto;
                `}
            }

            span {
                ${breakpoint.breakLarge`
                    font-size: 16px;
                    margin-left: 10px;
                `}
            }
        }

        .price {
            font-weight: 400;
            font-size: 14px;
            color: ${(props) => props.theme.colors.gray_3};

            ${breakpoint.breakIpadPro`
                font-size: 17px;
            `}

            ${breakpoint.breakLarge`
                font-size: 16px;
            `}

            span {
                color: ${(props) => props.theme.colors.main};
                font-weight: 700;
                font-size: 20px;
                margin-left: 12px;

                ${breakpoint.breakIpadPro`
                    font-size: 24px;
                `}

                ${breakpoint.breakLarge`
                    font-size: 24px;
                `}
            }
        }
    }
`;
