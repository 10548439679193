import { memo, useState } from "react";

import { ICheckAvail } from "@interfaces";
import { AvailBlockComponent } from "@components";

interface IDataItem {
    data: ICheckAvail | null;
    date: Date;
    day: number;
}

interface IProps {
    processData: IDataItem;
    isChecked: boolean;
    onCheck: (checkDate: ICheckAvail) => void;
}

export const AvailBlockModule = memo((props: IProps) => {
    //props
    const { processData, isChecked, onCheck } = props;
    //page state
    const [isCheck, setIsCheck] = useState<boolean>(isChecked);

    const handleClick = () => {
        if (processData.data) {
            setIsCheck(!isCheck);
            onCheck(processData.data);
        }
    };

    return (
        <AvailBlockComponent
            processData={processData}
            isChecked={isCheck}
            onCheck={handleClick}
        />
    );
});
