import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";
const queryString = require("query-string");
import { memo, useCallback, useEffect, useState } from "react";

import {
    StyledButton,
    DatePickerCommon,
    StyledBookingTimeError,
    ClipLoaderCommon,
} from "@components";
import { PATH_CHECK_AVAILABILITY } from "@routes";
import { ITourFare, IDateRange, IFareAvail } from "@interfaces";
import { openToast, getFarePrice } from "@redux";
import { DURATION_TIME } from "@constants";

interface IProps {
    data: ITourFare;
}

export const FareTypeModule = memo((props: IProps) => {
    // page hooks
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch();
    // props
    const { data } = props;
    //page state
    const [date, setDate] = useState<IDateRange>({
        from: null,
        to: null,
    });
    const [bookingTime, setBookingTime] = useState<string>();

    useEffect(() => {
        dispatch(getFarePrice(data.productPricesDetailsId, data.fareName));
    }, []);

    useEffect(() => {
        setBookingTime(data.bookingTimeId?.toString());
    }, [data.bookingTimeId]);

    const handleSwitchToCheckAvail = () => {
        const dateFrom = new Date(date.from || "");
        const dateTo = new Date(date.to || "");

        if (dateFrom.getTime() >= dateTo.getTime()) {
            dispatch(
                openToast({
                    type: "error", // type of toast message
                    message:
                        "Invalid date range: Please choose a start date that is after the end date.", // message
                    autoHideDuration: DURATION_TIME, // during time
                })
            );
            return;
        }
        if (!date.from || !date.to) {
            // example_toast
            dispatch(
                openToast({
                    type: "error", // type of toast message
                    message: "Please select a date range.", // message
                    autoHideDuration: DURATION_TIME, // during time
                })
            );
            return;
        }

        if (!bookingTime) {
            dispatch(
                openToast({
                    type: "error", // type of toast message
                    message: "You have to choose a booking time", // message
                    autoHideDuration: DURATION_TIME, // during time
                })
            );
            return;
        }

        const params: IFareAvail = {
            fareName: data.fareName,
            productPricesDetailsId: data.productPricesDetailsId,
            ...date,
            bookingTime,
            minDate: travelStart,
            maxDate: data.travelEnd ? new Date(data.travelEnd) : null,
        };
        // console.log(`${PATH_CHECK_AVAILABILITY}/${id}?${queryString.stringify(params)}`);
        history.push(`${PATH_CHECK_AVAILABILITY}/${id}?${queryString.stringify(params)}`);
    };

    const now = new Date();
    const travelStart = new Date(data.travelStart);
    const limitDate = {
        from: {
            minDate: travelStart > now ? travelStart : now, // không được chọn ngày nhỏ hơn ngoài khoảng 14 ngày
            maxDate: date.to || (data.travelEnd ? new Date(data.travelEnd) : null), // không được chọn ngày lớn hơn toDate
        },
        to: {
            minDate: date.from || (travelStart > now ? travelStart : now),
            maxDate: data.travelEnd ? new Date(data.travelEnd) : null,
        },
    };

    const handleChangeTime = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setBookingTime(event.target.value);
    }, []);

    const changeDateFrom = useCallback(
        (dateCheck: Date) => {
            setDate({ to: date.to, from: dateCheck });
        },
        [date]
    );

    const changeDateTo = useCallback(
        (dateCheck: Date) => {
            setDate({ from: date.from, to: dateCheck });
        },
        [date]
    );

    return (
        <Container>
            <div className="info">
                <p className="heading">Fare Type</p>
                <p className="title">{data.fareName}</p>
                {data.travelStart && (
                    <div className="travel-time">
                        Travel between {data.travelStart} and {data.travelEnd}
                    </div>
                )}
                {!data.bookingTimeError && (
                    <div className="choseDate">
                        <div className="from">
                            <DatePickerCommon
                                label="From"
                                value={date.from}
                                onChange={changeDateFrom}
                                limitDate={limitDate.from}
                            />
                        </div>
                        <div className="to">
                            <DatePickerCommon
                                label="To"
                                value={date.to}
                                onChange={changeDateTo}
                                limitDate={limitDate.to}
                                minDateMessage="End date should not be before start date"
                            />
                        </div>
                    </div>
                )}
            </div>
            {!data.bookingTimeError ? (
                <>
                    <p className="price">
                        {data.bookingTimes && data.bookingTimes.length > 0 ? (
                            <SelectTime>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">
                                        Booking Time (Select One)
                                    </FormLabel>
                                    <RadioGroup onChange={handleChangeTime} name="bookingTime">
                                        {data.bookingTimes.map((time, index) => (
                                            <FormControlLabel
                                                key={index}
                                                value={time.BookingTimeID.toString()}
                                                control={<Radio />}
                                                checked={
                                                    bookingTime?.toString() ===
                                                    time.BookingTimeID.toString()
                                                }
                                                label={time.BookingTime}
                                            />
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            </SelectTime>
                        ) : (
                            <ClipLoaderCommon color="#31B4B9" size={35} margin={5} />
                        )}
                        From <span className="rrp"> ${data.rrp}</span>
                        {!!data.levy && (
                            <div className="note">
                                + ${data.levy.toFixed(2)} to pay on arrival {data.levyDescription}
                            </div>
                        )}
                    </p>
                    <div className="listButton">
                        {/* <StyledButton color="#090A18" onClick={() => history.push(PATH_CART)}>Add to Cart Open Dated</StyledButton> */}
                        <StyledButton
                            className="check_availability"
                            onClick={handleSwitchToCheckAvail}
                        >
                            Check Availability
                        </StyledButton>
                    </div>
                </>
            ) : (
                <>
                    <p className="price">
                        From <span className="rrp"> ${data.rrp}</span>
                    </p>
                    <div className="listBtn">
                        <StyledBookingTimeError>
                            We are unable to check live availability for this fare right now. Please
                            try again later.
                        </StyledBookingTimeError>
                    </div>
                </>
            )}
        </Container>
    );
});

const Container = styled.div`
    background-color: #fff;
    padding-top: 13px;
    margin-bottom: 30px;

    .info,
    .price {
        padding: 0 16px;
    }

    .info {
        .heading {
            font-weight: 700;
            font-size: 16px;
            margin-bottom: 4px;
        }

        .title {
            font-weight: 400;
            font-size: 16px;
            margin-bottom: 9px;
            line-height: 21px;
        }
        .travel-time {
            font-weight: normal;
            line-height: 21px;
            font-size: 14px;
            color: ${(props) => props.theme.colors.gray_3};
        }
    }

    .price {
        font-size: 16px;
        color: ${(props) => props.theme.colors.gray_3};
        padding: 12px 16px;
        padding-top: 18px;
        border-top: ${(props) => `1px solid ${props.theme.colors.gray_5}}`};
        span {
            font-weight: bold;
            color: ${(props) => props.theme.colors.main};
            display: inline-block;
        }
        .note {
            font-size: 14px;
            margin-top: 6px;
        }
    }
    .choseDate {
        display: flex;

        .from {
            margin-right: 16px;
        }
    }

    .listButton {
        display: flex;

        button {
            font-family: DM Sans;
            font-size: 12px;
            font-weight: 700;
            line-height: 19px;
            margin-top: 0;
        }
    }

    .MuiButtonBase-root span {
        color: #000;
    }
    .MuiRadio-colorSecondary.Mui-checked span {
        color: ${(p) => p.theme.colors.main};
    }
    .MuiInputBase-root {
        margin-bottom: 12px;
    }
    .rrp {
        font-size: 20px;
    }
    .check_availability {
        font-size: 16px !important;
    }
`;

const SelectTime = styled.div`
    .MuiFormControl-root {
        color: #090a18;
    }
    .MuiFormLabel-root {
        font-weight: bold;
        color: #090a18 !important;
        margin-bottom: 12px;
    }
    .MuiTypography-root {
        color: #090a18;
    }
    .MuiFormControlLabel-root {
        font-size: 14px;
    }
`;
