import styled from "styled-components";

interface IProps {
    children?: string | JSX.Element | JSX.Element[];
    isShowPopup: boolean;
    onClickOverlay: () => void;
    onClickBtnYes: () => void;
    onClickBtnNo: () => void;
}

export const ConfirmComponent = (props: IProps) => {
    const { 
        children, 
        isShowPopup, 
        onClickOverlay, 
        onClickBtnYes, 
        onClickBtnNo 
    } = props;
    return (
        <StyledPopup isShowPopup={isShowPopup}>
            <div className="overlay" onClick={onClickOverlay}></div>
            <div className="main">
                <p className="question">{children}</p>
                <div className="listBtn">
                    <button className="btn yes" onClick={onClickBtnYes}>Yes</button>
                    <button className="btn no" onClick={onClickBtnNo}>No</button>
                </div>
            </div>
        </StyledPopup>
    );
};

const StyledPopup = styled.div<{ isShowPopup: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100vw;
    height: 100vh;
    display: ${p => p.isShowPopup ? "flex" : "none"};
    justify-content: center;
    align-items: center;
    padding: 20px;

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .3);
    }

    .main {
        background-color: #fff;
        z-index: 1;
        border-radius: 4px;
        
        .question {
            padding: 10px 15px;
            border-bottom: 1px solid ${p => p.theme.colors.gray_4};
            line-height: 22px;
            /* font-weight: 500; */
        }

        .listBtn {
            display: flex;

            .btn {
                flex: 1;
                padding: 10px 0;
                /* font-weight: 500; */
                font-size: 16px;
            }

            .yes {
                border-right: 1px solid ${p => p.theme.colors.gray_4};
                color: ${p => p.theme.colors.red};
            }
        }
    }
`;