import styled from "styled-components";

import { breakpoint } from "@utilities";

export const StyledSearchDesktopComponent = styled.div<{
    isShowFilter: boolean;
    width: number;
}>`
    box-shadow: 0px 19px 107px 3px rgba(0, 0, 0, 0.1);

    ${breakpoint.breakTablet`
        position: absolute;
        top: 50%;
        left: 50%;
        width: ${(p) => p.theme.widths.wide_700};
        transform: translate(-50%, -50%);

        .mobile {
            display: none !important;
        }
    `}

    ${breakpoint.breakIpadPro`
        width: ${(p) => p.theme.widths.wide_900};
    `}

    ${breakpoint.breakLarge`
        width: ${(p) => p.theme.widths.wide_1110};
    `}

    .searchBox {
        display: flex;
        background-color: #fff;

        ${breakpoint.breakOnlyMobile`
            height: 86px;
        `}

        ${breakpoint.breakTablet`
            height: 47px;
        `}

        ${breakpoint.breakIpadPro`
            height: 80px;
        `}
    }

    .searchIcon {
        flex: ${(p) => (p.isShowFilter ? "80" : "90")};
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid ${(p) => p.theme.colors.gray_5};

        ${breakpoint.breakOnlyMobile`
            display: none;
        `}
        svg {
            ${breakpoint.breakTablet`
                width: 18px;
            `}
            ${breakpoint.breakIpadPro`
                width: 24px;
            `}
        }
    }

    .inputBox {
        flex: ${(p) => (p.isShowFilter ? "911" : "629")};
        padding: 8px 10px;
        border-right: 1px solid ${(p) => p.theme.colors.gray_5};
        position: relative;

        ${breakpoint.breakOnlyMobile`
            flex: 288;
            padding: 16px 20px 16px 16px;
        `}

        ${breakpoint.breakIpadPro`
            padding: 13px 10px;
        `}

        .searchIconMobile {
            position: absolute;
            top: 50%;
            left: 35px;
            transform: translateY(-50%);
        }

        input {
            width: 100%;
            height: 100%;
            background-color: ${(p) => p.theme.colors.gray_6};
            padding: 14px;
            font-size: 14px;
            font-weight: 400;
            line-height: 26px;

            ${breakpoint.breakOnlyMobile`
                padding: 14px 14px 14px 52px;
            `}

            ${breakpoint.breakTablet`
                font-size: 12px;
            `}

            ${breakpoint.breakIpadPro`
                font-size: 16px;
            `}

            &::placeholder {
                color: ${(p) => p.theme.colors.gray_3};
            }
        }
    }

    .filterBtn {
        flex: ${(p) => (p.isShowFilter ? "119" : "136")};
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: ${(p) => (p.isShowFilter ? p.theme.colors.black : "#fff")};

        ${breakpoint.breakOnlyMobile`
            flex: 87;
        `}

        button {
            color: ${(p) => (p.isShowFilter ? "#fff" : p.theme.colors.main)};
            font-size: 12px;
            font-weight: 700;
            line-height: 26px;
            display: flex;
            justify-content: center;
            align-items: center;

            ${breakpoint.breakOnlyMobile`
                flex-direction: column;
                color: ${(p) => p.theme.colors.black};
                font-size: 16px;
                font-weight: 700;
                line-height: 26px;
            `}

            ${breakpoint.breakTablet`
                font-size: 12px;
            `}

            ${breakpoint.breakIpadPro`
                font-size: 16px;
            `}
        }

        span {
            margin-left: 5px;

            ${breakpoint.breakIpadPro`
                margin-left: 12px;
            `}
        }

        svg {
            ${breakpoint.breakTablet`
                width: 18px;
            `}
            ${breakpoint.breakIpadPro`
                width: 24px;
            `}
            path {
                stroke: ${(p) => (p.isShowFilter ? "#fff" : p.theme.colors.main)};
            }
        }
    }

    .searchBtn {
        flex: 255;
        display: ${(p) => (p.isShowFilter ? "none" : "block")};

        ${breakpoint.breakOnlyMobile`
            display: none;
        `}

        button {
            margin-top: 0;
            height: 100%;
            ${breakpoint.breakTablet`
                font-size: 12px;
            `}

            ${breakpoint.breakIpadPro`
                font-size: 14px;
            `}
        }
    }

    .filterBox {
        transition: 300ms;
        display: ${(p) => (p.width > 767 ? (p.isShowFilter ? "block" : "none") : "block")};
        transform: ${(p) =>
            p.width < 767 ? (p.isShowFilter ? "translateX(0)" : "translateX(100%)") : "unset"};

        @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            z-index: 3;
            max-height: 100vh;
            overflow: scroll;
        }
    }

    .searchBtnMobile {
        padding: 24px 16px 26px 16px;
        background-color: #fff;
        border-top: 1px solid ${(p) => p.theme.colors.gray_5};
        display: block;

        button {
            margin-top: 0;
        }
    }
`;
