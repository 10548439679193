import { breakpoint } from "@utilities";
import styled from "styled-components";

export const StyledExpandedCard = styled.div`
    background-color: #fff;
    padding: 20px 0;
    margin-bottom: 18px;
`;

export const StyledCardHeader = styled.div<{ isExpanded: boolean }>`
    position: relative;
    padding: 0 20px;
    cursor: pointer;

    .expandBtn {
        position: absolute;
        top: 5px;
        right: 20px;
        transform: ${(p) => (p.isExpanded ? "rotate(180deg)" : "unset")};
    }

    .voucherNumber {
        font-size: 16px;
        font-weight: 700;
        line-height: 26px;
        text-transform: uppercase;
        color: ${(p) => p.theme.colors.main};
    }

    .title {
        font-size: 16px;
        font-weight: 700;
        line-height: 21px;
        margin-top: 8px;
    }

    .cancel-request {
        color: red;
        margin-top: 1em;
    }
`;

export const StyledCardBody = styled.div<{ isExpanded: boolean }>`
    height: ${(p) => (p.isExpanded ? "auto" : "0")};
    /* transition: 300ms; */
    overflow: hidden;
    padding: 0 20px;

    .infoBlock {
        margin-top: 10px;
        position: relative;

        &.bottom {
            padding-bottom: 0;

            &::after {
                display: none;
            }
        }
        &.top {
            padding-bottom: 0;
        }

        &:nth-child(1)::after {
            content: "";
            position: absolute;
            width: calc(100% + 40px);
            height: 1px;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            background-color: ${(p) => p.theme.colors.gray_5};

            ${breakpoint.breakTablet`
                width: 100%;
            `}
        }
    }

    .infoBreak {
        content: "";
        width: calc(100% + 40px);
        height: 1px;
        bottom: 0;
        left: 50%;
        transform: translateX(-6%);
        background-color: ${(p) => p.theme.colors.gray_5};
        margin: 1em 0;
        ${breakpoint.breakTablet`
            transform: translateX(-3%);
        `}
    }

    .infoItem {
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
        color: ${(p) => p.theme.colors.gray_1};

        .fieldName {
            text-transform: uppercase;
        }
        &.flexColumn {
            .infoBlock.middle:nth-child(4) {
                padding-top: 22px;
                margin-top: 22px;
            }
            .infoBlock.middle:nth-child(4)::before {
                content: "";
                position: absolute;
                width: calc(100% + 40px);
                height: 1px;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                background-color: ${(p) => p.theme.colors.gray_5};

                ${breakpoint.breakTablet`
                width: 100%;
            `}
            }
        }

        .value {
            font-weight: 400;
            &.pickupLocation {
                ${breakpoint.breakTablet`
                    padding-right: 10px;
                    border-right: 1px solid #BDBDBD;
                `}

                ${breakpoint.breakIpadPro`
                    padding-right: 17px;
                `}
            }

            &.checkInTime {
                ${breakpoint.breakTablet`
                    padding-left: 10px;
                `}

                ${breakpoint.breakIpadPro`
                    padding-left: 17px;
                `}
            }

            &.phoneItem {
                ${breakpoint.breakTablet`
                    margin-right: 20px;
                `}
            }
        }
    }
    .break {
        border-bottom: 1px solid ${(p) => p.theme.colors.gray_5};
    }

    .marginBottom12 {
        margin-bottom: 12px;
    }

    .marginBottom18 {
        margin-bottom: 18px;
    }

    .cancelBtn {
        margin: 0 auto;
        max-width: 350px;
    }
`;
