import styled from "styled-components";

import { breakpoint } from "@utilities";

export const FieldNameCommon = styled.div`
    font-size: 15px;
    font-weight: 500;
    line-height: 26px;
    position: relative;

    ${breakpoint.breakIpadPro`
        font-size: 20px;
    `}

    .sortIcon {
        width: 10px;
        margin-left: 7px;

        ${breakpoint.breakLarge`
            width: 14px;
            margin-left: 13px;
        `}
    }
`;