import styled from "styled-components";

import { IHeaderComponent } from "@components";
import { breakpoint } from "src//Utilities/theme";

export const StyledHeader = styled.div<IHeaderComponent>`
    position: ${({ headerFix }) => (headerFix ? "fixed" : "sticky")};
    top: 0;
    z-index: 3;
    /* left: 50%;
    transform: translateX(-50%); */
    width: 100%;
    transition: 300ms;

    ${breakpoint.breakLarge`
        padding: 0;
    `}

    .backgroundHeader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: ${({ headerFix, isScrollUp }) => (isScrollUp || !headerFix ? "100%" : "0")};
        background-color: ${({ headerFix, isScrollUp }) =>
            isScrollUp || !headerFix ? "#fff" : "transparent"};
        transition: 300ms;
        border-bottom: 1px solid ${p => p.isScrollUp || !p.headerFix ? p.theme.colors.gray_5 : "transparent"};
    }

    .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        padding: 25px 15px;

        ${breakpoint.breakMobileMedium`
            width: ${(props) => props.theme.widths.wide_700};
        `}

        ${breakpoint.breakTablet`
            padding: 25px 0;
        `}

        ${breakpoint.breakIpadPro`
            width: ${(props) => props.theme.widths.wide_900};
        `}

        ${breakpoint.breakLarge`
            width: ${(props) => props.theme.widths.wide_1110};
        `}
    }

    .logo {
        height: 35px;
        position: relative;

        .logoItem {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 147px;

            ${breakpoint.breakIpadPro`
                width:300px;
            `}

            ${breakpoint.breakTablet`
            width:300px;
            `}

            ${breakpoint.breakLarge`
                width:350px;
            `}

            &.logoWhite {
                opacity: ${({ headerFix, isScrollUp }) => (isScrollUp || !headerFix ? "0" : "1")};
            }

            &.logoColor {
                opacity: ${({ headerFix, isScrollUp }) => (isScrollUp || !headerFix ? "1" : "0")};
            }
        }
    }

    .cart {
        display: flex;
        align-items: center;

        .switchBtn {
            margin-right: 30px;
        }

        .cartIconWrapper {
            position: relative;
            width: 26px;
            height: 26px;

            .number {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 14px;
                height: 14px;
                font-size: 10px;
                border-radius: 50%;
                color: #fff;
                top: 0;
                left: 100%;
                transform: translateX(-50%);
                background-color: ${(props) => props.theme.colors.main};
                z-index: 2;
            }

            svg {
                path {
                    stroke: ${({ headerFix, isScrollUp, theme }) =>
                        isScrollUp || !headerFix ? theme.colors.black : "#fff"};
                }
            }
        }

        .userIconWrapper {
            display: none;
            width: 40px;
            height: 40px;
            position: relative;
            position: relative;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: ${p => p.isShowMenu  ? p.theme.colors.gray_6 : "transparent"};
            transition: 300ms;

            ${breakpoint.breakTablet`
                display: flex;
                margin-left: 30px;
            `}

            svg {
                cursor: pointer;

                path {
                    stroke: ${({ headerFix, isScrollUp, theme, isShowMenu }) =>
                        isScrollUp || !headerFix || isShowMenu ? theme.colors.black : "#fff"};
                }
            }
        }
    }
`;

export const StyledHeaderTitle = styled.h6`
    font-size: 18px;
    font-weight: 700;
    line-height: 23px;
    padding-top: 44px;
    padding-bottom: 28px;
    text-align: center;
    color: #fff;
    background-color: ${(props) => props.theme.colors.main};
    position: sticky;
    top: 0;
    z-index: 1;
`;
