import { memo } from "react";
import styled from "styled-components";

import { IPasswordInfo, IHandleChange, IHandleBlur, IHandleSubmit } from "@interfaces";
import { InputCommon, StyledButton } from "@components";
import { breakpoint } from "@utilities";

interface IProps {
    values: IPasswordInfo;
    handleChange: IHandleChange;
    handleBlur: IHandleBlur;
    handleSubmit: IHandleSubmit;
    isSubmitting:boolean;
}
export const PasswordComponent = memo((props: IProps) => {
    const { values, handleChange, handleSubmit, handleBlur, isSubmitting } = props;
    return (
        <Container onSubmit={handleSubmit}>
            <InputCommon
                name="password"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.password}
                label="New password"
                required
                type="password"
            />

            <InputCommon
                name="confirmPassword"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.confirmPassword}
                label="Confirm password"
                required
                type="password"
            />

            <StyledButton disabled={isSubmitting} className="submit_account" type="submit">Update</StyledButton>
        </Container>
    );
});

const Container = styled.form`
    .Wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .col_48 {
            width: 48%;
        }
        ${breakpoint.breakOnlyMobile`
        .col_48.m_100 {
            width: 100%;
         }
        `};
    }
    .submit_account {
        width: 40%;
        margin: auto;
        margin-top: 50px;
        ${breakpoint.breakOnlyMobile`
        width: 100%;
        margin-top: 30px;
        `};
    }
    .MuiFormControl-root {
        height: 65px;
        margin: 0 !important;
    }
    .title__account.form {
        margin-top: 36px;
        margin-bottom: 12;
    }
`;
