import styled from "styled-components";
import { memo } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { PointCommon, CartItemComponent, CartItemInfoComponent } from "@components";
import { ICustomerWithCart, IFareCart } from "@interfaces";
import { selectCart, useAppSelector, openToast, updateCustomerQuantity } from "@redux";
import { PATH_DETAIL_TOUR } from "@routes";
import { DURATION_TIME } from "@constants";

interface ICheckoutProductModule {
    product: IFareCart;
    handleDeletePopUp?: (cartDeleteId?: string | number) => void;
    customers?: ICustomerWithCart[];
}

export const CheckoutProductModule = memo((props: ICheckoutProductModule) => {
    //props
    const { product, handleDeletePopUp, customers } = props;
    //hooks
    const history = useHistory();
    const dispatch = useDispatch();
    //redux state
    const cartRedux = useAppSelector(selectCart);
    //page variable
    const productQuantity = cartRedux.customers
        ? cartRedux.customers.reduce((totalQuantity, customer) => {
              const quantity = customer.carts.find((cart) => cart.id === product.id)?.quantity || 0;
              return totalQuantity + quantity;
          }, 0)
        : 1;

    const onChangeQuantity = (
        quantity: number,
        cartId: string | number,
        productPricesDetailsId: string | number | null,
        customerId: string | number
    ) => {
        if (quantity >= 0) {
            dispatch(
                updateCustomerQuantity({
                    customerId,
                    cartId,
                    productPricesDetailsId,
                    quantity,
                })
            );
        } else {
            dispatch(
                openToast({
                    open: true,
                    type: "error",
                    message: "Invalid number.",
                    autoHideDuration: DURATION_TIME,
                })
            );
        }
    };

    const handleClick = () => {
        history.push(`${PATH_DETAIL_TOUR}/${product.tour.productId}`);
    };

    const handleDelete = () => {
        if (handleDeletePopUp) handleDeletePopUp(product.id);
    };

    return (
        <StyledProduct>
            <CartItemComponent
                onClickDeleteBtn={handleDeletePopUp && handleDelete}
                onClick={handleClick}
                cart={product}
            >
                <CartItemInfoComponent totalQty={product.quantity} />
            </CartItemComponent>
            {!customers &&
                cartRedux.customers?.map((customer, index) => {
                    const fullName = (customer.firstName + " " + customer.lastName).trim();
                    if (fullName !== "") {
                        const customerCart = customer.carts.find(
                            (cartItem) => cartItem.id === product.id
                        );
                        const quantity = customerCart?.quantity || 0;
                        return (
                            <SelectTag key={index}>
                                <span>{fullName}</span>
                                <div>
                                    <PointCommon
                                        onClick={() =>
                                            onChangeQuantity(
                                                quantity - 1,
                                                product.id,
                                                product.productPricesDetailsId,
                                                customer.id
                                            )
                                        }
                                        disabled={quantity === 0}
                                    >
                                        -
                                    </PointCommon>
                                    <StyledSelectQuantity>
                                        {customerCart?.quantity || 0}
                                    </StyledSelectQuantity>
                                    <PointCommon
                                        onClick={() =>
                                            onChangeQuantity(
                                                quantity + 1,
                                                product.id,
                                                product.productPricesDetailsId,
                                                customer.id
                                            )
                                        }
                                        disabled={productQuantity >= product.quantity}
                                    >
                                        +
                                    </PointCommon>
                                </div>
                            </SelectTag>
                        );
                    }
                })}
            {customers?.map((customer, index) => {
                const fullName = (customer.firstName + " " + customer.lastName).trim();
                if (fullName !== "") {
                    const customerCart = customer.carts.find(
                        (cartItem) => cartItem.id === product.id
                    );
                    return (
                        <SelectTag key={index}>
                            <span>{fullName}</span>
                            <div>
                                <StyledSelectQuantity>
                                    {customerCart?.quantity || 0}
                                </StyledSelectQuantity>
                            </div>
                        </SelectTag>
                    );
                }
            })}
        </StyledProduct>
    );
});

const StyledProduct = styled.div`
    padding: 16px 0;
`;

const SelectTag = styled.div`
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 17px 29px 17px 25px;
    color: ${(p) => p.theme.colors.gray_2};
    outline: none;
    border: none;
    position: relative;
    margin: 12px 0;
`;

const StyledSelectQuantity = styled.span`
    display: inline-block;
    min-width: 2em;
    text-align: center;
`;
