import styled from "styled-components";

import { IHandleSubmit, IResetPassword } from "@interfaces";
import { InputCommon, StyledButton, StyledForm } from "@components";
import { IHandleChange, IHandleBlur } from "@interfaces";
interface ResetPassFormProps {
    values: IResetPassword;
    handleChange: IHandleChange;
    handleBlur: IHandleBlur;
    handleSubmit: IHandleSubmit;
    isSubmitting: boolean;
}

export const ResetPassComponent = (props: ResetPassFormProps) => {
    const { values, handleChange, handleBlur, handleSubmit } = props;
    return (
        <>
            <StyledWrapper onSubmit={handleSubmit}>
                <h2 className="heading">Get New Password</h2>
                <InputCommon
                    name="password"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.password}
                    label="New password"
                    type="password"
                />
                <InputCommon
                    name="confirmPassword"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.confirmPassword}
                    label="Confirm password"
                    type="password"
                />
                <StyledButton type="submit">Reset Password</StyledButton>
            </StyledWrapper>
        </>
    );
};

const StyledWrapper = styled(StyledForm)`
    .heading {
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 18px;
    }

    .subHeading {
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: ${props => props.theme.colors.gray_2};
    }
    .send_link {
        margin-top:56px;
        margin-bottom: -12px;
    }
`;
