import axios from "axios";

export const externalApi = {
    getDataHomePage: () => {
        const url = "/data/externalData.json";
        return axios.get(url);
    },
    getCountriesDate: () => {
        const url = "/data/countries.json";
        return axios.get(url);
    }
};