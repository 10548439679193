import styled from "styled-components";

import { breakpoint } from "@utilities";

export const StyledBookingDetail = styled.div<{isShareBookingDetail?: boolean, info? :boolean, isLogin?: boolean}>`
    ${(p)=> p.info && "min-height: calc(100vh - 95px);"};
    ${(p)=> p.isLogin && "min-height: calc(100vh - 95px);"};
    background-color: ${p => p.isShareBookingDetail ? "#ffffff" : p.theme.colors.gray_6};
    padding: ${p => p.isShareBookingDetail ? "0" : "24px 16px"};

    .clipLoader {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
        background-color: rgba(0, 0, 0, 0.2);

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.3);
        }

        .loader {
            z-index: 1;
        }
    }

    .wrapper {
        display: flex;
        margin: 0 auto;
        position: relative;
        width: 100%;

        ${breakpoint.breakTablet`
            width: ${(p) => p.theme.widths.wide_700};
            margin-top: 57px;
        `}

        @media (min-width: ${(props) => props.theme.breakPoints.breakIpadPro}) {
            width: ${(p) => p.isShareBookingDetail ? p.theme.widths.wide_700 : p.theme.widths.wide_900};
        }
        @media (min-width: ${(props) => props.theme.breakPoints.breakLarge}) {
            width: ${(p) => p.isShareBookingDetail ? p.theme.widths.wide_900 : p.theme.widths.wide_1110};
        }
        .breadCrumbs {
            position: absolute;
            left: 0;
            bottom: calc(100% + 20px);
            display: none;

            ${breakpoint.breakTablet`
                display: block;
            `}
        }
    }

    .detailWrapper {
        width: 100%;

        .heading1 {
            font-size: 28px;
            font-weight: 700;
            line-height: 36px;
            margin-bottom: 33px;
            display: block;
            text-align: left;
            ${breakpoint.breakTablet`
                display: block;
                margin-bottom: 20px;
            `}
        }
    }

    .detailWrapper {
        ${breakpoint.breakLarge`
            width: 100%;
        `}

        .navigateBtn {
            display: none;

            ${breakpoint.breakTablet`
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                span {
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 26px;
                    color: ${(p) => p.theme.colors.gray_3};
                    margin-left: 7px;
                }
            `}

            ${breakpoint.breakLarge`
                display: flex;
                align-items: center;
                margin-bottom: 26px;

                span {
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 26px;
                    color: ${(p) => p.theme.colors.gray_3};
                    margin-left: 12px;
                }
            `}

            img {
                ${breakpoint.breakTablet`
                    width: 14px;
                `}
            }
        }
    }

    .menu {
        display: none;

        ${breakpoint.breakTablet`
            display: block;
        `}
    }

    .listFare {
        text-align:left;
        .heading {
            font-size: 18px;
            font-weight: 700;
            line-height: 23px;
            color: ${(props) => props.theme.colors.black};
            margin: 26px 0 26px 0;
            text-align: left;
            ${breakpoint.breakLarge`
                display: none;
            `}
        }
    }

    .userInfoNPrice {
        ${breakpoint.breakTablet`
            margin-bottom: 33px;
            display: flex;
            justify-content: space-between;
        `}
    }
`;
