import { StyledTag } from "@components";
import { ITag } from "./index";

const FooterTag = (props: ITag) => {
    const { Icon, text, link, active } = props;

    //@ts-ignore
    const IconTag = () => <Icon />;
    return (
        <StyledTag to={link} exact={true} className={active ? "active" : ""}>
            <IconTag />
            <span>{text}</span>
        </StyledTag>
    );
};

export default FooterTag;
