import styled from "styled-components";
import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { PointCommon, TotalPriceComponent } from "@components";
import { PATH_SAVED_CART } from "@routes";
import { breakpoint, useWindowDimensions } from "@utilities";
import { CustomerFormModule, CheckoutProductModule } from "@module";
import { CartConstants } from "@constants";
import {
    addCustomer,
    selectCart,
    useAppSelector,
    checkPayment,
    resetCart,
    selectSavedCarts,
    addCart,
    updateSavedCart,
} from "@redux";

interface IProps {
    moveTo: (step: number) => void;
    handleDeletePopUp: (cartDeleteId?: string | number) => void;
}

export const CheckoutModule = (props: IProps) => {
    //props
    const { moveTo, handleDeletePopUp } = props;
    //hooks
    const history = useHistory();
    const dispatch = useDispatch();
    //redux state
    const cartRedux = useAppSelector(selectCart);
    const saveCart = useAppSelector(selectSavedCarts);
    const { carts, customers } = cartRedux;
    //page variable
    const customerDetailRef = useRef(null);
    const { width } = useWindowDimensions();

    useEffect(() => {
        dispatch(checkPayment());
    }, [cartRedux.carts.length]);

    const handleSwitchToPayment = () => {
        moveTo(CartConstants.PAYMENT);
    };

    const handleShowCustomerDetail = (customerId: string) => {
        const customerForm = document.querySelector(`#customer_${customerId}`);
        if (customerForm) {
            //@ts-ignore
            customerForm.style.top = "0";
            //@ts-ignore
            customerForm.style.opacity = "1";
        }
    };

    const handleCloseCustomerDetail = (customerId: string) => {
        const customerForm = document.querySelector(`#customer_${customerId}`);
        if (customerForm) {
            //@ts-ignore
            customerForm.style.top = "100%";
            //@ts-ignore
            customerForm.style.opacity = "0";
        }
    };

    const handleClickAddCustomer = () => {
        dispatch(addCustomer());
    };

    const handleSaveCart = () => {
        const idCard = saveCart.currentSavedCartId;
        if (idCard) {
            dispatch(updateSavedCart(cartRedux, idCard));
        } else {
            dispatch(addCart(cartRedux));
        }

        dispatch(resetCart());
        history.push(PATH_SAVED_CART);
    };

    return (
        <Container>
            <div className="wrapper">
                <Info>
                    <User>
                        {customers?.map((customer) => {
                            if (width >= 767) {
                                return (
                                    <div className="listCustomerDetail" key={customer.id}>
                                        <CustomerFormModule customer={customer} />
                                    </div>
                                );
                            } else {
                                const fullName = (
                                    customer?.firstName +
                                    " " +
                                    customer?.lastName
                                ).trim();
                                if (fullName === "") {
                                    return (
                                        <div className="editUser" key={customer.id}>
                                            <SelectTag
                                                onClick={() =>
                                                    handleShowCustomerDetail(customer.id)
                                                }
                                            >
                                                <span>
                                                    {customer.isLeader
                                                        ? "Click to enter lead customer details"
                                                        : "Click to enter customer details"}
                                                </span>
                                                <img src="/images/Vector.svg" alt="Vector" />
                                            </SelectTag>
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div
                                            key={customer.id}
                                            className="userItem"
                                            onClick={() => handleShowCustomerDetail(customer.id)}
                                        >
                                            <span className="name">
                                                <h2 className="title">
                                                    {customer.isLeader
                                                        ? "Lead Customer Details"
                                                        : "Additional Customer Details"}
                                                </h2>
                                                {fullName}
                                            </span>
                                            <PointCommon
                                                onClick={() => {
                                                    //do something
                                                }}
                                            >
                                                <img
                                                    src="/images/fi_check_white.svg"
                                                    alt="fi_check"
                                                />
                                            </PointCommon>
                                        </div>
                                    );
                                }
                            }
                        })}
                        <div className="btnAddCustomer" onClick={handleClickAddCustomer}>
                            <img src="/images/fi_plus-circle.png" alt="fi_plus-circle" />
                            <span>Add customer</span>
                        </div>
                    </User>
                    <ListProduct>
                        {carts?.map((product) => {
                            return (
                                <CheckoutProductModule
                                    product={product}
                                    key={product.id}
                                    handleDeletePopUp={handleDeletePopUp}
                                />
                            );
                        })}
                    </ListProduct>
                </Info>
                <div className="totalPrice">
                    <TotalPriceComponent
                        btnTitle="Payment"
                        onBtnClick={handleSwitchToPayment}
                        page="checkout"
                        handleSaveCart={handleSaveCart}
                    />
                </div>

                {width < 767 &&
                    customers?.map((customer) => {
                        return (
                            <div
                                key={customer.id}
                                className="customerDetail"
                                id={`customer_${customer.id}`}
                                ref={customerDetailRef}
                            >
                                <CustomerFormModule
                                    onCloseCustomerDetail={() =>
                                        handleCloseCustomerDetail(customer.id)
                                    }
                                    customer={customer}
                                />
                            </div>
                        );
                    })}
            </div>
        </Container>
    );
};

const Container = styled.div`
    background-color: #f2f2f2;
    width: 100%;

    .bottom {
        background-color: #fff;
        padding: 32px 15px 41px 15px;
        ${breakpoint.breakTablet`
            margin-top: -60px;
            margin-bottom: 60px;
        `}
        .title {
            font-size: 18px;
            font-weight: 700;
            line-height: 23px;
        }
    }

    .customerDetail {
        position: fixed;
        top: 100%;
        left: 0;
        transition: 300ms;
        width: 100vw;
        padding-top: 45px;
        background-color: #194e59;
        z-index: 1;
        opacity: 0;
        height: 100vh;
        overflow-y: scroll;
        z-index: 10;
    }
    .qty span {
        margin: 0 4px;
    }
    .totalPrice {
        display: flex;
        justify-content: flex-end;
    }

    ${breakpoint.breakTablet`
        background-color: #F2F2F2;

        & > .wrapper {
            display: flex;
            width: ${(p) => p.theme.widths.wide_700};
            margin: 0 auto;
        }

        .customerDetail {
            display: none;
        }

        .totalPrice {
            flex: 350;
        }

        .bottom {
            background-color: transparent;
            padding: 15px 0 0 0;

            .title {
                font-size: 15px;
            }
        }
    `}

    ${breakpoint.breakIpadPro`
        & > .wrapper {
            width: ${(p) => p.theme.widths.wide_900};
        }
    `}
    
    ${breakpoint.breakLarge`
        & > .wrapper {
            width: ${(p) => p.theme.widths.wide_1110};
        }

        .bottom {
            padding: 32px 0 0 0;

            .title {
                font-size: 18px;
            }
        }
    `}
`;

const Info = styled.div`
    padding: 16px;

    ${breakpoint.breakTablet`
        padding: 0 16px 44px 0;
        flex: 698;
    `}

    ${breakpoint.breakIpadPro`
        padding: 0 33px 44px 0;
    `}
    
    ${breakpoint.breakLarge`
        padding: 0 62px 84px 0;
    `}
`;

const User = styled.div`
    .userItem {
        background-color: #fff;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        color: ${(props) => props.theme.colors.black};
        padding: 17px 15px 17px 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        align-items: center;
        .title {
            margin-bottom: 4px;
        }
    }

    .listCustomerDetail {
        display: none;
    }

    .editUser {
        position: relative;
        display: flex;
        margin-bottom: 16px;

        .deleteBtn {
            position: absolute;
            height: 52px;
            background-color: #f43f3f;
            padding: 0 15px;
            top: 50%;
            transform: translateY(-50%);
            right: 1px;
        }
    }

    .btnAddCustomer {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
        line-height: 21px;
        color: ${(props) => props.theme.colors.main};
        /* margin: 0 0 18px 0; */
        margin-bottom: 18px;
        cursor: pointer;

        span {
            margin-left: 14px;
        }
    }

    ${breakpoint.breakTablet`
        .userItem,
        .editUser {
            display: none;
        }

        .listCustomerDetail {
            display: block;
        }
    `}
`;

const SelectTag = styled.div`
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 17px 29px 17px 25px;
    color: ${(p) => p.theme.colors.gray_2};
    outline: none;
    border: none;
    cursor: pointer;
    position: relative;

    .overlay {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 1;
    }

    .listCustomers {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        z-index: 1;
        background-color: #fff;
        box-shadow: 8px 22px 35px rgba(0, 0, 0, 0.16);
        max-height: 154px;
        overflow-y: overlay;

        &::-webkit-scrollbar {
            -webkit-appearance: none;
            appearance: none;
            width: 7px;
        }

        &::-webkit-scrollbar-track {
            appearance: none;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            -webkit-appearance: none;
            appearance: none;
            background-color: rgba(0, 0, 0, 0.3);
            transition: 0.3s;
            border-radius: 5px;
        }

        .customerItem {
            padding: 17px 29px 17px 25px;
            border-bottom: 1px solid ${(p) => p.theme.colors.gray_4};
            z-index: 3;

            &:first-child {
                border-top: 1px solid ${(p) => p.theme.colors.gray_4};
            }
        }
    }
`;

const ListProduct = styled.div`
    .infoWrapper {
        padding-bottom: 7px;
    }
`;
