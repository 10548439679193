import { createSlice } from "@reduxjs/toolkit";

import { IFareDetail } from "@interfaces";
import { CHANGE_DATE_FROM, CHANGE_DATE_TO } from "@pages";
import { RootState } from ".";

interface IInitialState {
    listFares: IFareDetail[]
}

const initialState: IInitialState = {
    listFares: []
};

const detailFare = createSlice({
    name: "detailFare",
    initialState,
    reducers: {
        addDateRange: (state, action) => {
            const { date, productPricesDetailsId, fareName, type } = action.payload;
            const dateRangeIndex = state.listFares.findIndex(item => item.productPricesDetailsId?.toString() === productPricesDetailsId.toString() && item.fareName === fareName);
            if (dateRangeIndex !== -1) {
                if (type === CHANGE_DATE_TO) {
                    state.listFares[dateRangeIndex].dateRange.to = date;
                } else if (type === CHANGE_DATE_FROM) {
                    state.listFares[dateRangeIndex].dateRange.from = date;
                }
            } else {
                const newDateRange: IFareDetail = {
                    productPricesDetailsId,
                    fareName,
                    dateRange: {
                        from: type === CHANGE_DATE_FROM ? date : null,
                        to: type === CHANGE_DATE_TO ? date : null,
                    },
                    autoCheckPackageDates: false,
                    bookingTimes: [],
                    imagePath: null,
                    pickupLocations: [],
                    supplier: null
                };
                state.listFares.push(newDateRange);
            }
        },
    }
});

export const selectDetailFare = (state: RootState) => state.detailFare;

export const {
    addDateRange
} = detailFare.actions;

export default detailFare.reducer;