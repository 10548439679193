import * as Yup from "yup";

// const phoneRegExp =
//     /\(?\+[0-9]{1,3}\)? ?-?[0-9]{1,3} ?-?[0-9]{3,5} ?-?[0-9]{4}( ?-?[0-9]{3})? ?(\w{1,10}\s?\d{1,6})?/;

export const customerDetailSchema = Yup.object().shape({
    firstName: Yup.string()
        .trim()
        .required("This field is required!")
        .max(50, "This field is cannot be longer than 50 characters!"),
    lastName: Yup.string()
        .trim()
        .required("This field is required!")
        .max(50, "This field is cannot be longer than 50 characters!"),
    phone: Yup.string().trim().required("This field is required!"),
    email: Yup.string()
        .trim()
        .required("This field is required!")
        .email("Email is not valid")
        .max(255, "This field is cannot be longer than 255 characters!"),
    // dateOfBirth: Yup.string()
    //     .trim()
    //     .required("This field is required!"),
    postCode: Yup.string()
        .trim()
        .required("This field is required!")
        .max(255, "Enter not allow too 255 character!"),
    country: Yup.object().nullable().required("This field is required!"),
});
