import { useHistory } from "react-router-dom";
import { memo } from "react";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";
const queryString = require("query-string");

import { IDateRange, ITourFare, IFareAvail } from "@interfaces";
import { AddToCart, DatePickerCommon, StyledBookingTimeError } from "@components";
import { PATH_CHECK_AVAILABILITY } from "@routes";
import { ClipLoaderCommon } from "./Common";

interface IProps {
    tourFare: ITourFare;
    date: IDateRange;
    changeDateFrom: (date: Date) => void;
    changeDateTo: (date: Date) => void;
    bookingTime: string | undefined;
    handleChangeTime: (event: React.ChangeEvent<HTMLInputElement>) => void;
    tourId: string | number;
}

export const TourFareComponent = memo((props: IProps) => {
    const { tourFare, date, changeDateFrom, changeDateTo, handleChangeTime, bookingTime, tourId } =
        props;

    // page hook
    const history = useHistory();
    //page variable
    const now = new Date();
    const travelStart = new Date(tourFare.travelStart);
    const limitDate = {
        from: {
            minDate: travelStart > now ? travelStart : now, // không được chọn ngày nhỏ hơn ngoài khoảng 14 ngày
            maxDate: date.to || (tourFare.travelEnd ? new Date(tourFare.travelEnd) : null), // không được chọn ngày lớn hơn toDate
        },
        to: {
            minDate: date.from || (travelStart > now ? travelStart : now),
            maxDate: tourFare.travelEnd ? new Date(tourFare.travelEnd) : null,
        },
    };

    const handleClickCheckAvail = () => {
        const params: IFareAvail = {
            fareName: tourFare.fareName,
            productPricesDetailsId: tourFare.productPricesDetailsId,
            ...date,
            bookingTime,
            minDate: travelStart,
            maxDate: tourFare.travelEnd ? new Date(tourFare.travelEnd) : null,
        };
        // console.log(`${PATH_CHECK_AVAILABILITY}/${props.tourId}?${queryString.stringify(params)}`);
        history.push(`${PATH_CHECK_AVAILABILITY}/${tourId}?${queryString.stringify(params)}`);
    };

    return (
        <div className="content--children">
            <div className="fareType">
                <div className="title">Fare Type</div>
                <div className="text">{tourFare.fareName}</div>
                {tourFare.travelStart && (
                    <div className="travel-time">
                        Travel between {tourFare.travelStart} and {tourFare.travelEnd}
                    </div>
                )}
            </div>
            <div className="date">
                {!tourFare.bookingTimeError && (
                    <div className="choseDate">
                        <div className="from">
                            <DatePickerCommon
                                label="From"
                                value={date.from}
                                onChange={changeDateFrom}
                                limitDate={limitDate.from}
                            />
                        </div>
                        <div className="to">
                            <DatePickerCommon
                                label="To"
                                value={date.to}
                                onChange={changeDateTo}
                                limitDate={limitDate.to}
                                minDateMessage="End date should not be before start date"
                            />
                        </div>
                    </div>
                )}

                <div className="rrp">
                    From <span>${tourFare.rrp.toFixed(2)}</span>
                    {!tourFare.bookingTimeError && !!tourFare.levy && (
                        <div className="note">
                            + ${tourFare.levy.toFixed(2)} to pay on arrival{" "}
                            {tourFare.levyDescription}
                        </div>
                    )}
                </div>
            </div>
            {!tourFare.bookingTimeError ? (
                <div className="listBtn">
                    {tourFare?.bookingTimes ? (
                        tourFare.bookingTimes.length > 0 && (
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Booking Time (Select One)</FormLabel>
                                <RadioGroup row onChange={handleChangeTime} name="bookingTime">
                                    {tourFare.bookingTimes.map((time, index) => (
                                        <FormControlLabel
                                            value={time.BookingTimeID.toString()}
                                            control={<Radio />}
                                            checked={
                                                bookingTime?.toString() ===
                                                time.BookingTimeID.toString()
                                            }
                                            label={time.BookingTime}
                                            key={index}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        )
                    ) : (
                        <ClipLoaderCommon color="#31B4B9" size={35} margin={5} />
                    )}

                    <AddToCart
                        onClick={handleClickCheckAvail}
                        disabled={!date.from || !date.to || !bookingTime}
                    >
                        Check Availability
                    </AddToCart>
                </div>
            ) : (
                <div className="listBtn">
                    <StyledBookingTimeError>
                        We are unable to check live availability for this fare right now. Please try
                        again later.
                    </StyledBookingTimeError>
                </div>
            )}
        </div>
    );
});
