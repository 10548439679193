import styled from "styled-components";
import React, { memo } from "react";

import { breakpoint } from "@utilities";
import { ISortMenuComponent, SortBy } from "@interfaces";
import {
    SORT_NAME_ASC,
    SORT_NAME_DESC,
    SORT_PRICE_ASC,
    SORT_PRICE_DESC,
    SORT_ITEMS,
} from "@constants";

export const SortMenuComponent = memo((props: ISortMenuComponent) => {
    const { onSort, isShowSortMenu, titleSort } = props;
    return (
        <StyledSortMenu isShowSortMenu={isShowSortMenu}>
            {SORT_ITEMS.map((sorts: SortBy, index: number) => (
                <React.Fragment key={`${sorts.key} + ${index} + ${sorts.value[index].value}`}>
                    <p key={`${sorts.key}`}></p>
                    {sorts.value.map((sortItem, index) => {
                        return (
                            <p
                                className={`menuItem ${titleSort === sortItem.title && "active"}`}
                                onClick={() => {
                                    onSort(sorts, index);
                                }}
                                key={index}
                            >
                                {sortItem.title === SORT_PRICE_ASC && (
                                    <>
                                        Price: <span>↑</span>
                                    </>
                                )}
                                {sortItem.title === SORT_PRICE_DESC && (
                                    <>
                                        Price: <span>↓</span>
                                    </>
                                )}
                                {sortItem.title === SORT_NAME_ASC && (
                                    <>
                                        Name: <span>A - Z</span>
                                    </>
                                )}
                                {sortItem.title === SORT_NAME_DESC && (
                                    <>
                                        Name: <span>Z - A</span>
                                    </>
                                )}
                            </p>
                        );
                    })}
                </React.Fragment>
            ))}
        </StyledSortMenu>
    );
});

const StyledSortMenu = styled.div<{ isShowSortMenu: boolean }>`
    position: absolute;
    display: ${(p) => (p.isShowSortMenu ? "block" : "none")};
    top: 100%;
    right: -6px;
    z-index: 2;
    overflow: hidden;
    box-shadow: 8px 22px 35px rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    width: 162px;
    z-index: 2;

    .menuItem {
        width: 100%;
        padding: 14px;
        cursor: pointer;
        font-size: 16px;
        text-align: center;
        color: #000000;
        font-weight: 500;
        border-bottom: 1px solid ${(p) => p.theme.colors.gray_5};
        display: flex;
        flex-direction: row;
        align-items: center;
        span {
            color: ${(p) => p.theme.colors.main};
            display: inline-block;
            margin-left: 4px;
        }

        ${breakpoint.breakTablet`
            font-size: 14px;
        `}

        ${breakpoint.breakIpadPro`
            font-size: 16px;
        `}

        ${breakpoint.breakLarge`
            font-size: 18px;
        `}

        &:last-child {
            border-bottom: none;
        }
    }
    .menuItem.active {
        background: #d3eff0;
    }
    .imageOrder {
        height: 16px;
        margin-right: 8px;
    }
    .imageOrder.Up {
        transform: rotate(180deg);
    }
`;
