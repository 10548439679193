import styled from "styled-components";

export const NotFound = () => {
    return (
        <Container>
            <div className="main">
                <img src="/images/notFound.svg" alt="" />
                <p className="textCenter heading">Page not found</p>
                <p className="textCenter subHeading">
                    We’re sorry, the page you requested could not be found. Please go back to the homepage
                </p>
            </div>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    
    .main {
        text-align: center;

        .textCenter {
            text-align: center;
        }

        .heading {
            font-size: 28px;
            font-weight: 700;
            line-height: 36px;
            color: ${p => p.theme.colors.gray_2};
            margin-top: 40px;
            margin-bottom: 6px;
        }

        .subHeading {
            font-size: 15px;
            font-weight: 400;
            line-height: 24px;
            color: ${p => p.theme.colors.gray_3};
        }
    }
`;