import { memo } from "react";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { IDetailTour, IImage } from "@interfaces";
import {
    StyledButton,
    StyledSlideBannerMobile,
    StyledTourInfo,
    StyledRatio34Image,
} from "@components";
import { HeartTourModule, InfoBoxModule } from "@module";

interface IDetailTourMobileComponent {
    tour: IDetailTour;
    onCheckFare: () => void;
    handleClickSupplier?    :()=>void;
}

export const DetailTourMobileComponent = memo((props: IDetailTourMobileComponent) => {
    const { tour, onCheckFare, handleClickSupplier } = props;
    return (
        <>
            <StyledSlideBannerMobile>
                <Carousel infiniteLoop showThumbs={false}>
                    {tour.images.map((item: IImage) => {
                        return <StyledRatio34Image key={item.mediaId} img={item.path} />;
                    })}
                </Carousel>
            </StyledSlideBannerMobile>
            <Main>
                <div className="heart">
                    <HeartTourModule tour={tour}/>
                </div>
                <StyledTourInfo>
                    <h1 className="title">{tour.name}</h1>
                    <p className="place">
                        <img src="/images/fi_map-pin.png" alt="mapIcon" className="mapIcon" />
                        <span>{tour.location}</span>
                    </p>
                    <div className="durationNPrice">
                        <div className="duration">
                            <img src="/images/fi_clock.png" alt="fi_clock" />
                            <span>{tour.duration}</span>
                        </div>
                        <div className="price">
                            From <span>${Number(tour.rrp).toFixed(2)}</span>
                        </div>
                    </div>
                </StyledTourInfo>
                <StyledTourInfo>
                    <p className="supplier" onClick={handleClickSupplier}>
                        Supplier: <span>{tour.supplier}</span>
                    </p>
                    <p className="tripID">Trip ID: {tour.productId}</p>
                    <p className="tourCode">Tour Code: {tour.tourCode}</p>
                </StyledTourInfo>
                <InfoBoxModule data={tour.description} />
                <div className="checkAvailBtn">
                    <StyledButton onClick={onCheckFare}>Check Availability </StyledButton>
                </div>
            </Main>
        </>
    );
});

const Main = styled.div`
    padding: 0 18px;
    position: relative;

    .heart {
        position: absolute;
        top: 0;
        right: 15px;
    }

    .checkAvailBtn {
        position: fixed;
        bottom: 0;
        left: 0;
        background-color: #fff;
        width: 100%;
        padding: 24px 16px;
        box-shadow: 0px -2px 19px rgba(0, 0, 0, 0.06);

        button {
            margin-top: 0;
        }
    }
`;
