import styled from "styled-components";
import { Route, Switch } from "react-router";

import {
    authPath,
    auth_route,
    PATH_SHARE_BOOKING_DETAIL,
    PATH_SHARE_ORDER_SUCCESS,
    PATH_TERM,
} from "@routes";
import { IRoute } from "@interfaces";
import { breakpoint, storageVersion } from "@utilities";
import { StyledVersion } from "@components";

interface ILoginFormContainerProps {
    isTermLayout: boolean;
    isNotFound: boolean;
    isShareSuccess: boolean;
    isShareBookingDetail: boolean;
}
const appVersion = storageVersion.getItem();

export const AuthLayout = () => {
    // useEffect(() => {
    //     window.location.assign(PATH_LOGIN);
    // }, []);
    const pathName = location.pathname;
    const isNotFound = authPath.some((item: string) => pathName === item);
    const isShareBookingDetail = pathName.includes(PATH_SHARE_BOOKING_DETAIL);

    return (
        <Container>
            <Content
                isShareBookingDetail={isShareBookingDetail}
                isShareSuccess={pathName === PATH_SHARE_ORDER_SUCCESS}
            >
                <BgImage />
                <LoginFormContainer
                    isTermLayout={pathName === PATH_TERM}
                    isNotFound={isNotFound}
                    isShareSuccess={pathName === PATH_SHARE_ORDER_SUCCESS}
                    isShareBookingDetail={isShareBookingDetail}
                >
                    <Switch>
                        {auth_route.map((e: IRoute, key) => (
                            <Route key={key} {...e} />
                        ))}
                    </Switch>
                </LoginFormContainer>
                <div className="version">
                    <StyledVersion>{appVersion}</StyledVersion>
                </div>
            </Content>
        </Container>
    );
};

const LoginFormContainer = styled.div<ILoginFormContainerProps>`
    background-color: ${(p) => (p.isShareSuccess ? "transparent" : "#ffffff")};
    margin: ${(p) => (!p.isNotFound ? "0px" : "15px")};
    padding: 64px 15px;
    max-height: 90vh;
    overflow-x: ${(p) =>
        p.isShareBookingDetail ? "hidden" : p.isShareSuccess ? "none" : "inherit"};
    /* width: 290px; */
    ${breakpoint.breakOnlyMobile`
        min-width: 345px; 
        max-height: inherit;

    `}

    ${breakpoint.breakMobileMedium`
        min-width: 430px;    
    `}

    @media (min-width: ${(props) => props.theme.breakPoints.breakTablet}) {
        padding: ${(p) => (p.isShareBookingDetail ? "0 45px" : "64px 45px")};
        width: ${(p) => (p.isTermLayout ? "608px" : "unset")};
    }

    @media (min-width: ${(props) => props.theme.breakPoints.breakLarge}) {
        /* width: 540px; */
        /* padding: 63px 95px 73px 95px; */
        padding: ${(p) => (p.isShareBookingDetail ? "0 57px" : "63px 95px 73px 95px")};
        /* min-width: 540px; */
        width: ${(p) => (p.isTermLayout ? "1050px" : "unset")};
    }
    @media (max-width: 321px) {
        min-width: 290px;
    }
    /* css scroll */
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        appearance: none;
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        appearance: none;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        -webkit-appearance: none;
        appearance: none;
        background-color: rgba(0, 0, 0, 0.3);
        transition: 0.3s;
        border-radius: 5px;
    }
`;

const Container = styled.section`
    height: 100vh;
    display: flex;
    flex-direction: column;
    text-align: center;
`;

const Content = styled.div<{ isShareBookingDetail: boolean; isShareSuccess?: boolean }>`
    /* width: 100%; */
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    height: 100%;
    overflow: ${(p) => (p.isShareSuccess ? "hidden" : "inherit")};
    @media (max-width: ${(props) => props.theme.breakPoints.breakTablet}) {
        align-items: ${(p) => (p.isShareBookingDetail ? "inherit" : "center")};
    }
    .version {
        position: fixed;
        bottom: 0px;
    }
`;

const BgImage = styled.div`
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("/images/login-background.png");
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
`;
