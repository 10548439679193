import styled from "styled-components";

import { breakpoint } from "@utilities";

export const StyledFilterDesktop = styled.div<{
    isSearchResultPage: boolean;
    width: number;
}>`
    background-color: #fff;
    margin: ${(p) => (p.width > 767 && p.isSearchResultPage ? "0 30px 30px 0" : "unset")};
    padding-bottom: ${(p) => (p.isSearchResultPage ? "12px" : "0")};

    @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
        padding: 49px 16px;
        padding-bottom: 90px;
    }

    @media (min-width: ${(p) => p.theme.breakPoints.breakIpadPro}) {
        padding-bottom: ${(p) => (p.isSearchResultPage ? "22px" : "0")};
    }

    .searchResultPage {
        display: ${(p) => (p.isSearchResultPage && p.width > 767 ? "block" : "none")} !important;

        .heading {
            display: flex;
            justify-content: space-between;
            padding: 12px 12px 0;
            align-items: flex-end;

            ${breakpoint.breakTablet`
                font-size: 19px;
                line-height: 22px;
            `}

            ${breakpoint.breakIpadPro`
                padding: 22px 22px 0;
            `}

            .resetBtn {
                font-size: 14px;
                font-weight: 700;
                line-height: 22px;
                color: ${(p) => p.theme.colors.main};
            }
        }
    }

    .mobile {
        ${breakpoint.breakTablet`
            display: none !important;
        `}
    }

    .heading {
        font-size: 28px;
        font-weight: 700;
        line-height: 36px;
        color: #000;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;

        .closeBtn {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: ${(p) => p.theme.colors.gray_6};
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .filterRow {
        display: block;
        flex-wrap: wrap;

        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            display: ${(p) => (p.isSearchResultPage && p.width > 767 ? "block" : "flex")};
            padding: ${(p) => (p.isSearchResultPage && p.width > 767 ? "0 12px" : "0")};
        }

        @media (min-width: ${(p) => p.theme.breakPoints.breakIpadPro}) {
            padding: ${(p) => (p.isSearchResultPage && p.width > 767 ? "0 22px" : "0")};
        }

        .filterCol-338 {
            width: ${(p) =>
                p.width < 767 || p.isSearchResultPage ? "auto !important" : "30.25045%"};

            ${breakpoint.breakTablet`
                position: relative;
            `}

            .tagName {
                ${breakpoint.breakTablet`
                
                `}

                ${breakpoint.breakIpadPro`
                    max-width: 196px;
                `}

                ${breakpoint.breakLarge`
                    max-width: 260px;
                `}
            }

            .lengthOfTrip {
                display: flex;
                align-items: center;
                padding: 13px 0 13px 13px;
                border: 1px solid ${(p) => p.theme.colors.gray_5};

                @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                    border: none;
                    border-bottom: 1px solid ${(p) => p.theme.colors.gray_5};
                    padding: 22px 0;
                }

                input {
                    padding-left: 6px;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 26px;
                    max-width: 134px;

                    @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                        padding-left: 16px;
                        flex: 1;
                        font-size: 16px;
                    }

                    &::placeholder {
                        color: ${(p) => p.theme.colors.gray_2};
                    }
                }

                .icon {
                    img {
                        width: 14px;

                        @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                            width: auto;
                        }
                    }
                }

                &.searchResult {
                    padding: 16px 14px 16px 0;
                    margin-bottom: 16px;
                }

                ${breakpoint.breakTablet`
                    &.searchResult {
                        padding: 13px;
                        margin-bottom: 16px;
                        border: none;
                        background-color: #F2F2F2;
                    }
                `}

                ${breakpoint.breakIpadPro`
                    padding: 28px 14px 28px 31px;

                    &.searchResult {
                        padding: 16px 14px 16px 31px;
                        margin-bottom: 16px;
                    }

                    .icon {
                        img {
                            width: auto;
                        }
                    }

                    input {
                        padding-left: 16px;
                        flex: 1;
                        font-size: 16px;
                    }
                `}
            }
        }

        .filterCol-514 {
            width: ${(p) =>
                p.width < 767 || p.isSearchResultPage ? "auto !important" : "46.5063%"};
        }

        .filterCol-258 {
            width: ${(p) =>
                p.width < 767 || p.isSearchResultPage ? "auto !important" : "23.2432%"};
            ${breakpoint.breakTablet`
                position: relative;
            `}

            .tagName {
                ${breakpoint.breakTablet`
                    max-width: 100px;
                `}

                ${breakpoint.breakIpadPro`
                    max-width: 131px;
                `}

                ${breakpoint.breakLarge`
                    max-width: 180px;
                `}
            }

            &:nth-child(n + 2):nth-child(-n + 4){
                padding-left: ${(p) =>
                    p.width < 767 || p.isSearchResultPage ? "30px" : "none"};
            }
        }
    }

    .filterItem {
        &.searchResult {
            display: none !important;
        }

        .resetBtn {
            display: flex;
            justify-content: flex-end;
            padding: 25px 0;
            align-items: center;

            img {
                margin-right: 12px;
            }

            .text {
                font-size: 16px;
                font-weight: 700;
                line-height: 26px;
            }
        }

        &.applyFilterBtn {
            button {
                margin-top: 0;
            }

            &.searchResult {
                padding-top: 16px;
                display: block !important;
                /* padding-bottom: 34px; */
            }
        }
    }
`;
