import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";

import { ClipLoaderCommon, StyledBorderButton } from "@components";
import { PATH_SHARE_BOOKING_DETAIL, PATH_HOME } from "@routes";
import { SPACE_TOKEN } from "@constants";
import { breakpoint, lsBookingReference, ssTokenAuth, ssTokenAuthShare } from "@utilities";
import { authApi, bookingApi } from "@api";
import { ILogin } from "@interfaces";

export const ShareOrderSuccess = () => {
    //page hooks
    const history = useHistory();
    //page state
    const [orderId, setOrderId] = useState<string | number | null>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    // page variable
    const bookingReference = lsBookingReference.getItem();

    // khi vào lấy username và password để get token
    useEffect(() => {
        try {
            setIsLoading(true);
            const tokenInfoAuth = ssTokenAuth.getItem();
            const email = tokenInfoAuth.split(SPACE_TOKEN)[0];
            const password = tokenInfoAuth.split(SPACE_TOKEN)[1];
            const values = {
                username: email,
                password: password,
            };
            handleConvertToOrder(values);
        } catch (err) {
            setIsLoading(false);
            history.push(PATH_HOME);
        }
        return () => {
            lsBookingReference.removeItem();
        };
    }, []);

    // handle convert to order
    const handleConvertToOrder = async (values: ILogin) => {
        try {
            setIsLoading(true);
            const resToken = await authApi.login(values);
            ssTokenAuthShare.setItem(resToken.data.access_token);
            const resConvertToOrder = await bookingApi.convertQuoteToOrderWithToken(
                bookingReference,
                resToken.data.access_token
            );
            setIsLoading(false);
            setOrderId(resConvertToOrder.data.id);
        } catch (err) {
            setIsLoading(false);
            history.push(PATH_HOME);
        }
    };

    const handleOrderDetail = () => {
        history.push(`${PATH_SHARE_BOOKING_DETAIL}/${orderId}`);
    };

    return (
        <ShareOrder>
            {isLoading ? (
                <div className="clipLoader">
                    <div className="overlay"></div>
                    <ClipLoaderCommon color="#31b4b9" margin={20} size={80} />
                </div>
            ) : (
                <Container>
                    <Main>
                        <div className="orderSuccessBackground">
                            <div className="orderSuccess">
                                <img src="/images/order_success.svg" alt="order_success" />
                            </div>
                        </div>
                        <p className="heading">Order Successful!</p>
                        <p className="subHeading">
                            Your order has been successfully completed. The confirmation and digital
                            travel vouchers have been emailed to your customer.
                        </p>
                    </Main>
                    <ListButton>
                        <StyledBorderButton className="noneMargin" onClick={handleOrderDetail}>
                            View Tickets
                        </StyledBorderButton>
                    </ListButton>
                </Container>
            )}
        </ShareOrder>
    );
};

const ShareOrder = styled.div`
    background-color: transparent;
    .clipLoader {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
        background-color: rgba(0, 0, 0, 0.2);

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.3);
        }

        .loader {
            z-index: 1;
        }
    }
`;

const Container = styled.div`
    height: 100vh;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
    background-color: transparent;
    padding-top: 16px;
    margin: 0 auto;

    ${breakpoint.breakTablet`
        max-width: 640px;
    `}

    ${breakpoint.breakLarge`
        max-width: 730px;
    `}
    ${breakpoint.breakOnlyMobile`
    background-color: transparent;
    `}
`;

const Main = styled.div`
    padding: 22px 21px 30px 21px;
    margin: 0 16px;
    background-color: #fff;

    ${breakpoint.breakTablet`
        margin: 0;
        padding: 43px 98px 0 98px;
    `}
    ${breakpoint.breakOnlyMobile`
      margin: 0;
    `}

    .orderSuccessBackground {
        background: linear-gradient(0deg, #ffffff -20.99%, rgba(255, 255, 255, 0.17) 119.75%),
            #f2f2f2;
        height: 78px;
        width: 78px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin: 0 auto;
    }

    .orderSuccess {
        height: 60px;
        width: 60px;
        background-color: ${(props) => props.theme.colors.main};
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }

    .heading {
        font-size: 20px;
        font-weight: 700;
        line-height: 32px;
        text-align: center;
        margin-top: 11px;
        margin-bottom: 19px;
    }

    .subHeading {
        margin-bottom: 30px;
    }

    .subHeading,
    .subHeading2 {
        cursor: pointer;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
    }

    .subHeading2 {
        a {
            color: #4990ef;
        }
    }
`;

const ListButton = styled.div`
    padding: 32px 16px 45px 16px;
    background-color: #fff;
    margin-top: 16px;

    ${breakpoint.breakTablet`
        margin-top: 0;
        margin-bottom: 60px;
        padding: 16px 191px 67px 191px;

        button {
            margin-top: 20px;
        }
    `}
    ${breakpoint.breakOnlyMobile`
    padding-top: 18px;
    margin-top: 0;
    .noneMargin {
      margin:0;
    }
    `};
`;
