import styled from "styled-components";

export const StyledNavigator = styled.div`
    position: sticky;
    height: 95px;
    background-color: ${props => props.theme.colors.main};
    padding: 44px 25px 25px 25px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    z-index: 3;
    top: 0;
    .backBtn {
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            font-weight: 700;
            font-size: 16px;
            margin-left: 5px;
        }
    }

    .menu {
        display: flex;

        .iconWrapper {
            margin-left: 30px;
        }
    }

    .cartIconWrapper {
            position: relative;
            width: 26px;
            height: 26px;

            .number {
                position: absolute;
                display: inline-block;
                width: 14px;
                height: 14px;
                font-size: 10px;
                line-height: 14px;
                border-radius: 50%;
                text-align: center;
                color: #fff;
                top: 0;
                left: 100%;
                transform: translateX(-50%);
                background-color: #F43F3F;
                z-index: 2;
            }

            svg {
                path {
                    stroke: #fff;
                }
            }
        }

    @media (min-width: ${props => props.theme.breakPoints.breakTablet}) {
        display: none;
    }
`;