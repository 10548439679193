import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IFilter, IOption, sortBy, sortDirection } from "@interfaces";
import { categoriesApi } from "@api";
import { RootState } from ".";

const initialState: IFilter = {
    name: null,
    countries: null,
    states: null,
    regions: null,
    localities: null,
    accommodation: null,
    activities: null,
    transports: null,
    suppliers: null,
    day: 0,
    sortBy: "rrp",
    sortDirection: "asc",
    duration: null,
    categories: {
        countries: [],
        states: [],
        regions: [],
        localities: [],
        accommodation: [],
        activities: [],
        transports: [],
        suppliers: [],
        duration: [],
    },
};

export const suppliers = createAsyncThunk<IOption[], any>(
    "searchCategories/suppliers",
    async (action: { filter?: null }, { rejectWithValue }) => {
        try {
            const res = await categoriesApi.getSupplier();
            return res.data.results as IOption[];
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);

const searchCategories = createSlice({
    name: "searchCategories",
    initialState: initialState,
    reducers: {
        resetSortDirection: (state) => {
            state.sortDirection = null;
        },
        setName: (state, action) => {
            state.name = action.payload;
        },
        setCountries: (state, action) => {
            state.countries = action.payload;
        },
        setStates: (state, action) => {
            state.states = action.payload;
        },
        setRegions: (state, action) => {
            state.regions = action.payload;
        },
        setTowns: (state, action) => {
            state.localities = action.payload;
        },
        setAccommodation: (state, action) => {
            state.accommodation = action.payload;
        },
        setActivity: (state, action) => {
            state.activities = action.payload;
        },
        setTransport: (state, action) => {
            state.transports = action.payload;
        },
        setSupplier: (state, action) => {
            state.suppliers = action.payload;
        },
        setDay: (state, action) => {
            state.day = action.payload;
        },
        setLengthOfTrip: (state, action) => {
            state.duration = action.payload;
        },
        setCountriesCategory: (state, action) => {
            state.categories.countries = action.payload;
        },
        setStatesCategory: (state, action) => {
            state.categories.states = action.payload;
        },
        setRegionsCategory: (state, action) => {
            state.categories.regions = action.payload;
        },
        setTownsCategory: (state, action) => {
            state.categories.localities = action.payload;
        },
        setAccommodationCategory: (state, action) => {
            state.categories.accommodation = action.payload;
        },
        setActivityCategory: (state, action) => {
            state.categories.activities = action.payload;
        },
        setTransportCategory: (state, action) => {
            state.categories.transports = action.payload;
        },
        setSupplierCategory: (state, action) => {
            state.categories.suppliers = action.payload;
        },
        setLengthOfTripCategory: (state, action) => {
            state.categories.duration = action.payload;
        },
        setSortBy: (state, action: PayloadAction<sortBy>) => {
            state.sortBy = action.payload;
        },
        setSortDirection: (state, action: PayloadAction<sortDirection>) => {
            state.sortDirection = action.payload;
        },
        removeExceptCountry: (state) => {
            state.states = null;
            state.categories.states = [];
            state.regions = null;
            state.categories.regions = [];
            state.localities = null;
            state.categories.localities = [];
            state.accommodation = null;
            state.categories.accommodation = [];
            state.activities = null;
            state.categories.activities = [];
            state.transports = null;
            state.categories.transports = [];
        },
        removeExceptState: (state) => {
            state.regions = null;
            state.categories.regions = [];
            state.localities = null;
            state.categories.localities = [];
            state.accommodation = null;
            state.categories.accommodation = [];
            state.activities = null;
            state.categories.activities = [];
            state.transports = null;
            state.categories.transports = [];
        },
        removeExceptRegions: (state) => {
            state.localities = null;
            state.categories.localities = [];
            state.accommodation = null;
            state.categories.accommodation = [];
            state.activities = null;
            state.categories.activities = [];
            state.transports = null;
            state.categories.transports = [];
        },
        removeExceptTowns: (state) => {
            state.accommodation = null;
            state.categories.accommodation = [];
            state.activities = null;
            state.categories.activities = [];
            state.transports = null;
            state.categories.transports = [];
        },
        resetSearch: () => {
            return { ...initialState, categories: { ...initialState.categories } };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(suppliers.fulfilled, (state, action: { payload: IOption[] }) => {
            state.categories.suppliers = action.payload;
        });
    },
});

export const selectSearchCategories = (state: RootState) => state.searchCategories;

export const {
    setName,
    setCountries,
    setStates,
    setRegions,
    setTowns,
    setAccommodation,
    setActivity,
    setTransport,
    setSupplier,
    setDay,
    setCountriesCategory,
    setStatesCategory,
    setRegionsCategory,
    setTownsCategory,
    setAccommodationCategory,
    setActivityCategory,
    setTransportCategory,
    setSupplierCategory,
    removeExceptCountry,
    removeExceptState,
    removeExceptRegions,
    removeExceptTowns,
    resetSearch,
    setSortDirection,
    setSortBy,
    resetSortDirection,
    setLengthOfTrip,
    setLengthOfTripCategory,
} = searchCategories.actions;
export default searchCategories.reducer;
