import { IBookingTimesFare } from "./../../Interfaces/tours";
import { logger } from "@utilities";
import { AppDispatch } from "./../store";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
    IDetailTour,
    ITourFare,
    ICheckAvailUrl,
    IFavoriteTour,
    ITourFareBookingTime,
} from "@interfaces";
import { toursApi } from "@api";
import { BOOKING_SYSTEMS } from "@constants";
import { store } from "@redux";
import { RootState } from ".";

interface IInitialState {
    tour: IDetailTour | null;
    tourFare: ICheckAvailUrl;
    isLoading: boolean;
}

const initialState: IInitialState = {
    tour: null,
    tourFare: {
        levy: null,
        bookingTimeId: null,
        productPricesDetailsId: null,
        fareName: null,
        dateRange: {
            from: null,
            to: null,
        },
    },
    isLoading: false,
};

export const getTour = createAsyncThunk<IDetailTour, string | number>(
    "tour/getTour",
    async (id: string | number, { rejectWithValue }) => {
        const favoriteTours = store.getState().favoriteTours.tours as IFavoriteTour[];
        try {
            const res = await toursApi.getById(id);
            const detailTourRes = res.data.results[0];
            let duringTime = "";
            if (Number(detailTourRes.durationDays) > 0) {
                duringTime += `${detailTourRes.durationDays} ${
                    Number(detailTourRes.durationDays) === 1 ? "day" : "days"
                }`;
            }
            if (Number(detailTourRes.durationDays) > 0 && Number(detailTourRes.durationNight) > 0) {
                duringTime += " - ";
            }
            if (Number(detailTourRes.durationNight) > 0) {
                duringTime += `${detailTourRes.durationNight} ${
                    Number(detailTourRes.durationNight) === 1 ? "night" : "nights"
                }`;
            }

            const description = [];
            const whatSIncluded = detailTourRes?.includedItems.join(", ") || "";
            const bringItems = detailTourRes?.bringItems.join(", ") || "";
            const descriptionContent = `
                ${
                    detailTourRes.productDetails &&
                    `<b>Description</b>: ${detailTourRes.productDetails}<br/>`
                }
                ${whatSIncluded && `<b>What's Included</b>: ${whatSIncluded}<br/>`}
                <b>Minimum Age</b>: ${detailTourRes?.minAgeRecommendation || 1} <br/>
                <b>Maximum Age</b>: ${detailTourRes?.maxAgeRecommendation || 99}<br/>
                <b>Fitness Requirement</b>: ${detailTourRes?.fitnessRequirement || "basic"} <br/>
                <b>Agent Restriction</b>: ${detailTourRes?.ageRestriction || 0} <br/>
                <b>Group Size</b>: ${detailTourRes?.minGroupSize || 1} - 
                ${detailTourRes?.maxGroupSize || 999} <br/>
                ${
                    detailTourRes.operatingPartner &&
                    `<b>Operating Partner</b>: ${detailTourRes.operatingPartner}<br/>`
                }
                ${
                    detailTourRes.dietaryRequirement &&
                    `<b>Dietary Requirements</b>: ${detailTourRes.dietaryRequirement}<br/>`
                }
                ${
                    detailTourRes.luggageLimit &&
                    `<b>Luggage Limit</b>: ${detailTourRes.luggageLimit}<br/>`
                }
                ${bringItems && `<b>What To Bring</b>: ${bringItems}<br/>`} 
                ${
                    detailTourRes.safePracticeMeasures &&
                    `<b>Covid Safe Practises</b>: ${detailTourRes.safePracticeMeasures}<br/>`
                }
            `;
            description.push({
                title: "Description",
                content: descriptionContent,
            });
            description.push({
                title: "Itinerary",
                content: detailTourRes?.itinerary || "",
            });
            description.push({
                title: "Departures/Pickups",
                content: detailTourRes?.pickups || "",
            });
            description.push({
                title: "Extras",
                content: detailTourRes?.extras || "",
            });

            description.push({
                title: "Agent Information",
                content:
                    detailTourRes?.conditions || "" + " " + detailTourRes?.importantInfos || "",
            });

            const resMedia = await toursApi.getMediasById(id);
            const listImg = resMedia.data.results;
            const isLiked = favoriteTours.some((tour) => tour.productId === id);

            const faresPrices: ITourFare[] = detailTourRes.faresprices;
            // for await (const fare of detailTourRes.faresprices) {
            //     if (
            //         BOOKING_SYSTEMS.some((system) => system === fare.bookingSystem) &&
            //         fare.availabilityInfoUrl
            //     ) {
            //         const res = await toursApi.getBookingDetail(fare.productPricesDetailsId);
            //         faresPrices.push({ ...fare, ...res.data });
            //     } else {
            //         faresPrices.push({ ...fare });
            //     }
            // }

            // WHAT: Merge cancel policy for each fare
            const cancelPolicies: ITourFare[] = [];
            faresPrices.forEach((fare) => {
                if (cancelPolicies.length === 0) {
                    cancelPolicies.push({ ...fare, fareName: `<b>${fare.fareName}</b> <br/>` });
                } else {
                    let isSame = true;
                    cancelPolicies.every((policy, index) => {
                        if (!isSame) return false;
                        const sameDescription =
                            policy.cancellationFeePolicyDescription ===
                            fare.cancellationFeePolicyDescription;
                        let sameRule = false;

                        // WHAT: check same all rule
                        if (
                            // WHAT: different length return false
                            policy.cancellationFeeRules.length === fare.cancellationFeeRules.length
                        ) {
                            sameRule = true;
                            // WHAT: check by index
                            for (
                                let index = 0;
                                index < policy.cancellationFeeRules.length;
                                index++
                            ) {
                                const sameDate =
                                    policy.cancellationFeeRules[index].daysBeforeTravelDate ===
                                    fare.cancellationFeeRules[index].daysBeforeTravelDate;
                                const sameAmount =
                                    policy.cancellationFeeRules[index].feeAmount ===
                                    fare.cancellationFeeRules[index].feeAmount;
                                if (!sameDate || !sameAmount) {
                                    // WHAT: return and break;
                                    sameRule = false;
                                    break;
                                }
                            }
                        }
                        isSame = sameDescription && sameRule;
                        if (isSame) {
                            cancelPolicies[index].fareName += `<b>${fare.fareName}</b> <br/>`;
                        }
                    });

                    if (!isSame) {
                        if (
                            BOOKING_SYSTEMS.some((system) => system === fare.bookingSystem) &&
                            fare.availabilityInfoUrl
                        ) {
                            cancelPolicies.push({
                                ...fare,
                                fareName: `<b>${fare.fareName}</b> <br/>`,
                            });
                        }
                    }
                }
            });
            if (cancelPolicies.length === 1) {
                cancelPolicies[0].fareName = "";
            }
            // WHAT: cancel policy for each fare
            const cancelPolicy = cancelPolicies.reduce((text, fare) => {
                const cancelFeeRule = fare.cancellationFeeRules.reduce((text, rule) => {
                    return `${text} <li>- Cancelled within ${rule.daysBeforeTravelDate} days before travel: ${rule.feeAmount}% fee</li> `;
                }, "");
                return `${text} ${fare.fareName}
                        <p>${fare.cancellationFeePolicyDescription}</p>
                        <ul>${cancelFeeRule}</ul> <br/>`;
            }, "<b>Please note any cancellation has a 10% cancellation processing fee PLUS the operator fee below.</b> <br/><br/>");

            description.push({
                title: "Cancellation Policy",
                content: cancelPolicy,
            });
            return {
                location: detailTourRes?.categories?.startLocationName || detailTourRes.country,
                name: detailTourRes.name,
                duration: duringTime,
                rrp: detailTourRes.rrp,
                supplier: detailTourRes.supplierName,
                productId: id.toString(),
                tourCode: detailTourRes.tourCode,
                description,
                isLiked,
                images: listImg,
                productImagePath: detailTourRes.productLargeSizeImagePath,
                faresPrices: faresPrices,
                supplierId: detailTourRes.supplierid,
                optionalFields: [],
            } as IDetailTour;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const getFarePrice =
    (fareId: string | number, fareName: string) => async (dispatch: AppDispatch) => {
        try {
            const res = await toursApi.getBookingDetail(fareId);

            const bookingTimes: IBookingTimesFare[] = res?.data?.bookingTimes;
            //make default fare
            if (bookingTimes?.length <= 0) {
                bookingTimes.push({
                    BookingTimeID: "0",
                    BookingTime: "Default",
                    Default: true,
                });
            }

            dispatch(setFareBookingTime({ fareId, bookingTime: { ...res.data, bookingTimes } }));
        } catch {
            const tour = store.getState().tour.tour;
            if (tour) {
                logger.getFareError(tour.name, tour.productId, fareName, fareId.toString());
            }

            dispatch(
                setFareBookingTime({
                    fareId,
                    bookingTime: {
                        bookingTimes: [],
                        pickupLocations: [],
                        optionalFields: [],
                        bookingTimeError: true,
                    },
                })
            );
        }
    };

const tourSplice = createSlice({
    name: "tour",
    initialState: initialState,
    reducers: {
        setTour: (state, action) => {
            state.tour = action.payload;
        },
        resetTour: (state) => {
            state.tour = null;
        },
        //set pickupTime item của faresprice
        setPickupTimeItem: (state, action) => {
            const index = state.tour?.faresPrices.findIndex(
                (faresPrice) => faresPrice.productPriceId === action.payload.productPriceId
            );

            if (index !== undefined && index >= 0 && state.tour) {
                state.tour.faresPrices[index].pickupTime = action.payload.value;
            }
        },
        //reset pickup time
        resetPickupTimes: (state) => {
            state.tour?.faresPrices.forEach((faresPrice) => {
                faresPrice.pickupTime = "";
            });
        },
        setTourFare: (state, action) => {
            const { bookingTimeId, productPricesDetailsId, fareName, levy } = action.payload;
            state.tourFare.bookingTimeId = bookingTimeId;
            state.tourFare.productPricesDetailsId = productPricesDetailsId;
            state.tourFare.fareName = fareName;
            state.tourFare.levy = levy;
        },
        resetTourFare: (state) => {
            state.tourFare.bookingTimeId = null;
            state.tourFare.productPricesDetailsId = null;
            state.tourFare.fareName = null;
            state.tourFare.levy = null;
        },
        setDateRangeFrom: (state, action) => {
            state.tourFare.dateRange.from = action.payload;
            if (!state.tourFare.dateRange.to) {
                state.tourFare.dateRange.to = action.payload;
            }
        },
        setDateRangeTo: (state, action) => {
            state.tourFare.dateRange.to = action.payload;
        },
        setDateRange: (state, action) => {
            state.tourFare.dateRange.from = action.payload.from;
            state.tourFare.dateRange.to = action.payload.to;
        },
        resetDateRange: (state) => {
            state.tourFare.dateRange = {
                from: null,
                to: null,
            };
        },
        sortTours: (state, action: PayloadAction<ITourFare[]>) => {
            if (state.tour) {
                state.tour.faresPrices = action.payload;
            }
        },
        setFareBookingTime: (
            state,
            action: PayloadAction<{ bookingTime: ITourFareBookingTime; fareId: string | number }>
        ) => {
            const { bookingTime, fareId } = action.payload;
            if (state.tour) {
                const fareIndex = state.tour.faresPrices.findIndex(
                    (fare) => fare.productPricesDetailsId === fareId
                );

                const defaultTime = bookingTime.bookingTimes.find((time) => time.Default);

                state.tour.faresPrices[fareIndex] = {
                    ...state.tour.faresPrices[fareIndex],
                    ...bookingTime,
                    bookingTimeId:
                        defaultTime?.BookingTimeID || bookingTime.bookingTimes[0]?.BookingTimeID,
                };
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getTour.fulfilled, (state, action: { payload: IDetailTour }) => {
            state.tour = action.payload;
            state.isLoading = false;
        });

        builder.addCase(getTour.pending, (state) => {
            state.isLoading = true;
        });

        builder.addCase(getTour.rejected, (state) => {
            state.tour = null;
            state.isLoading = false;
        });
    },
});

export const selectTour = (state: RootState) => state.tour;

export const {
    resetTour,
    setTour,
    setTourFare,
    resetTourFare,
    setDateRangeFrom,
    setDateRangeTo,
    resetDateRange,
    sortTours,
    setDateRange,
    setPickupTimeItem,
    resetPickupTimes,
    setFareBookingTime,
} = tourSplice.actions;
export default tourSplice.reducer;
