// import moment from "moment";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

import {
    OptionalField,
    ICart,
    IFareCart,
    ICustomerWithCart,
    IAddCart,
    ICustomerCart,
    IChangePickup,
} from "@interfaces";
import { CartConstants } from "@constants";
import { RootState } from ".";

const initialState: ICart = {
    carts: [],
    totalRRP: 0,
    totalNet: 0,
    totalPrice: 0,
    commission: 0,
    commissionPercent: 0,
    customers: [
        {
            id: uuidv4(),
            title: "Lead Customer Details",
            qty: 0,
            firstName: "",
            lastName: "",
            dateOfBirth: null,
            phone: "",
            email: "",
            postCode: "",
            country: null,
            isLeader: true,
            carts: [],
        },
    ],
    isShare: false,
    optionalFieldValidated: true,
    canCheckout: false,
    redirectUrl: "",
    bookingReference: "",
    step: CartConstants.CART,
    currentStep: CartConstants.CART,
};

export const INCREASE_QTY = "increase";
export const DECREASE_QTY = "decrease";

const cart = createSlice({
    name: "cart",
    initialState: initialState,
    reducers: {
        resetCart: () => {
            return initialState;
        },
        checkPayment: (state) => {
            state.canCheckout = initialState.canCheckout;
            // WHAT: cart exist and lead customer is field
            if (state.carts.length > 0 && state.customers[0]?.firstName !== "") {
                state.canCheckout = true;
                state.carts.forEach((cart) => {
                    // WHAT: not enough customer fer cart
                    if (cart.quantity > cart.qtyCustomer) {
                        state.canCheckout = false;
                    }
                });
            }
        },
        checkOptionalField: (state) => {
            state.optionalFieldValidated = true;
            // WHAT: check all optional field is validated
            state.carts.map((cart) => {
                cart.optionalFields.map((field) => {
                    // WHAT: if one mandatory field is empty or not valid the validation is false
                    if (field.type === "number") {
                        if (field.mandatory && (!field.value || field.value === "0")) {
                            state.optionalFieldValidated = false;
                        }
                    } else {
                        if (field.mandatory && (!field.value || field.value === "")) {
                            state.optionalFieldValidated = false;
                        }
                    }
                });
                if (
                    cart.pickupLocations &&
                    cart.pickupLocations?.length > 0 &&
                    !cart.pickupLocation
                ) {
                    state.optionalFieldValidated = false;
                }
            });
        },
        changePickupLocation: (state, action: PayloadAction<IChangePickup>) => {
            // WHAT: change value
            const index = state.carts.findIndex((cart) => {
                return cart.id === action.payload.itemId;
            });
            if (index >= 0) {
                state.carts[index].pickupLocation = action.payload.pickupLocation;
            }
            state.optionalFieldValidated = true;
            // WHAT: check all optional field is validated
            state.carts.map((cart) => {
                cart.optionalFields.map((field) => {
                    // WHAT: if one mandatory field is empty or not valid the validation is false
                    if (field.type === "number") {
                        if (field.mandatory && (!field.value || field.value === "0")) {
                            state.optionalFieldValidated = false;
                        }
                    } else {
                        if (field.mandatory && (!field.value || field.value === "")) {
                            state.optionalFieldValidated = false;
                        }
                    }
                });
                if (
                    cart.pickupLocations &&
                    cart.pickupLocations?.length > 0 &&
                    !cart.pickupLocation
                ) {
                    state.optionalFieldValidated = false;
                }
            });
        },
        addToCart: (state, action: PayloadAction<IAddCart>) => {
            const {
                tour,
                checkedAvail,
                productPricesDetailsId,
                fareName,
                bookingTimeId,
                datePriceCacheId,
            } = action.payload;
            let optionalFields: OptionalField[] = [];
            let levi;
            let leviDescription;
            const tourIndex = tour.faresPrices.findIndex(
                (fare: any) =>
                    fare.productPricesDetailsId.toString() === productPricesDetailsId?.toString()
            );
            // const bookingDateFormat = checkedAvail.BookingDate.toString().replaceAll("-", "/");
            /*WHY: get issue with the ios device when convert date from - to /. 
                dont know the reason why we have converting previously but now we remove the convert
            */
            const bookingDateFormat = checkedAvail.BookingDate;
            if (tourIndex >= 0) {
                optionalFields =
                    tour.faresPrices[tourIndex].optionalFields?.map((option: any) => {
                        return {
                            ...option,
                            value: option.type === "select" ? option.options[0] : null,
                        };
                    }) || [];
                levi = tour.faresPrices[tourIndex].levy || 0;
                leviDescription = tour.faresPrices[tourIndex].levyDescription || "";
            }
            const cartIndex = state.carts.findIndex((cartItem) => {
                return (
                    cartItem.date.toString() === new Date(bookingDateFormat).toString() &&
                    cartItem.productPricesDetailsId?.toString() ===
                        checkedAvail.productPricesDetailsId?.toString()
                );
            });

            //params
            if (cartIndex > -1) {
                if (state.carts[cartIndex].quantity < checkedAvail.NumAvailable) {
                    state.carts[cartIndex].quantity++;
                }
            } else {
                const fareIndex = tour.faresPrices.findIndex(
                    (fare) =>
                        fare.productPricesDetailsId.toString() ===
                        productPricesDetailsId?.toString()
                );
                const newCartItem: IFareCart = {
                    fareName,
                    BookingTimeID: bookingTimeId || "",
                    available: checkedAvail.NumAvailable,
                    date: new Date(bookingDateFormat),
                    productPricesDetailsId,
                    datePriceCacheId,
                    quantity: 1,
                    rrp: checkedAvail.productPricingData.RRP,
                    tour: tour,
                    net: checkedAvail.productPricingData.NetRate,
                    id: uuidv4(),
                    optionalFields,
                    qtyCustomer: 1,
                    levi,
                    leviDescription,
                    pickupLocations: tour.faresPrices[fareIndex]?.pickupLocations,
                };
                state.carts.push(newCartItem);

                // WHAT: add cart to lead customer
                const leaderIndex = state.customers.findIndex((customer) => customer.isLeader);
                if (leaderIndex >= 0) {
                    state.customers[leaderIndex].carts.push({
                        id: newCartItem.id,
                        quantity: 1,
                        productPricesDetailsId: newCartItem.productPricesDetailsId,
                    });
                }
            }
            state.totalRRP = state.carts.reduce(
                (accumulator, cartItem) => accumulator + cartItem.rrp * cartItem.quantity,
                0
            );
            state.totalNet = state.carts.reduce(
                (accumulator, cartItem) => accumulator + (cartItem.net || 0) * cartItem.quantity,
                0
            );
            state.commission = state.totalRRP - state.totalNet;
            if (state.totalRRP !== 0) {
                state.commissionPercent = (state.commission / state.totalRRP) * 100;
            } else {
                state.commissionPercent = 0;
            }

            state.totalPrice = state.totalRRP;
        },
        setTotalPriceCart: (state, action: PayloadAction<number>) => {
            const totalPrice = action.payload;
            // nếu totalPrice < totalNet thì không cập nhập totalNet
            if (totalPrice <= state.totalNet) {
                state.commissionPercent = 0;
                state.commission = 0;
                state.totalPrice = totalPrice;
            } else if (totalPrice < 10000000000) {
                state.commissionPercent = ((totalPrice - state.totalNet) / state.totalRRP) * 100;
                state.commission = totalPrice - state.totalNet;
                state.totalPrice = totalPrice;
            }
        },
        changeQty: (
            state,
            action: PayloadAction<{
                cartItemIndex: number;
                quantity: number;
                numAvailable?: number;
            }>
        ) => {
            const { cartItemIndex, quantity, numAvailable } = action.payload;

            // WHAT: update fare available
            if (numAvailable) {
                state.carts[cartItemIndex].available = numAvailable;
            }

            // WHAT: check quantity and update
            if (quantity > state.carts[cartItemIndex].available) {
                state.carts[cartItemIndex].quantity =
                    numAvailable || state.carts[cartItemIndex].available;
            } else {
                state.carts[cartItemIndex].quantity = quantity;
            }

            // WHAT: update total rrp
            state.totalRRP = state.carts.reduce(
                (accumulator, cartItem) => accumulator + cartItem.rrp * cartItem.quantity,
                0
            );
            state.totalNet = state.carts.reduce(
                (accumulator, cartItem) => accumulator + (cartItem.net || 0) * cartItem.quantity,
                0
            );
            state.commission = state.totalRRP - state.totalNet;
            if (state.totalRRP !== 0) {
                state.commissionPercent = (state.commission / state.totalRRP) * 100;
            } else {
                state.commissionPercent = 0;
            }
            state.totalPrice = state.totalRRP;

            // WHAT:reset customer cart
            const cartItemId = state.carts[cartItemIndex].id;
            state.customers.map((customer, index) => {
                const customerCartIndex = customer.carts.findIndex(
                    (customerCart) => customerCart.id === cartItemId
                );

                if (index < quantity) {
                    if (customerCartIndex >= 0) {
                        state.customers[index].carts[customerCartIndex].quantity = 1;
                    } else {
                        state.customers[index].carts.push({
                            id: cartItemId,
                            quantity: 1,
                            productPricesDetailsId:
                                state.carts[cartItemIndex].productPricesDetailsId,
                        });
                    }
                } else {
                    if (customerCartIndex >= 0) {
                        state.customers[index].carts[customerCartIndex].quantity = 0;
                    }
                }
            });

            // WHAT: add the rest to lead customer
            if (state.customers.length < quantity) {
                const theRestQuantity = quantity - state.customers.length;
                // WHAT: find lead
                const leadIndex = state.customers.findIndex((customer) => customer.isLeader);
                if (leadIndex >= 0) {
                    const leadCustomer = state.customers[leadIndex];
                    const customerCartIndex = leadCustomer.carts.findIndex(
                        (customerCart) => customerCart.id === cartItemId
                    );
                    if (customerCartIndex >= 0) {
                        state.customers[leadIndex].carts[customerCartIndex].quantity +=
                            theRestQuantity;
                    }
                }
            }

            state.carts[cartItemIndex].qtyCustomer = quantity;
        },
        removeCart: (state, action: PayloadAction<string | number>) => {
            const cartId = action.payload;

            const cartItemIndex = state.carts.findIndex((cartItem) => cartItem.id === cartId);
            if (cartItemIndex >= 0) {
                if (state.carts.length === 1) {
                    return initialState;
                } else {
                    state.carts.splice(cartItemIndex, 1);
                    // WHAT: update total rrp
                    state.totalRRP = state.carts.reduce(
                        (accumulator, cartItem) => accumulator + cartItem.rrp * cartItem.quantity,
                        0
                    );
                    state.totalNet = state.carts.reduce(
                        (accumulator, cartItem) =>
                            accumulator + (cartItem.net || 0) * cartItem.quantity,
                        0
                    );
                    state.commission = state.totalRRP - state.totalNet;
                    if (state.totalRRP !== 0) {
                        state.commissionPercent = (state.commission / state.totalRRP) * 100;
                    } else {
                        state.commissionPercent = 0;
                    }
                    state.totalPrice = state.totalRRP;

                    //clear customer cart

                    // WHAT: remove in customer cart
                    state.customers.map((customer, index) => {
                        const customerCartIndex = customer.carts.findIndex(
                            (customerCart) => customerCart.id === cartId
                        );

                        if (customerCartIndex >= 0) {
                            state.customers[index].carts.splice(customerCartIndex, 1);
                        }
                    });
                }
            }
        },
        addCustomer: (state) => {
            const newCustomer: ICustomerWithCart = {
                id: uuidv4(),
                firstName: "",
                lastName: "",
                country: null,
                dateOfBirth: null,
                email: "",
                phone: "",
                postCode: "",
                title: "",
                qty: 0,
                isLeader: false,
                carts: [],
            };
            state.customers.push(newCustomer);
        },
        editCustomer: (
            state,
            action: PayloadAction<{ newInfo: ICustomerWithCart; id: string }>
        ) => {
            const { newInfo, id } = action.payload;
            const customerIndex = state.customers.findIndex((item) => item.id === id);
            if (customerIndex > -1) {
                const customer = state.customers[customerIndex];
                const initQuantity = 1;
                // WHAT: allocating new customer
                const carts: ICustomerCart[] = customer.carts;
                state.carts.map((cart, index) => {
                    if (cart.quantity > cart.qtyCustomer && customer.carts.length === 0) {
                        state.carts[index].qtyCustomer = cart.qtyCustomer + initQuantity;
                        carts.push({
                            id: cart.id,
                            quantity: initQuantity,
                            productPricesDetailsId: cart.productPricesDetailsId,
                        });
                    }
                });

                state.customers[customerIndex] = {
                    ...newInfo,
                    carts: [...carts],
                };
                // WHAT: check all cart for can check out
                state.canCheckout = true;
                state.carts.forEach((cart) => {
                    // WHAT: not enough customer fer cart
                    if (cart.quantity > cart.qtyCustomer) {
                        state.canCheckout = false;
                    }
                });
            }
        },
        updateCustomerQuantity: (
            state,
            action: PayloadAction<{
                customerId: string | number;
                cartId: string | number;
                productPricesDetailsId: string | number | null;
                quantity: number;
            }>
        ) => {
            const { customerId, cartId, productPricesDetailsId, quantity } = action.payload;
            const cartItemIndex = state.carts.findIndex((cartItem) => cartItem.id === cartId);
            if (cartItemIndex >= 0 && state.customers[0]?.firstName !== "") {
                const cartItem = state.carts[cartItemIndex];
                // WHAT: Find customer
                const customerIndex = state.customers.findIndex(
                    (customer) => customer.id === customerId
                );
                // console.log(cartItem.quantity);
                // WHAT: check quantity can update
                if (
                    customerIndex >= 0 &&
                    (cartItem.quantity > cartItem.qtyCustomer || quantity < cartItem.quantity)
                ) {
                    const customer = state.customers[customerIndex];
                    // WHAT: find cart update quantity add new if don't have
                    const customerCartIndex = customer.carts.findIndex(
                        (cart) => cart.id === cartId
                    );
                    if (customerCartIndex >= 0) {
                        const quantityChange =
                            quantity -
                            state.customers[customerIndex].carts[customerCartIndex].quantity;
                        state.carts[cartItemIndex].qtyCustomer =
                            cartItem.qtyCustomer + quantityChange;
                        state.customers[customerIndex].carts[customerCartIndex].quantity = quantity;
                    } else if (quantity > 0) {
                        state.carts[cartItemIndex].qtyCustomer = cartItem.qtyCustomer + quantity;
                        state.customers[customerIndex].carts.push({
                            id: cartId,
                            quantity: quantity,
                            productPricesDetailsId: productPricesDetailsId,
                        });
                    }

                    // WHAT: check all cart for can check out
                    state.canCheckout = true;
                    state.carts.forEach((cart) => {
                        // WHAT: not enough customer fer cart
                        if (cart.quantity > cart.qtyCustomer) {
                            state.canCheckout = false;
                        }
                    });
                }
            }
        },
        resetCustomer: (state, action: PayloadAction<{ customerId: string }>) => {
            const customerIndex = state.customers.findIndex(
                (customer) => customer.id === action.payload.customerId
            );
            if (customerIndex !== -1) {
                state.customers[customerIndex] = {
                    id: action.payload.customerId,
                    firstName: "",
                    lastName: "",
                    country: null,
                    dateOfBirth: null,
                    email: "",
                    phone: "",
                    postCode: "",
                    title: "",
                    qty: 0,
                    isLeader: false,
                    carts: [],
                };
            }
        },
        removeCustomer: (state, action: PayloadAction<{ customerId: string }>) => {
            const customerIndex = state.customers.findIndex(
                (customer) => customer.id === action.payload.customerId
            );
            if (customerIndex !== -1) {
                state.customers.splice(customerIndex, 1);
            }
        },
        replaceCart: (state, action: PayloadAction<ICart>) => {
            return {
                ...action.payload,
                // What : reset isShare and redirectUrl to clear old payment if have
                isShare: initialState.isShare,
                redirectUrl: initialState.redirectUrl,
            };
        },
        saveAnswersCartItem: (state, action) => {
            // WHAT: change value
            const index = state.carts.findIndex((cart) => {
                return cart.id === action.payload.itemId;
            });
            if (index >= 0) {
                const indexFiled = state.carts[index].optionalFields.findIndex((optionField) => {
                    return optionField.optionalFieldId === action.payload.optionalFieldId;
                });
                if (indexFiled >= 0) {
                    //save new value
                    state.carts[index].optionalFields[indexFiled].value = action.payload.value;
                }
            }
            state.optionalFieldValidated = true;
            // WHAT: check all optional field is validated
            state.carts.map((cart) => {
                cart.optionalFields.map((field) => {
                    // WHAT: if one mandatory field is empty or not valid the validation is false
                    if (field.type === "number") {
                        if (field.mandatory && (!field.value || field.value === "0")) {
                            state.optionalFieldValidated = false;
                        }
                    } else {
                        if (field.mandatory && (!field.value || field.value === "")) {
                            state.optionalFieldValidated = false;
                        }
                    }
                });
                if (
                    cart.pickupLocations &&
                    cart.pickupLocations?.length > 0 &&
                    !cart.pickupLocation
                ) {
                    state.optionalFieldValidated = false;
                }
            });
        },
        share: (
            state,
            action: PayloadAction<{
                isShare: boolean;
                redirectUrl: string;
            }>
        ) => {
            state.isShare = action.payload.isShare;
            state.redirectUrl = action.payload.redirectUrl;
        },
        setStep: (state, action) => {
            state.step = action.payload;
        },
        setCurrentStep: (state, action) => {
            state.currentStep = action.payload;
        },
        setBookingReference: (state, action: PayloadAction<string>) => {
            state.bookingReference = action.payload;
        },
    },
});

export const selectCart = (state: RootState) => state.cart;

export const {
    resetCart,
    addToCart,
    changeQty,
    removeCart,
    addCustomer,
    editCustomer,
    resetCustomer,
    removeCustomer,
    replaceCart,
    saveAnswersCartItem,
    setTotalPriceCart,
    checkOptionalField,
    checkPayment,
    updateCustomerQuantity,
    share,
    setStep,
    setCurrentStep,
    setBookingReference,
    changePickupLocation,
} = cart.actions;

export default cart.reducer;
