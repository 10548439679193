import styled from "styled-components";
// import { useDetectClickOutside } from "react-detect-click-outside";
import React, { memo } from "react";

import { selectSearchCategories, useAppSelector } from "@redux";
import { IOption } from "@interfaces";
import { breakpoint } from "@utilities";
import { ClipLoaderCommon } from "../Common";

interface IProps {
    data: IOption[];
    onCloseOptions: () => void;
    onClickItem: (categoryItem: IOption) => void;
    name: string;
}

export const ListOptionFilterComponent = memo((props: IProps) => {
    const searchCategories = useAppSelector(selectSearchCategories);
    const { data, onClickItem, name } = props;
    // const overlayRef = useDetectClickOutside({ onTriggered: onCloseOptions });
    if (data?.length === 0) {
        return (
            <Container>
                {/* <h1 className="loading">No Data</h1> */}
                <ClipLoaderCommon color="#31B4B9" size={35} margin={5} />
            </Container>
        );
    }

    return (
        <Container>
            {data.map((item, index) => {
                //@ts-ignore
                const isActive = item.id === searchCategories[name]?.id;
                return (
                    <React.Fragment key={index}>
                        {item.text === "" ? (
                            <></>
                        ) : (
                            <div
                                className={`item ${isActive ? "active" : ""}`}
                                key={item.id}
                                onClick={() => onClickItem(item)}
                            >
                                <span className="text">{item.text}</span>
                                <span className="checkIcon">
                                    <img src="/images/fi_check.svg" alt="" />
                                </span>
                            </div>
                        )}
                    </React.Fragment>
                );
            })}
        </Container>
    );
});

const Container = styled.div`
    left: 0;
    max-height: 384px;
    overflow-y: scroll;
    width: 100% !important;
    background-color: #fff;
    z-index: 1;
    border-bottom: 1px solid ${(p) => p.theme.colors.gray_4};
    border-top: 1px solid ${(p) => p.theme.colors.gray_4};
    padding: 14px 0px;

    ${breakpoint.breakTablet`
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        border-left: 1px solid ${(p) => p.theme.colors.gray_4};
        border-right: 1px solid ${(p) => p.theme.colors.gray_4};
    `}

    @media (max-width: 320px) {
        max-height: 300px;
    }

    .loading {
        padding-left: 20px;
    }

    &::-webkit-scrollbar {
        -webkit-appearance: none;
        appearance: none;
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        appearance: none;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        -webkit-appearance: none;
        appearance: none;
        background-color: rgba(0, 0, 0, 0.3);
        transition: 0.3s;
        border-radius: 5px;
    }

    .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #000;
        white-space: nowrap;
        cursor: pointer;

        ${breakpoint.breakOnlyMobile`
            height: 40px;
            padding: 0 14px 0 28px;
        `}

        ${breakpoint.breakTablet`
            height: 30px;
            padding: 0 9px 0 15px;
        `}
        
        ${breakpoint.breakIpadPro`
            height: 35px;
            padding: 0 13px 0 27px;
        `}
        
        ${breakpoint.breakLarge`
            height: 40px;
            padding: 0 14px 0 28px;
        `}

        .text {
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            /* max-width: 80px; */

            ${breakpoint.breakTablet`
                font-size: 12px;
            `}

            ${breakpoint.breakIpadPro`
                font-size: 16px;
            `}
            
            /* ${breakpoint.breakLarge`
                font-size: 16px;
            `} */
        }

        .checkIcon {
            display: none;
            background-color: #fff;
            text-align: center;
            border-radius: 50%;
            margin-left: 5px;

            ${breakpoint.breakOnlyMobile`
                width: 18px;
                height: 18px;
                line-height: 18px;
            `}

            ${breakpoint.breakTablet`
                width: 10px;
                height: 10px;
                line-height: 10px;
            `}

            ${breakpoint.breakLarge`
                width: 18px;
                height: 18px;
                line-height: 18px;
            `}
        }

        &.active {
            background-color: ${(p) => p.theme.colors.main};
            color: #fff;

            .checkIcon {
                display: inline-block;
            }
        }
    }
`;
