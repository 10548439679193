import { Waypoint } from "react-waypoint";
import { Link } from "react-scroll";
import styled from "styled-components";
const renderHTML = require("react-render-html");

import { IDescription } from "@interfaces";
import { StyledDescription, StyledNavBar } from "@components";
import { useWindowDimensions } from "@utilities";

interface IProps {
    data: IDescription[];
    currentTagIndex: number;
    _onEnter: (index: number) => void;
    isActiveNav: boolean;
    onGetInfoBoxRef: (ref: any) => void;
    onGetNavItemRef: (index: number, ref: any) => void;
}

interface INavItemProps {
    active: number;
}

export const InfoBoxComponent = (props: IProps) => {
    const { data, currentTagIndex, _onEnter, isActiveNav, onGetInfoBoxRef, onGetNavItemRef } =
        props;
    const { height } = useWindowDimensions();
    return (
        <div style={{ paddingBottom: "100px" }} ref={onGetInfoBoxRef}>
            <StyledNavBar active={isActiveNav}>
                {data.map((item, index) => {
                    return (
                        <div ref={(ref: any) => onGetNavItemRef(index, ref)} key={index}>
                            <StyledNavItem
                                to={item.title}
                                smooth={true}
                                duration={500}
                                offset={-120}
                                active={currentTagIndex === index ? 1 : 0}
                            >
                                <div className="title">
                                    {item.title}
                                    <div className="line"></div>
                                </div>
                            </StyledNavItem>
                        </div>
                    );
                })}
            </StyledNavBar>
            {data.map((item, index) => {
                return (
                    <StyledDescription name={item.title} key={index}>
                        <div className="heading">
                            {item.title}
                            <Waypoint
                                key={index}
                                onEnter={() => _onEnter(index)}
                                topOffset={155}
                                bottomOffset={height - 155 - 20}
                            >
                                <div className="wayPoint"></div>
                            </Waypoint>
                        </div>
                        <div className="content">{renderHTML(item.content)}</div>
                    </StyledDescription>
                );
            })}
        </div>
    );
};

const StyledNavItem = styled(Link)<INavItemProps>`
    height: 100%;
    white-space: nowrap;

    .title {
        padding: 18px 16px;
        font-size: 16px;
        font-weight: 700;
        line-height: 26px;
        position: relative;
    }

    .line {
        position: absolute;
        left: 50%;
        bottom: 2px;
        height: 4px;
        transform: translateX(-50%);
        background-color: ${(props) => props.theme.colors.main};
        transition: 300ms;
        width: ${(props) => (props.active ? "100%" : "0")};
    }
`;
