import styled from "styled-components";
import { Link } from "react-router-dom";

import FooterContactItem from "./FooterContactItem";
import { PATH_HOME, PATH_CART } from "@routes";
import { breakpoint } from "@utilities";
import { LINk_FACEBOOK, LINK_INSTAGRAM, LINk_LINKEDIN } from "@constants";

export const FooterComponent = () => {
    const socialIcon = [
        {
            icon: "/images/website/footer/fb.png",
            link: LINk_FACEBOOK,
        },
        {
            icon: "/images/website/footer/insta.png",
            link: LINK_INSTAGRAM,
        },
        {
            icon: "/images/website/footer/akar-icons_linkedin-fill.png",
            link: LINk_LINKEDIN,
        },
    ];

    const menu = [
        {
            title: "Home",
            link: PATH_HOME,
        },
        {
            title: "Cart",
            link: PATH_CART,
        },
    ];

    const contactList = [
        {
            img: "/images/website/footer/fi_map-pin.png",
            title: "Level 4, 437 St Kilda Road, Melbourne, VIC, 3004, Australia",
        },
        {
            img: "/images/website/footer/fi_mail.png",
            title: "support@freelancetravel.com",
        },
    ];

    return (
        <StyledFooterWebsite>
            <div className="wrapper">
                <div className="follow">
                    <img src="/images/logos/FreelanceTravel_Primary_Logo_Rev_(2)_(da3453ef-44bb-4946-a8ef-4ee1a8a33e3c).svg" alt="logo" className="logo" />
                    <p className="title">Follow us on</p>
                    <div className="social">
                        {socialIcon.map((icon, index) => {
                            return (
                                <a className="icon" key={index} href={icon.link}>
                                    <img src={icon.icon} alt="icon" />
                                </a>
                            );
                        })}
                    </div>
                </div>
                <div className="section travel">
                    <p className="title">Freelance Travel</p>
                    <div>
                        {menu.map((item, index) => {
                            return (
                                <Link key={index} to={item.link}>
                                    <p className="menuItem">{item.title}</p>
                                </Link>
                            );
                        })}
                    </div>
                </div>
                <div className="section">
                    <p className="title">Contact</p>
                    <div>
                        {contactList.map((item, index) => (
                            <FooterContactItem key={index} img={item.img} title={item.title} />
                        ))}
                    </div>
                </div>
            </div>
            <p className="copyright">Copyright © Freelance Travel. All rights reserved</p>
        </StyledFooterWebsite>
    );
};

const StyledFooterWebsite = styled.div`
    width: 100%;
    background-color: ${(props) => props.theme.colors.black};
    display: block;
    margin-top: 40px;

    ${breakpoint.breakLarge`
        padding-top: 20px;
    `}

    .wrapper {
        width: ${(props) => props.theme.widths.wide_700};
        margin: 0 auto;
        display: flex;

        ${breakpoint.breakIpadPro`
            width: ${(props) => props.theme.widths.wide_900};
        `}

        ${breakpoint.breakLarge`
            width: ${(props) => props.theme.widths.wide_1110};
        `}

        div {
            list-style: none;
        }

        .title {
            color: ${(props) => props.theme.colors.main};
        }

        .follow {
            flex: 5;
            margin-top: 30px;

            .logo {
                margin-bottom: 30px;
                width: 90px;

                ${breakpoint.breakIpadPro`
                    width: 210px;
                `}

                ${breakpoint.breakLarge`
                    width: 240px;
                `}
            }
            .title {
                font-size: 13px;
                font-weight: 700;
                margin-bottom: 15px;

                ${breakpoint.breakIpadPro`
                    font-size: 16px;
                `}

                ${breakpoint.breakLarge`
                    font-size: 18px;
                    margin-bottom: 20px;
                `}
            }

            .social {
                display: flex;
                align-items: center;

                .icon {
                    margin-right: 15px;

                    ${breakpoint.breakIpadPro`
                        margin-right: 20px;
                    `}

                    ${breakpoint.breakLarge`
                        margin-right: 30px;
                    `}
                    
                    img {
                        width: 15px;

                        ${breakpoint.breakIpadPro`
                            width: 18px;
                        `}

                        ${breakpoint.breakLarge`
                            width: 20px;
                        `}
                    }
                }
            }
        }

        .section {
            flex: 4;
            margin-top: 50px;

            &.travel {
                flex: 3;
            }

            .title {
                font-size: 12px;
                font-weight: 700;
                margin-bottom: 20px;

                ${breakpoint.breakIpadPro`
                    font-size: 14px;
                `}

                ${breakpoint.breakLarge`
                    font-size: 18px;
                `}
            }
            .menuItem {
                color: #fff;
                font-size: 10px;
                font-weight: 400;
                margin-bottom: 20px;

                ${breakpoint.breakIpadPro`
                    font-size: 12px;
                    margin-bottom: 20px;
                `}

                ${breakpoint.breakLarge`
                    font-size: 16px;
                `}
            }
        }
    }

    .copyright {
        background-color: ${(props) => props.theme.colors.main};
        color: #fff;
        padding: 1em 0;
        text-align: center;
        font-size: 10px;
        margin-top: 25px;
        font-weight: 400;

        ${breakpoint.breakIpadPro`
            font-size: 10px;
        `}

        ${breakpoint.breakLarge`
            margin-top: 65px;
            font-size: 12px;
        `}
    }
`;
