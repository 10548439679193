import Paper from "@material-ui/core/Paper";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useCallback } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import { CartItemComponent, IncreaseCartComponent } from "@components";
import { breakpoint } from "@utilities";
import { PATH_DETAIL_TOUR } from "@routes";
import { IFareCart, IPickupLocationFare, OptionalField } from "@interfaces";
import { OptionalFieldModule } from "@module";
import { changeQty, openToast, selectCart, useAppSelector, changePickupLocation } from "@redux";
import { DURATION_TIME } from "@constants";

interface IPropsCartItem {
    item: IFareCart;
    handleDeletePopUp: (cartDeleteId: string | number) => void;
}

const CartItem = (props: IPropsCartItem) => {
    //props
    const { item, handleDeletePopUp } = props;
    //page hook
    const history = useHistory();
    const dispatch = useDispatch();
    //redux state
    const carts = useAppSelector(selectCart).carts;
    //page variable
    const totalPrice = item.quantity * item.rrp;
    const cartItemIndex = carts.findIndex((cartItem) => cartItem.id === item.id);
    const pickupLocationField: OptionalField = {
        value: "",
        apiName: "",
        mandatory: false,
        optionalFieldId: "pickupLocation",
        options: [],
        title: "Pick Up Location",
        type: "select",
    };

    const handleClick = () => {
        history.push(`${PATH_DETAIL_TOUR}/${item.tour.productId}`);
    };

    const increaseQty = useCallback(() => {
        if (item.quantity < item.available) {
            dispatch(
                changeQty({
                    cartItemIndex,
                    quantity: item.quantity + 1,
                })
            );
        } else {
            dispatch(
                openToast({
                    open: true,
                    type: "error",
                    message: `Your request has exceeded the quantity of ${item.fareName} available. Please choose another date or fare type.`,
                    autoHideDuration: DURATION_TIME,
                })
            );
        }
    }, [item.id, item.quantity]);

    const decreaseQty = useCallback(() => {
        if (cartItemIndex >= 0) {
            if (item.quantity === 1) {
                handleDelete();
            } else {
                dispatch(
                    changeQty({
                        cartItemIndex,
                        quantity: item.quantity - 1,
                    })
                );
            }
        }
    }, [item.id, item.quantity]);

    const handleDelete = () => {
        handleDeletePopUp(item.id);
    };

    const changeLocation = (
        e: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ) => {
        const value = e.target.value;
        const pickupLocation = item.pickupLocations?.find((pickup) => pickup.PickupID === value);
        dispatch(changePickupLocation({ itemId: item.id, pickupLocation }));
    };

    return (
        <>
            <CartItemComponent cart={item} onClickDeleteBtn={handleDelete} onClick={handleClick}>
                <IncreaseCartComponent
                    quantity={item.quantity}
                    handleIncrease={increaseQty}
                    handleDecrease={decreaseQty}
                    totalPrice={totalPrice}
                />
            </CartItemComponent>
            <ContainerChildren>
                {item.optionalFields?.map((optionalField: OptionalField, index: number) => {
                    return (
                        <>
                            {optionalField.mandatory && (
                                <FormControl component="fieldset" key={index}>
                                    <label
                                        className={`title `}
                                        htmlFor={`input_${item.id}_${index}`}
                                    >
                                        {optionalField.title} <span>*</span>
                                    </label>
                                    <Paper component="form">
                                        <OptionalFieldModule
                                            optionalField={optionalField}
                                            itemId={item.id}
                                        />
                                    </Paper>
                                </FormControl>
                            )}
                        </>
                    );
                })}
                {item.pickupLocations && item.pickupLocations?.length > 0 && (
                    <FormControl component="fieldset" style={{ width: "100%" }}>
                        <label className={`title `} htmlFor={`input-pickup`}>
                            {pickupLocationField.title} <span>*</span>
                        </label>
                        <Paper component="form">
                            <Select
                                labelId="label"
                                value={item.pickupLocation?.PickupID}
                                IconComponent={KeyboardArrowDownIcon}
                                onChange={changeLocation}
                            >
                                {item.pickupLocations?.map(
                                    (option: IPickupLocationFare, index: number) => {
                                        return (
                                            <MenuItem value={option.PickupID || ""} key={index}>
                                                <div
                                                    style={{ width: "12px" }}
                                                >{`${option.PickupTime} ${option.PickupLocation}`}</div>
                                            </MenuItem>
                                        );
                                    }
                                )}
                            </Select>
                        </Paper>
                    </FormControl>
                )}
            </ContainerChildren>
        </>
    );
};

const ContainerChildren = styled.div`
    background-color: #fff;
    padding: 20px 16px;

    margin-bottom: 24px;
    fieldset.MuiFormControl-root {
        width: 100%;
        .title {
            font-weight: bold;
            font-size: 16px;
            color: #090a18;
            margin-bottom: 16px;
            ${breakpoint.breakIpad`
                font-size: 12px;
            `}
            ${breakpoint.breakOnlyMobile`
                font-size: 14px;
                margin-bottom: 16px;
            `}
            span {
                color: red;
            }
        }
        ${breakpoint.breakIpadPro`
                width:48%;
        `}
    }
    .MuiInputBase-root {
        width: 100%;
        background: #f2f2f2;
        padding: 4px 12px;
        margin-top: 0px;
    }
    .MuiPaper-elevation1 {
        box-shadow: none;
    }
    .MuiSelect-root {
        width: 100% !important;
        background: transparent;
        ${breakpoint.breakTablet`

        `}
    }
    ${breakpoint.breakTablet`
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .MuiInput-root {
            display: block !important;
        }
    `}
    .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu {
        background-color: transparent !important;
        font-weight: 300 !important;
        ${breakpoint.breakIpad`
        font-size: 14px !important;
        `}
        ${breakpoint.breakOnlyMobile`
        font-size: 14px !important;
        `}
    }
    .MuiGrid-root.MuiGrid-container.MuiGrid-justify-xs-space-around {
        justify-content: inherit;
        display: inherit;
        width: 100%;
    }
    .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal {
        margin: 0 !important;
        width: 100%;
    }
    .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedEnd {
        display: flex !important;
    }
    .error {
        font-size: 18px;
        color: red;
        margin-top: 8px;
    }
    .MuiGrid-root.MuiGrid-container.MuiGrid-justify-content-xs-space-around {
        justify-content: flex-start;
    }
`;

export default CartItem;
