import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { IFavoriteTour } from "@interfaces";
import { phpHelper } from "src/Utilities/phpHelper";
import { RootState } from ".";
import { store, AppDispatch } from "@redux";

interface IFavoriteTours {
    tours: IFavoriteTour[];
}

interface IToggleFavorite {
    tour: IFavoriteTour;
    method: number;
}

const initialState: IFavoriteTours = {
    tours: [],
};

export const FAVORITE_METHOD_LIKE = 1;
export const FAVORITE_METHOD_UNLIKE = 0;

export const fetchFavoriteTour = createAsyncThunk<IFavoriteTour[], void>(
    "get/favoriteTours",
    async (action, { rejectWithValue }) => {
        try {
            // const res: any = await phpHelper.fetchFavorite();
            const res: any = await phpHelper.getFavourites();
            return (res?.data as IFavoriteTour[]) || [];
        } catch (err: any) {
            return rejectWithValue(err);
        }
    }
);
export const toggleFavorites = (tour: IFavoriteTour, method: number) => (dispatch: AppDispatch) => {
    let tours: IFavoriteTour[] = store.getState().favoriteTours.tours;

    const tourIndex = tours.findIndex((tourFind) => tourFind.productId === tour.productId);
    if (tourIndex >= 0 && method === FAVORITE_METHOD_UNLIKE) {
        // nếu có thì unlike
        tours = [...tours.slice(0, tourIndex), ...tours.slice(tourIndex + 1)];
        phpHelper.deleteFavorite(tour);
    } else if (tourIndex < 0 && method === FAVORITE_METHOD_LIKE) {
        // nếu chưa thi like
        tours = [...tours, tour];

        phpHelper.addFavorite(tour);
    }
    dispatch(setFavoriteTours(tours));
    // phpHelper.saveFavorite(tours);
};

const favoriteTours = createSlice({
    name: "favoriteTours",
    initialState: initialState,
    reducers: {
        toggleFavorite: (state, { payload }: { payload: IToggleFavorite }) => {
            const { tour, method } = payload;
            let tours = state.tours;
            // check xem product đã có trong list favorites hay chưa
            const tourIndex = tours.findIndex((tourFind) => tourFind.productId === tour.productId);
            if (tourIndex >= 0 && method === FAVORITE_METHOD_UNLIKE) {
                // nếu có thì unlike
                tours = [...tours.slice(0, tourIndex), ...tours.slice(tourIndex + 1)];
            } else if (tourIndex < 0 && method === FAVORITE_METHOD_LIKE) {
                // nếu chưa thi like
                tours.push(tour);
            }
            state.tours = tours; // cập nhật state
        },
        setFavoriteTours: (state, action: PayloadAction<IFavoriteTour[]>) => {
            state.tours = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchFavoriteTour.fulfilled, (state, action) => {
            state.tours = action.payload;
        });
    },
});

export const selectFavoriteTours = (state: RootState) => state.favoriteTours;

export const { toggleFavorite, setFavoriteTours } = favoriteTours.actions;
export default favoriteTours.reducer;
