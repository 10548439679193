import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { StyledButton, StyledBorderButton } from "@components";
import { PATH_BOOKING, PATH_BOOKING_DETAIL, PATH_HOME } from "@routes";
import { resetCart, selectCart } from "@redux";
import { breakpoint, lsBookingReference, ssOrder } from "@utilities";

export const OrderSuccessModule = () => {
    //page hooks
    const history = useHistory();
    const dispatch = useDispatch();
    //redux state
    const cartRedux = useSelector(selectCart);
    const { isShare, bookingReference } = cartRedux;

    const ssOrderInfo = ssOrder.getItem();

    useEffect(() => {
        return () => {
            dispatch(resetCart());
            lsBookingReference.removeItem();
            ssOrder.removeItem();
        };
    }, []);

    const handleViewTicket = () => {
        if (ssOrderInfo?.orderId) history.push(`${PATH_BOOKING_DETAIL}/${ssOrderInfo?.orderId}`);
        else history.push(PATH_BOOKING);
    };

    const handleBackToHome = () => {
        history.push(PATH_HOME);
    };

    const handleClickHere = () => {
        history.push(PATH_BOOKING);
    };

    // const handleShare = () => {
    //     if (redirectUrl) {
    //         navigator.clipboard.writeText(redirectUrl);
    //         dispatch(
    //             openToast({
    //                 type: "success",
    //                 message: `Copied to clipboard`,
    //                 autoHideDuration: 3000,
    //             })
    //         );
    //     }
    // };

    return (
        <Container>
            <Main>
                <div className="orderSuccessBackground">
                    <div className="orderSuccess">
                        <img src="/images/order_success.svg" alt="order_success" />
                    </div>
                </div>
                <p className="heading">
                    {isShare ? "Payment Link Emailed Successfully" : "Order Successful!"}{" "}
                </p>
                {!isShare ? (
                    <p className="subHeading">
                        Your order has been successfully completed. The confirmation and digital
                        travel vouchers have been emailed to your customer.
                    </p>
                ) : (
                    <>
                        <p className="subHeading">
                            A link to the quote including secure payment link has been successfully
                            emailed to the lead customer on the booking.
                            {/* <b>Please note all payment links are for one-time use only.</b>. If you
                            have received a copy of the email we sent the customer,{" "}
                            <b>please do not click the link.</b> */}
                        </p>
                        <p className="subHeading">
                            If you need to resend a new link or make any changes before payment, the
                            order can be located in your ‘Quotes’ tab.
                        </p>
                        <p className="subHeading">
                            When your customer pays via the below link, the order will move to your
                            ‘Bookings’ tab and the confirmation and digital travel vouchers will be
                            automatically emailed to your customer.
                        </p>
                    </>
                )}
                {!isShare && (
                    <div className="paymentInfo">
                        <p className="title">Booking Reference</p>
                        <p className="bookingReference">{bookingReference}</p>
                        <p className="date">Date Of Purchase: {ssOrderInfo?.date}</p>
                        <p className="titleReceipt">Receipt Details</p>
                        {ssOrderInfo?.cmsgw_MerchTxnRef && (
                            <div className="info">
                                <span>MerchTxnRef</span>
                                <span>{ssOrderInfo.cmsgw_MerchTxnRef}</span>
                            </div>
                        )}
                        {ssOrderInfo?.cmsgw_ReceiptNo && (
                            <div className="info">
                                <span>Receipt No</span>
                                <span>{ssOrderInfo.cmsgw_ReceiptNo}</span>
                            </div>
                        )}
                        {ssOrderInfo?.cmsgw_AuthorizeId && (
                            <div className="info">
                                <span>AuthId</span>
                                <span>{ssOrderInfo.cmsgw_AuthorizeId}</span>
                            </div>
                        )}
                        {ssOrderInfo?.cmsgw_CardNumber && (
                            <div className="info">
                                <span>Paid by Card</span>
                                <span>xxx {ssOrderInfo.cmsgw_CardNumber}</span>
                            </div>
                        )}
                        {ssOrderInfo?.cmsgw_AmountOriginal && (
                            <div className="info">
                                <span>Original Transaction Amount</span>
                                <span>
                                    {ssOrderInfo?.cmsgw_OriginalCurrency ||
                                        ssOrderInfo?.cmsgw_Currency}{" "}
                                    {(+ssOrderInfo.cmsgw_AmountOriginal / 100).toFixed(2)}
                                </span>
                            </div>
                        )}
                        {ssOrderInfo?.cmsgw_AmountOriginal && (
                            <div className="info">
                                <span>Total Sell Price (incl card fee)</span>
                                <span>
                                    {ssOrderInfo?.cmsgw_OriginalCurrency ||
                                        ssOrderInfo?.cmsgw_Currency}{" "}
                                    {(
                                        (+ssOrderInfo.cmsgw_AmountOriginal +
                                            +ssOrderInfo.cmsgw_FeeAmount) /
                                        100
                                    ).toFixed(2)}
                                </span>
                            </div>
                        )}
                        {ssOrderInfo?.cmsgw_DccRate && (
                            <div className="info">
                                <div className="rate">
                                    <span>FX Rate*</span> <p>(include 2.75% margin)</p>
                                </div>
                                <span>
                                    {ssOrderInfo?.cmsgw_OriginalCurrency ||
                                        ssOrderInfo?.cmsgw_Currency}{" "}
                                    1 = {ssOrderInfo?.cmsgw_DccCurrency} {ssOrderInfo.cmsgw_DccRate}
                                </span>
                            </div>
                        )}
                        {ssOrderInfo?.cmsgw_Amount && (
                            <div className="info">
                                <span>Final Transaction Amount</span>
                                <span>
                                    {ssOrderInfo?.cmsgw_DccCurrency || ssOrderInfo?.cmsgw_Currency}{" "}
                                    {ssOrderInfo?.cmsgw_DccAmount ||
                                        (+ssOrderInfo.cmsgw_Amount / 100).toFixed(2)}
                                </span>
                            </div>
                        )}
                        {ssOrderInfo?.cmsgw_DccRate && (
                            <div className="note">
                                *This currency conversion has been provided by Adventium Technology
                                PTY LTD (trading as Freelance Travel). I have been offered a choice
                                of currencies for payment including AUD and agreed to pay in NZD
                            </div>
                        )}
                    </div>
                )}
                {!isShare && (
                    <p className="subHeading2">
                        Please <a onClick={handleClickHere}>click here</a> for your commission
                        payment information. Thank you!
                    </p>
                )}
                {/* {isShare && (
                    <>
                        <div>Payment Link:</div>
                        <StyledShareBox value={redirectUrl} disabled />
                    </> )} */}
            </Main>
            <ListButton>
                {!isShare ? (
                    <>
                        <StyledButton onClick={handleBackToHome}>Back to Home</StyledButton>
                        <StyledBorderButton onClick={handleViewTicket}>
                            View Tickets
                        </StyledBorderButton>
                    </>
                ) : (
                    <StyledButton onClick={handleBackToHome}>Back to Home</StyledButton>
                )}
            </ListButton>
        </Container>
    );
};

const Container = styled.div`
    background-color: #f2f2f2;
    padding-top: 16px;

    ${breakpoint.breakTablet`
        background-color: transparent;
        max-width: 640px;
    `}

    ${breakpoint.breakLarge`
        max-width: 730px;
    `}
`;

const Main = styled.div`
    padding: 22px 21px 30px 21px;
    margin: 0 16px;
    background-color: #fff;

    ${breakpoint.breakTablet`
        margin: 0;
        padding: 43px 98px 1px 98px;
    `}

    .orderSuccessBackground {
        background: linear-gradient(0deg, #ffffff -20.99%, rgba(255, 255, 255, 0.17) 119.75%),
            #f2f2f2;
        height: 78px;
        width: 78px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin: 0 auto;
    }

    .orderSuccess {
        height: 60px;
        width: 60px;
        background-color: ${(props) => props.theme.colors.main};
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }

    .heading {
        font-size: 20px;
        font-weight: 700;
        line-height: 32px;
        text-align: center;
        margin-top: 11px;
        margin-bottom: 19px;
    }

    .subHeading {
        margin-bottom: 30px;
    }

    .subHeading,
    .subHeading2 {
        cursor: pointer;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
    }

    .subHeading2 {
        a {
            color: #4990ef;
        }
    }

    .paymentInfo {
        width: 100%;
        text-align: center;
        margin-bottom: 1rem;
        font-size: 14px;
        p {
            margin-bottom: 0.5em;
        }
        .title {
            font-weight: bold;
        }
        .bookingReference {
            color: ${(props) => props.theme.colors.main};
            font-size: 25px;
            font-weight: bold;
        }
        .date {
            color: ${(props) => props.theme.colors.gray_3};
        }
        .gap {
            margin: 2rem;
        }
        .titleReceipt {
            font-size: 20px;
            font-weight: bold;
            margin: 1em;
        }
        .info {
            display: flex;
            justify-content: space-between;
            margin: 0.5em;
            text-align: left;
            span:last-child {
                text-align: right;
            }
            .rate p {
                font-size: 12px;
                font-weight: small;
                margin-top: 0.5em;
                margin-bottom: 0;
            }
        }
        .note {
            font-size: 12px;
            font-weight: small;
            text-align: left;
            padding: 1em 0.5em;
        }
    }
`;

const ListButton = styled.div`
    padding: 32px 16px 45px 16px;
    background-color: #fff;
    margin-top: 16px;

    ${breakpoint.breakTablet`
        margin-top: 0;
        margin-bottom: 60px;
        padding: 16px 191px 67px 191px;

        button {
            margin-top: 20px;
        }
    `}
`;

// const StyledShareBox = styled.input`
//     background-color: ${(props) => props.theme.colors.gray_6};
//     border: 1px solid ${(props) => props.theme.colors.gray_5};
//     height: 3em;
//     padding: 0.5em;
//     display: block;
//     width: 100%;
//     color: ${(props) => props.theme.colors.gray_2};
//     font-size: 14px;
//     margin-top: 1em;
// `;
