import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledItemAccount = styled(Link)`
    display: flex;
    padding: 32px 0;
    border-bottom: 1px solid ${props => props.theme.colors.gray_5};
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    align-items: center;
    color: ${props => props.theme.colors.black};

    .icon {
        margin-right: 17px;
        display: flex;
        align-items: center;
    }
`;

export const StyledItemAccountLogout = styled.div`
    display: flex;
    padding: 32px 0;
    border-bottom: 1px solid ${props => props.theme.colors.gray_5};
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    align-items: center;
    color: ${props => props.theme.colors.red};

    .icon {
        margin-right: 17px;
        display: flex;
        align-items: center;
    }
`;