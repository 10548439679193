import "date-fns";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";

import { StyledDatePicker, IconDatePicker } from "@components";
interface IProps {
    id?: string;
    label?: string;
    value: Date | string | null;
    onChange(date: Date | null, value?: string | null | undefined): void;
    limitDate?: {
        maxDate: Date | null;
        minDate: Date | null;
    };
    minDateMessage?: string;
    maxDateMessage?: string;
    placeholder?: string;
    name?: string;
}

export function DatePickerCommon(props: IProps) {
    const {
        id,
        label,
        value,
        onChange,
        limitDate,
        minDateMessage = "Date should not be before current date",
        maxDateMessage = "Date should not be in the future",
        placeholder = "Select Date",
    } = props;
    let minDate = limitDate?.minDate;
    if (!minDate) {
        minDate = new Date();
    }
    return (
        <StyledDatePicker>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid direction="column" container justify="space-around">
                    <KeyboardDatePicker
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id={id}
                        label={label}
                        value={value}
                        onChange={onChange}
                        KeyboardButtonProps={{
                            "aria-label": "change date",
                        }}
                        emptyLabel={placeholder}
                        autoOk
                        keyboardIcon={<IconDatePicker />}
                        maxDate={
                            limitDate && limitDate.maxDate
                                ? limitDate.maxDate
                                : new Date("01/01/2100")
                        }
                        minDate={minDate}
                        allowKeyboardControl={false}
                        InputLabelProps={{
                            shrink: false,
                        }}
                        minDateMessage={minDateMessage}
                        maxDateMessage={maxDateMessage}
                    />
                </Grid>
            </MuiPickersUtilsProvider>
        </StyledDatePicker>
    );
}
