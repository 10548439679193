import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ErrorMessage } from "formik";

import { StyledFormControl, StyledError } from "@components";
import { IHandleBlur } from "@interfaces";

interface IProps {
    handleChange: (value?: any) => void;
    handleBlur: IHandleBlur;
    value: {
        id: string | number;
        label: string;
    } | null;
    name: string;
    label: string;
    focus?: boolean;
    defaultValue?: string | Date | null;
    required?: boolean;
    userId?: string | number;
    listValueMembers: {
        id: string | number;
        label: string;
    }[];
}

export const SelectCommon = (props: IProps) => {
    const { name, label, value, handleChange, handleBlur, userId, listValueMembers } = props;
    return (
        <>
            <StyledFormControl>
                <Autocomplete
                    options={listValueMembers}
                    getOptionLabel={(option) => option.label}
                    id={"" + name + userId}
                    fullWidth
                    onChange={handleChange}
                    value={value}
                    renderInput={(params) => (
                        <TextField {...params} label={`${label} *`} margin="normal" />
                    )}
                    onBlur={handleBlur(name)}
                />
            </StyledFormControl>
            <ErrorMessage name={name} component={StyledError} />
        </>
    );
};
