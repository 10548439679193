import styled from "styled-components";
import { Element } from "react-scroll";

export const StyledDescription = styled(Element)`
    padding: 30px 0;
    border-bottom: 1px solid ${props => props.theme.colors.gray_4};

    .heading {
        font-weight: 700;
        font-size: 20px;
        color: ${props => props.theme.colors.black};
        margin-top: 12px;
        margin-bottom: 8px;
        position: relative;
    }

    .content {
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        color: ${props => props.theme.colors.gray_2};
        word-wrap: break-word;
    }

    .wayPoint {
        position: absolute;
        top: 0;
        width: 4px;
        height: 4px;
        background-color: #f00;
        opacity: 0;
    }
`;