import moment from "moment";

export const diffDateCart = (fromDate: Date | string, toDate: Date | string) => {
    const from = moment(fromDate);
    const to = moment(toDate);

    return to.diff(from);
};

export const diffDate = (fromDate: Date | string, toDate: Date | string) => {
    const date1 = new Date(fromDate);
    const date2 = new Date(toDate);
    const Difference_In_Time = date2.getTime() - date1.getTime();
    return Math.floor(Difference_In_Time / (1000 * 3600 * 24));
};
