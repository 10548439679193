import styled from "styled-components";

import { breakpoint } from "@utilities";

interface IProps {
    isActive: boolean;
    onSwitchNeedToKnow: () => void;
    title: string;
}

export const NeedToKnowTagCommon = (props: IProps) => {
    const { isActive, onSwitchNeedToKnow, title } = props;
    return (
        <Container
            className={`${isActive ? "active" : ""}`}
            onClick={onSwitchNeedToKnow}
        >
            {title}
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    font-weight: 700;
    color: ${p => p.theme.colors.gray_3};

    ${breakpoint.breakTablet`
        font-size: 12px;
        line-height: 18px;
    `}
    
    ${breakpoint.breakIpadPro`
        font-size: 15px;
        line-height: 20px;
    `}
    
    ${breakpoint.breakLarge`
        font-size: 18px;
        line-height: 23px;
    `}

    &::after {
        width: 0;
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 5px;
        background-color: ${p => p.theme.colors.main};
    }

    &.active {
        color: #151515;

        &::after {
            width: 100%;
        }
    }
`;