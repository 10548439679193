import { SearchInputItem, StyledFilterDesktop } from "@components";
import { useWindowDimensions } from "@utilities";
interface ITab {
    title: string;
    icon: string;
}

interface IProps {
    title: string;
    listTabs: ITab[];
    nameValue?: string;
    onSetNameValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onkeyup:(e: React.KeyboardEvent<HTMLInputElement>)=> void;
}

export const FilterItemComponent = (props: IProps) => {
    const { title, listTabs, nameValue, onSetNameValue,onkeyup } = props;
    const { width } = useWindowDimensions();
    return (
        <StyledFilterDesktop isSearchResultPage={true} width={width}>
            <div className="searchResultPage">
                <div className="heading">
                    <span className="text">{title}</span>
                </div>
            </div>

            <div className="filterRow">
                <div className="filterItem">
                    <SearchInputItem
                        icon={listTabs[0].icon}
                        onChange={onSetNameValue}
                        value={nameValue}
                        placeholder={listTabs[0].title}
                        onkeyup={onkeyup}
                    />
                </div>
            </div>
        </StyledFilterDesktop>
    );
};
