import axiosClient from "./axiosClient";
import { IForgotPassword, ILogin, ILoginDemo, IAgentInfo } from "@interfaces";

export const authApi = {
    login: (params: ILogin) => {
        const url = "/agentToken";
        return axiosClient.post(url, params);
    },
    loginDemo: (params: ILoginDemo) => {
        const url = "/auth/login";
        return axiosClient.post(url, params);
    },
    forgotPass: (params: IForgotPassword) => {
        const url = "/updatePassword";
        return axiosClient.post(url, params);
    },
    updateAgentInfo: (params: IAgentInfo) => {
        const url = "/updateAgentAccount";
        return axiosClient.post(url, params);
    },
};
