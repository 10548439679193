import axiosClient from "./axiosClient";

export const COUNTRIES_PARAM = "countries";
export const STATES_PARAM = "states";
export const REGIONS_PARAM = "regions";
export const LOCALITY_PARAM = "locality";

export const categoriesApi = {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getCountries: (stateId?: number | string, paramType?: string) => {
        const url = "/categories/countries";
        return axiosClient.get(url);
    },
    getStates: (countriesId?: number | string, paramType?: string) => {
        let url = "/categories/states";
        if (countriesId) {
            url += `?${paramType}=${countriesId}`;
        }
        return axiosClient.get(url);
    },
    getRegions: (stateId?: number | string, paramType?: string) => {
        let url = "/categories/regions";
        if (stateId) {
            url += `?${paramType}=${stateId}`;
        }
        return axiosClient.get(url);
    },
    getTowns: (regionId?: number | string, paramType?: string) => {
        let url = "/categories/locality";
        if (regionId) {
            url += `?${paramType}=${regionId}`;
        }
        return axiosClient.get(url);
    },
    getAccommodation: (id?: number | string, paramType?: string) => {
        let url = "/categories/accommodation";
        if (id && paramType) {
            url += `?${paramType}=${id}`;
        }
        return axiosClient.get(url);
    },
    getActivity: (id?: number | string, paramType?: string) => {
        let url = "/categories/activities";
        if (id && paramType) {
            url += `?${paramType}=${id}`;
        }
        return axiosClient.get(url);
    },
    getTransport: (id?: number | string, paramType?: string) => {
        let url = "/categories/transport";
        if (id && paramType) {
            url += `?${paramType}=${id}`;
        }
        return axiosClient.get(url);
    },
    getSupplier: () => {
        const url = "/suppliers";
        return axiosClient.get(url);
    }
    // getSupplier: (id?: number | string, paramType?: string) => {
    //     let url = "/categories/suppliers";
    //     if (id && paramType) {
    //         url += `?${paramType}=${id}`;
    //     }
    //     return axiosClient.get(url);
    // }
};
// https://freelancetest02.websitetravel.com/apiv1/categories/suppliers
// https://freelancetest02.websitetravel.com/apiv1/categories/transport
// https://freelancetest02.websitetravel.com/apiv1/categories/suppliers?countries=20
// https://freelancetest02.websitetravel.com/apiv1/categories/transport?countries=20
