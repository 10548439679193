import { createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";

const loadingSplice = createSlice({
    name: "loading",
    initialState: {
        isLoading: false,
    },
    reducers: {
        toggleLoading: (state, action) => {
            state.isLoading = action.payload;
        },
    },
});

export const selectLoading = (state: RootState) => state.loading;

export const { toggleLoading } = loadingSplice.actions;
export default loadingSplice.reducer;
