import styled from "styled-components";

import { breakpoint } from "@utilities";

export const StyledPopupCheckAvail = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    padding: 0 16px;

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(9, 10, 24, 0.4);

        /* ${breakpoint.breakTablet`
            max-width: 300px;
        `} */
    }

    .main {
        background-color: #fff;
        z-index: 1;
        width: 100%;
        padding: 62px 22px 48px 22px;

        ${breakpoint.breakTablet`
            max-width: 300px;
        `}

        p {
            text-align: center;
        }

        .fareName {
            font-weight: 700;
            font-size: 16px;
            line-height: 25px;

            &.line2 {
                font-weight: 400;
            }
        }
    }
`;