import styled from "styled-components";
import { breakpoint } from "@utilities";

export const StyledDatePicker = styled.div`
    .MuiInputBase-root {
        background-color: ${(props) => props.theme.colors.gray_6} !important;
        font-size: 16px !important;
        padding: 12px 13px !important;

    }

    .MuiInput-underline:after,
    .MuiInput-underline:before {
        display: none !important;
    }

    .MuiInputBase-input {
        padding: 0;
        font-family: DM Sans;
        color: ${(props) => props.theme.colors.gray_3};
        font-size: 16px;

        ${breakpoint.breakTablet`
            font-size: 12px !important;
        `}

        ${breakpoint.breakIpadPro`
            font-size: 16px !important;
        `}
    }

    .MuiIconButton-label{
        width: 100%;

        ${breakpoint.breakTablet`
            width: 60%;
        `}

        ${breakpoint.breakIpadPro`
            width: 100%;
        `}
    }

    .MuiInputLabel-animated {
        font-size: 16px;
        font-weight: 100;
        color: ${(props) => props.theme.colors.gray_2};
    }

    .MuiInputLabel-formControl {
        top: -26px;
        font-size: 16px;
        ${breakpoint.breakTablet`
            font-size: 12px ;
        `}

        ${breakpoint.breakIpadPro`
            font-size: 16px ;
        `}
    }

    .MuiInputLabel-root.Mui-focused {
        color: ${(p) => p.theme.colors.main};
    }

    .MuiIconButton-root {
        padding: 0 !important;
    }
    label + .MuiInput-formControl {
        margin-top: 24px;
    }
`;
