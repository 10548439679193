import styled from "styled-components";

export const LoginLogo = styled.img`
    width: 300px;
    height: auto;
    object-fit: cover;
    margin-bottom: 20px;
`;

interface IStyledRatioImage {
    img: string;
}

export const StyledRatio12Image = styled.div<IStyledRatioImage>`
   position: relative;
   width: 100%;
   padding-top: 50%;
   background: url(${props => props.img}) no-repeat center;
`;


export const StyledRatio34Image = styled.div<IStyledRatioImage>`
   position: relative;
   width: 100%;
   padding-top: 75%;
   background: url(${props => props.img}) no-repeat center;
   background-size: cover;
`;


export const StyledRatio35Image = styled.div<IStyledRatioImage>`
   position: relative;
   width: 100%;
   padding-top: 63%;
   background: url(${props => props.img}) no-repeat center;
   background-size: cover;
`;