import styled from "styled-components";

import { breakpoint } from "@utilities";

export const StyledSlideBannerMobile = styled.div`
    display: none;

    @media screen and (max-width: ${(props) => props.theme.breakPoints.breakMobileMedium}) {
        display: block;
    }

    .carousel .thumbs-wrapper {
        display: none;
    }

    .carousel.carousel-slider .control-arrow {
        display: none;
    }

    .carousel .carousel-status {
        display: none;
    }

    .carousel .slider-wrapper.axis-horizontal .slider .slide {
        position: relative;
    }

    .carousel .slide .legend {
        position: absolute;
        background-color: transparent;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        color: #fff;
        opacity: 1;
        font-size: 28px;
        font-weight: 700;
        line-height: 36px;
    }

    .carousel .control-dots .dot {
        width: 10px;
        height: 10px;
        background-color: transparent;
        border: 1px solid #fff;
        opacity: 1;

        &.selected {
            background-color: #fff;
        }
    }

    .imgWrapper {
        height: 100%;

        img {
            height: 100%;
        }
    }
`;

export const StyledSlideBannerwebsite = styled(StyledSlideBannerMobile)`
    display: none;

    ${breakpoint.breakTablet`
        display: block;

        .carousel .control-dots {
            bottom: 80px;
        }

        .carousel .slide .legend {
            font-size: 24px;
            line-height: 1em;

            ${breakpoint.breakIpadPro`
                font-size: 36px;
            `}
        }
    `}

    ${breakpoint.breakTablet`
        .carousel .control-dots {
            bottom: 80px;
        }

        .carousel .slide .legend {
            font-size: 64px;
            font-weight: 700;
            line-height: 83px;
            padding: 0 300px;
        }
    `}

    @media (min-width: 1800px) {
        .carousel .slide .legend {
            padding: 0 700px;
            user-select: none;
        }
    }
`;
