import { useDispatch } from "react-redux";

import { HeartCommon } from "@components";
import { ITour } from "@interfaces";
import {
    FAVORITE_METHOD_LIKE,
    FAVORITE_METHOD_UNLIKE,
    selectFavoriteTours,
    toggleFavorites,
    useAppSelector,
} from "@redux";

export const HeartTourModule = (props: { tour: ITour }) => {
    const { tour } = props;
    const dispatch = useDispatch();
    const favoriteTours = useAppSelector(selectFavoriteTours).tours;
    const isLiked = favoriteTours.some(
        (tourItem) => tourItem.productId.toString() === tour.productId.toString()
    );
    const handleLike = () => {
        const method = isLiked ? FAVORITE_METHOD_UNLIKE : FAVORITE_METHOD_LIKE;
        dispatch(
            toggleFavorites(
                {
                    ...tour,
                    date: new Date(),
                },
                method
            )
        );
    };
    return <HeartCommon active={isLiked} onClick={handleLike} />;
};
