import {
    Login,
    Home,
    Term,
    ForgotPassword,
    SearchResult,
    DetailTour,
    CheckAvailability,
    Cart,
    Booking,
    AccountSetting,
    Favorites,
    SavedCart,
    BookingDetail,
    // Accounting,
    MyAccount,
    MyAccountSetting,
    NotFound,
    UpdatePassword,
    ShareOrderSuccess,
    ShareSuccess,
    ShareBookingDetail,
    PaymentWrapper,
    SavedCartDetail,
    OrderSuccess,
} from "@pages";
import { IRoute } from "@interfaces";
import PaymentFail from "src/Pages/PaymentFail";

export const PATH_HOME = "/";

export const PATH_LOGIN = "/login";
export const PATH_TERM = "/term";
export const PATH_FORGOT_PASSWORD = "/forgot-password";
export const PATH_SEARCH_RESULT = "/search-result";
export const PATH_DETAIL_TOUR = "/detail-tour";
export const PATH_CHECK_AVAILABILITY = "/check-availability";
export const PATH_CART = "/cart";
export const PATH_BOOKING = "/booking";
export const PATH_MENU_SETTING = "/menu-setting";
export const PATH_FAVORITES = "/favorites";
export const PATH_SAVED_CART = "/saved-cart";
export const PATH_BOOKING_DETAIL = "/booking-detail";
export const PATH_MY_ACCOUNT = "/my-account";
export const PATH_ACCOUNT = "/account";
export const PATH_UPDATE_PASSWORD = "/update-password";
export const PATH_SHARE_ORDER_SUCCESS = "/share/order-success";
export const PATH_SHARE_SUCCESS = "/share/success";
export const PATH_SHARE_BOOKING_DETAIL = "/share/book-detail";
export const PATH_PAYMENT_WRAPPER = "/payment-wrapper";
export const PATH_ORDER_SUCCESS = "/order/success";
export const PATH_PAYMENT_FAIL = "/paymentfail";

export const authPath = [
    PATH_LOGIN,
    PATH_TERM,
    PATH_FORGOT_PASSWORD,
    PATH_SHARE_ORDER_SUCCESS,
    PATH_SHARE_SUCCESS,
    PATH_SHARE_BOOKING_DETAIL,
    PATH_PAYMENT_FAIL
];

export const normalPath = [
    PATH_DETAIL_TOUR,
    PATH_SEARCH_RESULT,
    PATH_CHECK_AVAILABILITY,
    PATH_CART,
    PATH_BOOKING,
    PATH_ACCOUNT,
    PATH_MENU_SETTING,
    PATH_FAVORITES,
    PATH_BOOKING_DETAIL,
    PATH_SAVED_CART,
    PATH_MY_ACCOUNT,
    PATH_UPDATE_PASSWORD,
    PATH_ORDER_SUCCESS,
];

export const route: Array<IRoute> = [
    { path: PATH_HOME, component: Home, exact: true },
    { path: PATH_SEARCH_RESULT, component: SearchResult, exact: true },
    { path: PATH_DETAIL_TOUR + "/:id", component: DetailTour, exact: true },
    {
        path: PATH_CHECK_AVAILABILITY + "/:id",
        component: CheckAvailability,
        exact: true,
    },
    { path: PATH_CART + "/:idSaveCart", component: Cart, exact: true },
    { path: PATH_CART, component: Cart, exact: true },
    { path: PATH_BOOKING, component: Booking, exact: true },
    { path: PATH_MENU_SETTING, component: AccountSetting, exact: true },
    { path: PATH_FAVORITES, component: Favorites, exact: true },
    { path: PATH_SAVED_CART, component: SavedCart, exact: true },
    { path: PATH_SAVED_CART + "/:id", component: SavedCartDetail, exact: true },
    { path: PATH_BOOKING_DETAIL + "/:id", component: BookingDetail, exact: true },
    { path: PATH_MY_ACCOUNT, component: MyAccountSetting, exact: true },
    { path: PATH_ACCOUNT, component: MyAccount, exact: true },
    { path: PATH_UPDATE_PASSWORD, component: UpdatePassword, exact: true },
    { path: PATH_PAYMENT_WRAPPER, component: PaymentWrapper, exact: true },
    { path: PATH_ORDER_SUCCESS, component: OrderSuccess, exact: true },

    { path: "**", component: NotFound, exact: true },
];

export const auth_route: Array<IRoute> = [
    { path: PATH_LOGIN, component: Login, exact: true },
    { path: PATH_TERM, component: Term, exact: true },
    { path: PATH_FORGOT_PASSWORD, component: ForgotPassword, exact: true },
    { path: PATH_SHARE_SUCCESS + "/:token", component: ShareSuccess, exact: true },
    { path: PATH_SHARE_ORDER_SUCCESS, component: ShareOrderSuccess, exact: true },
    { path: PATH_SHARE_BOOKING_DETAIL + "/:id", component: ShareBookingDetail, exact: true },
    { path: PATH_PAYMENT_WRAPPER, component: PaymentWrapper, exact: true },
    { path: PATH_PAYMENT_FAIL, component: PaymentFail, exact: true },
    { path: "**", component: NotFound, exact: true },

];
