const queryString = require("query-string");
import axios from "axios";

import { IOrderData } from "@interfaces";
import axiosClient from "./axiosClient";

export const bookingApi = {
    getBookingReference: () => {
        const url = "/bookingreference";
        return axiosClient.post(url);
    },
    getPaymentMethod: () => {
        const url = "/agent/paymentmethods";
        return axiosClient.get(url);
    },
    order: (body: IOrderData) => {
        const url = "/order";
        return axiosClient.post(url, body);
    },
    getPaymentGatewayUrl: (
        bookingReference: string,
        paymentAmount: string | number,
        returnUrl: string
    ) => {
        const dataRaw = { showProducts: 1 };
        const url = `pgw/authenticate/${bookingReference}?paymentAmount=${paymentAmount}&returnUrl=${returnUrl}`;
        return axiosClient({
            method: "GET",
            url,
            data: dataRaw,
        });
    },
    getListBooking: () => {
        const url = "/customerOrderDetail?since=9-jun-2020&searchOnlyStatus=Order";
        return axiosClient.get(url);
    },
    getListCustomerOrder: () => {
        const url = "/customerOrderDetail?since=9-jun-2021&searchOnlyStatus=Quote";
        return axiosClient.get(url);
    },
    getBookingDetail: (id: string | number) => {
        const url = `voucher/${id}.json`;
        return axiosClient.get(url);
    },
    getBookingDetailWithToken: (id: string | number, token: string) => {
        const url = `voucher/${id}.json`;
        const axiosClientBookingDetail = axios.create({
            baseURL: process.env.REACT_APP_API_URL,

            headers: {
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${token}`,
            },

            paramsSerializer: (params) => queryString.stringify(params),
        });
        return axiosClientBookingDetail.get(url);
    },
    convertQuoteToOrder: (bookingReference: string) => {
        const url = `/order/${bookingReference}/convertQuote`;
        return axiosClient.post(url);
    },
    convertQuoteToOrderWithToken: (bookingReference: string, token: string) => {
        const url = `/order/${bookingReference}/convertQuote`;
        const axiosClientConvertQuote = axios.create({
            baseURL: process.env.REACT_APP_API_URL,

            headers: {
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${token}`,
            },

            paramsSerializer: (params) => queryString.stringify(params),
        });
        return axiosClientConvertQuote.post(url);
    },
};
