import { Fragment, useEffect, useState } from "react";
import styled from "styled-components";

import { LoadingCommon, StyledBanner, StyledVersion } from "@components";
import { AppModuleLayout } from "@layouts";
import { PromoTourModule, SearchModule, TourElementModule } from "@module";
import { selectExternal, selectFavoriteTours, useAppSelector } from "@redux";
import { breakpoint, storageVersion } from "@utilities";
import { setTimeout } from "timers";

const appVersion = storageVersion.getItem();
export const Home = () => {
    //page redux
    const homepage = useAppSelector(selectExternal).homepage;
    const favoritesTour = useAppSelector(selectFavoriteTours).tours;
    //page state
    const [isIncreasePaddingTop, setIsIncreasePaddingTop] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(true);
        }, 1000);
    }, []);

    const handleChangeIsIncreasePaddingTop = () => {
        setIsIncreasePaddingTop(!isIncreasePaddingTop);
    };
    if (!isLoading) {
        return <LoadingCommon />;
    }
    return (
        <AppModuleLayout>
            <Container>
                <StyledBanner>
                    <div className="bannerMobile">
                        <img src="/images/banner/Banner-home-50.png" alt="" />
                        <h2 className="heading">Start selling travel & earn commission fast</h2>
                    </div>
                    <div className="bannerDesktop">
                        <img src="/images/website/banner/banner1.png" alt="" />
                        <h2 className="heading">Start selling travel & earn commission fast</h2>
                    </div>
                </StyledBanner>
                <div className="search">
                    <SearchModule onChangeIsIncreasePaddingTop={handleChangeIsIncreasePaddingTop} />
                </div>
                <div className="listDestinations">
                    {homepage.map((products, index) => {
                        const { categoryName, categoryProducts } = products;
                        return (
                            <Fragment key={index}>
                                <h2 className="topDestinations">{categoryName}</h2>
                                {categoryProducts.map((tour) => {
                                    return <TourElementModule data={tour} key={tour.productId} />;
                                })}
                            </Fragment>
                        );
                    })}
                </div>
                {favoritesTour?.length > 0 && (
                    <div className="listDestinations">
                        <h2 className="topDestinations">My Favourites</h2>
                        {favoritesTour.slice(0, 4).map((tour) => {
                            return <TourElementModule data={tour} key={tour.productId} />;
                        })}
                    </div>
                )}

                <div
                    className="listDestinationsWebsite"
                    style={{ paddingTop: isIncreasePaddingTop ? "140px" : "" }}
                >
                    {homepage.map((products, index) => {
                        const { categoryName, categoryProducts } = products;
                        return (
                            <Fragment key={index}>
                                <h2 className="topDestinations">{categoryName}</h2>
                                <PromoTourModule data={categoryProducts} />
                            </Fragment>
                        );
                    })}
                </div>
                {favoritesTour?.length > 0 && (
                    <div className="listDestinationsWebsite">
                        <h2 className="topDestinations">My Favourites</h2>
                        <PromoTourModule data={favoritesTour.slice(0, 4)} type="favorite" />
                    </div>
                )}

                <StyledVersion>{appVersion}</StyledVersion>
            </Container>
            {/* {isShowWelComePopup ? <WelcomePopupModule onClosePopup={handleClosePopup} /> : <></>} */}
        </AppModuleLayout>
    );
};

const Container = styled.div`
    background-color: ${(props) => props.theme.colors.gray_6};
    width: 100vw;
    padding-bottom: 60px;

    .search {
        position: relative;

        ${breakpoint.breakTablet`
            z-index: 2;
        `}/* ${breakpoint.breakOnlyMobile`
            z-index: 2;
        `} */
    }

    .topDestinations {
        line-height: 36.46px;
        font-size: 28px;
        font-weight: 700;
        text-align: center;
        margin-top: 80px;
        color: ${(props) => props.theme.colors.black};
        margin-bottom: 36px;
    }

    .listDestinations {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 16px 30px 16px;
    }

    .listDestinationsWebsite {
        display: none;
    }

    ${breakpoint.breakTablet`
        padding-bottom: 0px;
        .listDestinations {
            display: none;
        }

        .listDestinationsWebsite {
            display: block;
            padding-bottom: 60px;
        }

        .topDestinations {
            margin-top: 60px;

            &.topTour {
                margin-top: 80px;
            }
        }
    `}

    @media (min-width: 1110px) {
        .topDestinations {
            margin-top: 75px;

            &.topTour {
                margin-top: 80px;
            }
        }
    }
`;
