import { Link } from "react-router-dom";

import styled from "styled-components";
import { StyledButton } from "@components";
import { PATH_SEARCH_RESULT } from "@routes";

interface IProps {
    onClickFilter: () => void;
}

export const SearchMobileComponent = (props: IProps) => {
    const { onClickFilter } = props;
    return (
        <Container>
            <div className="searchBox">
                <div className="input">
                    <Link to={PATH_SEARCH_RESULT} className="icon">
                        <img src="/images/fi_search.png" alt="" />
                    </Link>
                    <input type="text" className="searchInput" placeholder="Search by keyword" />
                </div>
                <button className="filterBtn" onClick={onClickFilter}>
                    <img src="/images/fi_sliders.png" alt="" />
                    <span>Filter</span>
                </button>
            </div>
            <div className="searchBtn">
                <Link to={PATH_SEARCH_RESULT}>
                    <SearchButton>
                        Search
                    </SearchButton>
                </Link>
            </div>
        </Container>
    );
};

const Container = styled.div`
    background-color: #fff;

    .searchBox {
        display: flex;
        border-bottom: 1px solid ${props => props.theme.colors.gray_5};

        .input {
            flex: 10;
            padding: 16px;
            display: flex;
            border-right: 1px solid ${props => props.theme.colors.gray_5};

            .icon {
                width: 54px;
                height: 54px;
                background-color: ${props => props.theme.colors.gray_6};
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .searchInput {
                background-color: ${props => props.theme.colors.gray_6};
                flex: 1;

                &::placeholder {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22px;
                    color: ${props => props.theme.colors.gray_3};
                }
            }
        }

        .filterBtn {
            flex: 3;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            span {
                font-size: 16px;
                font-weight: 700;
                line-height: 26px;
                color: ${props => props.theme.colors.black};
            }
        }
    }

    .searchBtn {
        padding: 24px 16px;
    }
`;

const SearchButton = styled(StyledButton)`
    margin-top: 0;
`;