import styled from "styled-components";


export const StyledBookingTimeError = styled.div`
    background-color: ${(props) => props.theme.colors.gray_5_5};
    padding: 1em 1.5em;
    font-size: 14px;
    color: ${(props) => props.theme.colors.gray_3};
    text-align: center;
    font-weight: 600;
    line-height: 1.5em;
`;
