import { createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";

const initialState = {
    isSplash: window.localStorage.getItem("splash") ? false : true
};

const splashSlice = createSlice({
    name: "splash",
    initialState: initialState,
    reducers: {
        toggleSplash: (state, action) => {
            state.isSplash = action.payload;
        }
    }
});

export const selectSplash = (state: RootState) => state.splash;

export const { toggleSplash } = splashSlice.actions;

export default splashSlice.reducer;