import styled from "styled-components";
import { useDispatch } from "react-redux";
import { Formik, FormikHelpers } from "formik";
import { useHistory } from "react-router-dom";

import { breakpoint, logger } from "@utilities";
import { IAccountInfo, IAgentInfo } from "@interfaces";
import { accountSchema } from "@validations";
import { AccountComponent } from "@components";
import { authApi } from "@api";
import { PATH_HOME, PATH_LOGIN } from "@routes";
import { openToast, selectAgent, selectAuth, setAgentInfo, useAppSelector } from "@redux";
import { phpHelper } from "src/Utilities/phpHelper";
import { DURATION_TIME } from "@constants";

// const initialValues: IAccountInfo = {
//     firstName: "",
//     lastName: "",
//     emailAddress: "",
//     bankCountryShortCode: "",
//     businessnumber: "",
//     tradingname: "",
//     bankBsb: "",
//     bankAccount: "",
// };

export const MyAccount = () => {
    //hooks
    const dispatch = useDispatch();
    const history = useHistory();
    //page redux
    const { auth } = useAppSelector(selectAuth);
    const { agentInfo } = useAppSelector(selectAgent);
    //page state
    const token = auth?.access_token;

    if (agentInfo && agentInfo.firstName !== "") {
        history.push(PATH_HOME);
    }

    const handleSubmit = async (
        values: IAccountInfo,
        { setSubmitting }: FormikHelpers<IAccountInfo>
    ) => {
        const { tradingname, bankAccount, bankBsb, businessnumber } = values;

        if (!token) {
            history.push(PATH_LOGIN);
        }
        // WHAT: call api update
        try {
            const agentInfoParams: IAgentInfo = {
                tradingname,
                bankAccount,
                bankBsb,
                businessnumber,
            };
            await authApi.updateAgentInfo(agentInfoParams);

            logger.agentInfo(agentInfoParams);
            // WHAT: save data to redux
            dispatch(setAgentInfo(values));
            await phpHelper.saveAccount(values);
            history.push(PATH_HOME);
        } catch (error: any) {
            const message = error?.response?.data?.message || "Somethings went wrong";
            dispatch(
                openToast({
                    type: "error",
                    message: message,
                    autoHideDuration: DURATION_TIME,
                })
            );
            logger.error("Update agent info", message);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Container>
            <Settings>
                <h2 className="title">Congratulations on your new Freelance Travel Account! </h2>
                <p className="desc">
                    Before you begin selling please complete the following details so we can pay
                    your commission:
                </p>
                <FormContainer>
                    <Formik
                        initialValues={agentInfo}
                        validationSchema={accountSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
                            return (
                                <AccountComponent
                                    values={values}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    handleSubmit={handleSubmit}
                                    isSubmitting={isSubmitting}
                                />
                            );
                        }}
                    </Formik>
                </FormContainer>

                <br />
                <div className="desc">
                    Note:
                    <ul>
                        <li>
                            <span>*</span> BSB must be in the format 999-999
                        </li>
                        <li>
                            <span>*</span> Account Number must be greater than or equal to 6 but
                            less than 9 digits for AU, less than 10 for NZ and less than 20 for
                            other countries.
                        </li>
                    </ul>
                </div>
            </Settings>
        </Container>
    );
};

const Container = styled.div`
    background: url("/images/backgroundAccountSetting.svg");
    width: 100vw;
    min-height: 100vh;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Settings = styled.div`
    width: 77.1%;
    background: #ffffff;
    padding: 85px 95px 70px 95px;
    font-family: DM Sans;
    margin: 100px 0;

    .title {
        color: #000000;
        font-weight: bold;
        font-style: normal;
        ${breakpoint.breakSmall`
        font-size: 20px;
        margin-bottom: 15px;
        line-height: 25px;
        `}
        ${breakpoint.breakIpadPro`
        font-size: 26px;
        margin-bottom: 8px;
        line-height: 30px;
        `}
        ${breakpoint.breakTablet`
        font-size: 24px;
        `}
    }
    .desc {
        color: #333333;
        font-style: normal;
        font-weight: normal;
        line-height: 21px;
        font-size: 16px;
        margin-bottom: 40px;
        ${breakpoint.breakSmall`
        font-size: 14px;
        `}
        ${breakpoint.breakIpadPro`
        margin-bottom: 27px;
        `}
        ${breakpoint.breakTablet`
        font-size: 16px;
        `}
        li {
            font-size: 14px;
            span {
                color: red;
            }
        }
    }
    .title__account {
        line-height: 26px;
        font-weight: bold;
        ${breakpoint.breakSmall`
        font-size: 18px;
        margin-top: 30px;
        margin-bottom: 15px;
        `}
        ${breakpoint.breakIpadPro`
        margin-bottom: 24px;
        margin-top: 30px;
        font-size: 20px;
        `}
    }
    label + .MuiInput-formControl {
        ${breakpoint.breakSmall`
        margin-bottom: 15px;
        `}
        ${breakpoint.breakIpadPro`
        margin-bottom: 38px;
        `}
    }
    form div {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .sub_btn {
        width: 100%;
        justify-content: center;
        margin-top: 10px;
        .submit {
            background-color: ${(props) => props.theme.colors.main};
            font-weight: bold;
            font-size: 16px;
            color: #ffffff;
            ${breakpoint.breakSmall`
            padding: 10px 64px;

            `}
            ${breakpoint.breakMobile`
            padding: 10px 75px;

           `}
           ${breakpoint.breakIpadPro`
              padding: 14px 145px;
           `}
        }
    }
    ${breakpoint.breakOnlyMobile`
           width:100vw;
           height: 100vh;
           overflow-y: scroll;
           padding: 65px 75px 54px 75px;
           margin:0 !important;
          .MuiFormControl-root {
            width: 100% !important;
          }
          .fName_Lname {
             width:100%;
             display:flex;
             justify-content:space-between;
            .MuiFormControl-root {
                width: 48% !important;
                margin: 0;
          }
          }
          .MuiTextField-root  {
            margin-right: 0;
            margin-left: 0;
           }
    `}
    ${breakpoint.breakSmall`
        padding: 30px 35px 20px 30px;
        .MuiFormLabel-root {
            font-size: 14px;
        }
    `}
    ${breakpoint.breakTablet`
          width: 77.1%;
          /* max-height: 80vh; */
        `}
    ${breakpoint.breakIpadPro`
           /* max-height: 90vh; */
           overflow-y: none;
           padding: 70px 95px 65px 95px;
           .MuiFormLabel-root {
            font-size: 16px;
           }
    `}
    .MuiTextField-root {
        margin: 0 !important;
    }
`;

const FormContainer = styled.div``;
