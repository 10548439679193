import styled from "styled-components";

interface IProps {
    children?: any;
    onClick: () => void;
    disabled?: boolean;
}

export const PointCommon = (props: IProps) => {
    const { children, onClick, disabled } = props;
    return (
        <Container disabled={disabled} onClick={onClick}>
            {children}
        </Container>
    );
};

const Container = styled.button<IProps>`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: ${(props) => (props.disabled ? "inherit" : "pointer")};
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${(props) =>
        props.disabled ? props.theme.colors.main_disable : props.theme.colors.main};
    text-align: center;
    line-height: 20px;
    font-weight: 700;
    color: #fff;
    font-size: 16px;
`;
