import { createSlice } from "@reduxjs/toolkit";

import { IStatusApp } from "@interfaces";
import { RootState } from ".";

const initialState : IStatusApp = {
    status:true
};

const status = createSlice({
    name: "status",
    initialState: initialState,
    reducers: {
        toggleStatus: (state) => {
            state.status = !state.status;
        }
    }
});

export const selectStatusApp = (state: RootState) => state.status;

export const { toggleStatus } = status.actions;

export default status.reducer;
