import { useState } from "react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";

import { 
    SendEmailComponent, 
    ResetPassComponent, 
    LoginLogo 
} from "@components";
import { IForgotPass, IResetPassword } from "@interfaces";
import { forgotPassSChema, resetPassSchema } from "@validations";
import { toggleLoading } from "@redux";
import { authApi } from "@api";


export function ForgotPassword() {
    const SEND_EMAIL_SCREEN = 'send_email_screen';
    const RESET_PASSWORD_SCREEN = 'reset_password_screen';

    const [screen, setScreen] = useState(SEND_EMAIL_SCREEN);
    const dispatch = useDispatch();
    const initialValuesEmail: IForgotPass = {
        email: "maianh.truong@adamodigital.com"
    };
    const initialValuesPassword: IResetPassword = {
        password: "",
        confirmPassword: ""
    };
    return (
        <>
            <LoginLogo src="/images/logos/FreelanceTravel_Primary_Logo_(1).svg" />
            {screen === SEND_EMAIL_SCREEN ? (
                <Formik
                    initialValues={initialValuesEmail}
                    validationSchema={forgotPassSChema}
                    onSubmit={() => {
                        //chưa có api forgot
                        setScreen(RESET_PASSWORD_SCREEN);
                    }}
                >
                    {({
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                    }) => {
                        return (
                            <SendEmailComponent
                                values={values}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                handleSubmit={handleSubmit}
                                isSubmitting={isSubmitting}
                            />
                        );
                    }}
                </Formik>
            ) : (
                <Formik
                    initialValues={initialValuesPassword}
                    validationSchema={resetPassSchema}
                    onSubmit={  async (values) => {
                        const password = {
                            password:values.password,
                        };
                        try {
                            dispatch(toggleLoading(true));
                            await authApi.forgotPass(password);
                            setScreen(RESET_PASSWORD_SCREEN);
                        } catch (err : any) {
                            // console.log(err);
                        } finally {
                            dispatch(toggleLoading(false));
                        }
                    }}
                >
                    {({
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                    }) => {
                        return (
                            <ResetPassComponent
                                values={values}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                handleSubmit={handleSubmit}
                                isSubmitting={isSubmitting}
                            />
                        );
                    }}
                </Formik>
            )}
        </>
    );
};