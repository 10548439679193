import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IAuth, ILogin } from "@interfaces";
import { authApi } from "@api";
import { RootState } from ".";

export const login = createAsyncThunk<IAuth, ILogin>(
    "auth/login",
    async (values: ILogin, { rejectWithValue }) => {
        try {
            const res = await authApi.login(values);
            return res.data as IAuth;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);

interface IState {
    auth: IAuth | null;
    isLoading: boolean;
    error: string;
    tokenInfoAuth: string;
}

const initialState: IState = {
    auth: null,
    isLoading: false,
    error: "",
    tokenInfoAuth: "",
};

const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        logout: () => {
            return initialState;
        },
        setTokenInfoAuth: (state, action: PayloadAction<string>) => {
            state.tokenInfoAuth = action.payload;
        },
        setAuth: (state, action: PayloadAction<IAuth>) => {
            state.auth = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(login.fulfilled, (state, { payload }: PayloadAction<IAuth>) => {
            if (payload.access_token) {
                state.auth = payload;
                state.auth.expires_in = payload.expires_in * 1000 + new Date().getTime();
            }
            state.isLoading = false;
        });

        builder.addCase(login.pending, (state) => {
            state.isLoading = true;
        });

        builder.addCase(login.rejected, (state) => {
            state.auth = null;
            state.isLoading = false;
        });
    },
});

export const selectAuth = (state: RootState) => state.auth;

export const { logout, setTokenInfoAuth, setAuth } = authSlice.actions;

export default authSlice.reducer;
