import Switch from "react-switch";
import styled from "styled-components";

import { IconDollar } from "@components";

interface ISwitchCommon {
    isChecked: boolean;
    setIsChecked: ()=> void;
    color?: string;
}

export const SwitchCommon = (props: ISwitchCommon) => {
    const { isChecked, setIsChecked, color } = props;
    return (
        <StyledSwitch
            checked={isChecked}
            onChange={setIsChecked}
            onHandleColor="#fff"
            onColor={color ?? "#188387"}
            checkedIcon={
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                    }}
                >
                    <IconDollar />
                </div>
            }
        />
    );
};

const StyledSwitch = styled(Switch)`
    .react-switch-handle {
        height: 20px !important;
        width: 20px !important;
        top: 4px !important;
        right: 33px !important;
    }
`;
