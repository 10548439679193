import { SortBy } from "@interfaces";

export const COUNTRIES = "countries";
export const STATES = "states";
export const REGIONS = "regions";
export const LOCALITIES = "localities";
export const ACCOMMODATION = "accommodation";
export const ACTIVITIES = "activities";
export const TRANSPORTS = "transports";
export const SUPPLIERS = "suppliers";
export const LENGTH_OF_TRIP = "duration";

export const filterConstants = {
    COUNTRIES,
    STATES,
    REGIONS,
    LOCALITIES,
    ACCOMMODATION,
    ACTIVITIES,
    TRANSPORTS,
    SUPPLIERS,
    LENGTH_OF_TRIP,
};

export const SORT_NAME_ASC= "A - Z";
export const SORT_NAME_DESC= "Z - A";
export const SORT_PRICE_ASC= "L - H";
export const SORT_PRICE_DESC= "H - L";

export const SORT_ITEMS: SortBy[] = [
    {
        key: "Name",
        value: [
            {
                title: SORT_NAME_ASC,
                value: "asc",
            },
            {
                title: SORT_NAME_DESC,
                value: "desc",
            },
        ],
    },
    {
        key: "Price",
        value: [
            {
                title: SORT_PRICE_ASC,
                value: "asc",
            },
            {
                title: SORT_PRICE_DESC,
                value: "desc",
            },
        ],
    },
];

export const FILTER_BOOKING = {
    BY_BOOKING_DATE: "BY_BOOKING_DATE",
};
