import styled from "styled-components";

import { breakpoint } from "@utilities";

export const StyledForm = styled.form`
    max-width: 350px;
    ${breakpoint.breakLarge`
        max-width: unset;
        width: 350px;
    `}
`;
