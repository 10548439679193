export const IconCart = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            d="M20 23C20.5523 23 21 22.5523 21 22C21 21.4477 20.5523 21 20 21C19.4477 21 19 21.4477 19 22C19 22.5523 19.4477 23 20 23Z" 
            stroke="#333333" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"/>
        <path 
            d="M9 23C9.55228 23 10 22.5523 10 22C10 21.4477 9.55228 21 9 21C8.44772 21 8 21.4477 8 22C8 22.5523 8.44772 23 9 23Z" 
            stroke="#4F4F4F" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"/>
        <path 
            d="M1 2H5L7.68 15.39C7.77144 15.8504 8.02191 16.264 8.38755 16.5583C8.75318 16.8526 9.2107 17.009 9.68 17H19.4C19.8693 17.009 20.3268 16.8526 20.6925 16.5583C21.0581 16.264 21.3086 15.8504 21.4 15.39L23 7" 
            stroke="#4F4F4F" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"/>
        <path 
            d="M14.0909 2L14.0909 11" 
            stroke="#4F4F4F" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"/>
        <path 
            d="M17 8L14 11L11 8" 
            stroke="#4F4F4F" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"/>
    </svg>

);
