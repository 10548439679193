import { createSlice } from "@reduxjs/toolkit";

import { ITourPromotes } from "@interfaces";
import { RootState } from ".";

interface IExternalData {
    homepage: ITourPromotes[];
    time?: number;
    date?: number
}

const initialState: IExternalData = {
    homepage: [],

};

const externalData = createSlice({
    name: "externalData",
    initialState: initialState,
    reducers: {
        setHomepage: (state, { payload }: { payload: IExternalData }) => {
            state.homepage = payload.homepage;
            const newDate = new Date();
            state.time = newDate.getTime();
            state.date = newDate.getDate();
        },
    },
});

export const selectExternal = (state: RootState) => state.externalData;

export const { setHomepage } = externalData.actions;
export default externalData.reducer;
