import styled from "styled-components";

import { breakpoint } from "@utilities";

interface IProps {
    active?: boolean;
    disabled?: boolean;
}

export const StyledPagePoint = styled.div<IProps>`
    background-color: ${(props) => (props.active ? props.theme.colors.main : "#fff")};
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => (props.active ? "#fff" : "#000")};
    margin-left: 10px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    font-size: 10px;
    opacity: ${p => p.disabled ? "0.4" : "1"};

    ${breakpoint.breakIpadPro`
        width: 36px;
        height: 36px;
        font-size: 12px;
        margin-left: 18px;
    `}

    ${breakpoint.breakLarge`
        width: 47px;
        height: 47px;
        font-size: 14px;
        margin-left: 18px;
    `}
`;

