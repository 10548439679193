export const sliceString = (stringInput: string, wordNumber: number) => {
    const strArr = stringInput.split(" ");
    const newStrArr: string[] = [];
    const tail = "...";
    for (let i = 0; i < strArr.length ; i++) {
        newStrArr.push(strArr[i]);
        if (i >= wordNumber - 1) {
            break;
        }
    }
    if (wordNumber < strArr.length) {
        return newStrArr.join(" ") + tail;
    }
    return newStrArr.join(" ");
};