import styled from "styled-components";

import { breakpoint } from "@utilities";

export const StyledFavoritesPage = styled.div`
    background-color: #F2F2F2;
    min-height: calc(100vh - 95px);

    .wrapper {
        display: block;
        margin: 0 auto;
        position: relative;

        ${breakpoint.breakTablet`
            display: flex;
            width: ${(p) => p.theme.widths.wide_700};
            margin-top: 81px;
            padding-bottom: 20px;
        `}

            ${breakpoint.breakIpadPro`
            width: ${(p) => p.theme.widths.wide_900};
        `}

        ${breakpoint.breakLarge`
            width: ${(p) => p.theme.widths.wide_1110};
        `}

    .breadCrumbs {
            position: absolute;
            bottom: calc(100% + 30px);
            display: none;

            ${breakpoint.breakTablet`
            display: block;
        `}
        }

        .menu {
            display: none;

            ${breakpoint.breakTablet`
            display: block;
        `}
        }
    }

    .listProduct {
        padding: 24px 16px;
        background-color: #F2F2F2;
        position: relative;
        top: 12px;
        margin-bottom: 12px;

        ${breakpoint.breakTablet`
        flex: 1;
        padding: 0;
    `}

        .heading1 {
            font-size: 28px;
            font-weight: 700;
            line-height: 36px;
            margin-bottom: 30px;
            margin-top: -10px;
            display: none;

            ${breakpoint.breakTablet`
            display: block;
        `}
        }

        .destinationWrapper {
            margin-bottom: 30px;
        }
    }

    .pagination {
        margin: auto;

        ${breakpoint.breakTablet`
            width: ${p => p.theme.widths.wide_700};
        `}

        ${breakpoint.breakIpadPro`
            width: ${p => p.theme.widths.wide_900};
        `}

        ${breakpoint.breakLarge`
            width: ${p => p.theme.widths.wide_1110};
        `}
    }
`;
