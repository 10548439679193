import DotLoader from "react-spinners/DotLoader";
import ClipLoader from "react-spinners/ClipLoader";
import styled from "styled-components";

interface ILoaderProps {
    size: number;
    color: string;
    margin: number;
}

export const DotLoaderCommon = (props: ILoaderProps) => {
    return (
        <StyledLoader margin={props.margin} className="clip-loader">
            <DotLoader color={props.color} size={props.size} />
        </StyledLoader>
    );
};

export const ClipLoaderCommon = (props: ILoaderProps) => {
    return (
        <StyledLoader margin={props.margin} className="clip-loader">
            <ClipLoader color={props.color} size={props.size} />
        </StyledLoader>
    );
};

const StyledLoader = styled.div<{ margin: number }>`
    display: flex;
    justify-content: center;
    margin: ${(props) => `${props.margin}px`} auto;
    flex: 1;
`;
