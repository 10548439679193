import styled from "styled-components";

import {
    ICountry,
    ICountryData,
    ICustomerInfo,
    IHandleBlur,
    IHandleChange,
    IHandleSubmit,
} from "@interfaces";
import {
    InputCommon,
    StyledButton,
    SelectCommon,
    DatePickerCommon,
    StyledError,
} from "@components";
import { breakpoint } from "@utilities";

interface IProps {
    values: ICustomerInfo;
    handleChange: IHandleChange;
    handleBlur: IHandleBlur;
    handleSubmit: IHandleSubmit;
    isSubmitting: boolean;
    userId?: number | string;
    dateValue: Date | null | string;
    onResetCustomer: () => void;
    onRemoveCustomer: () => void;
    countriesData: ICountryData[];
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    handleChangeDate: (date: Date | null, value?: string | null | undefined) => void;
    isLeader?: boolean;
    isShowErrorDate: boolean;
}

export const CustomerDetailFormComponent = (props: IProps) => {
    const {
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        userId,
        dateValue,
        onResetCustomer,
        onRemoveCustomer,
        countriesData,
        setFieldValue,
        handleChangeDate,
        isLeader,
        isShowErrorDate,
    } = props;
    const countriesModel = countriesData.map((item) => {
        return {
            id: item.countryCode,
            label: item.name,
        };
    });

    const handleChangeCountry = (
        e: React.ChangeEvent<HTMLInputElement>,
        value?: ICountry | null
    ) => {
        setFieldValue("country", value);
    };

    return (
        <Container onSubmit={handleSubmit}>
            <div className="nameWrapper">
                <div className="firstName">
                    <InputCommon
                        name="firstName"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.firstName}
                        label="First name *"
                        type="text"
                        required
                        userId={userId}
                    />
                </div>
                <div className="lastName">
                    <InputCommon
                        name="lastName"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        required
                        value={values.lastName}
                        label="Last name *"
                        type="text"
                        userId={userId}
                    />
                </div>
            </div>
            {/* <InputCommon
                name="dateOfBirth"
                handleChange={handleChange}
                handleBlur={handleBlur}
                required
                value={values.dateOfBirth}
                label="Date of birth*"
                type="date"
                userId={userId}
            /> */}
            <DatePickerCommon
                label="Date of birth *"
                value={dateValue}
                onChange={(date: Date | null) => handleChangeDate(date)}
                limitDate={{ maxDate: new Date(), minDate: new Date("1/1/1000") }}
                placeholder="DD/MM/YYYY"
            />
            {!dateValue && isShowErrorDate &&  <StyledError>This field is required!</StyledError>}
            <InputCommon
                name="phone"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.phone}
                required
                label="Phone *"
                type="text"
                userId={userId}
            />
            <InputCommon
                name="email"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.email}
                label="Email *"
                required
                type="text"
                userId={userId}
            />
            <InputCommon
                name="postCode"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.postCode}
                label="Post code *"
                required
                type="text"
                userId={userId}
            />
            <SelectCommon
                name="country"
                handleChange={handleChangeCountry}
                handleBlur={handleBlur}
                value={values.country}
                label="Country"
                required
                userId={userId}
                listValueMembers={countriesModel}
            />

            <div className="removeNRefresh">
                <div className="btnRemove" onClick={onRemoveCustomer}>
                    {!isLeader && (
                        <>
                            <img src="/images/fi_trash-2_red.svg" alt="fi_trash-2_red" />
                            <span>Remove</span>
                        </>
                    )}
                </div>
                <div className="btnRefresh" onClick={onResetCustomer}>
                    <img src="/images/fi_refresh-ccw.svg" alt="fi_refresh-ccw" />
                    <span>Refresh</span>
                </div>
            </div>

            <div className="saveBtn">
                <StyledButton disabled={isSubmitting} type="submit">
                    Save
                </StyledButton>
            </div>
        </Container>
    );
};

const Container = styled.form`
    padding: 0 28px;
    /* overflow-y: scroll; */

    .nameWrapper {
        display: flex;

        .firstName,
        .lastName {
            flex: 1;
        }

        .firstName {
            margin-right: 16px;
        }
    }

    .removeNRefresh {
        display: flex;
        justify-content: space-between;
        margin-top: 19px;

        span {
            font-size: 16px;
            font-weight: 700;
            line-height: 26px;
        }

        .btnRemove,
        .btnRefresh {
            display: flex;
            align-items: center;

            span {
                margin-left: 13px;
            }
        }

        .btnRemove {
            color: ${(p) => p.theme.colors.red};

            ${breakpoint.breakTablet`
                opacity: 0;
                visibility: hidden;
            `}
        }

        .btnRefresh {
            color: ${(props) => props.theme.colors.black};
            cursor: pointer;
        }
    }

    .saveBtn {
        ${breakpoint.breakIpadPro`
            max-width: 213px;
            margin: 0 auto;
        `}
    }
    .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal {
        width: 100% !important;
    }
    .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedEnd {
        background-color: transparent !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.42);
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd {
        color: rgba(0, 0, 0, 1.87) !important;
    }
    .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiFormLabel-filled {
        color: #4f4f4f;
        line-height: 160%;
        font-weight: 100;
        font-size: 16px;
        font-family: DM Sans !important;
    }
    .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd {
        font-size: 14px;
    }
`;
