import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
const queryString = require("query-string");

import { LoadingCommon } from "@components";
import {
    getListBooking,
    openToast,
    selectAgent,
    setCurrentStep,
    setStep,
    useAppSelector,
} from "@redux";
import { CartConstants, DURATION_TIME } from "@constants";
import { lsBookingReference, ssOrder } from "@utilities";
import { PATH_CART, PATH_HOME, PATH_PAYMENT_FAIL } from "@routes";

export const OrderSuccess = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { agentInfo } = useAppSelector(selectAgent);

    //payment success => return url
    useEffect(() => {
        // What: Lưu initialValues cho arr email đã đang nhập

        if (window.location.search) {
            //get url return param
            const params = window.location.search.substring(1);

            let message = `Payment failed. Please contact support@freelancetravel.com for manual payment.`;
            const paramsObj = queryString.parse(params);
            if (paramsObj.status && paramsObj.status === "1") {
                //return order success
                dispatch(setCurrentStep(CartConstants.ORDER_SUCCESS));
                dispatch(setStep(CartConstants.ORDER_SUCCESS));
                dispatch(getListBooking());

                //get booking reference id
                const index = paramsObj.cmsgw_OrderInfo.indexOf("API_");
                const bookingReference = paramsObj.cmsgw_OrderInfo.slice(index + 4);

                lsBookingReference.setItem(bookingReference);
                ssOrder.setItem(paramsObj);
            } else if (paramsObj.error) {
                //return false
                if (agentInfo) {
                    dispatch(setCurrentStep(CartConstants.PAYMENT));
                    dispatch(setStep(CartConstants.PAYMENT));
                    message = paramsObj.error;
                    dispatch(
                        openToast({
                            type: "error",
                            message,
                            autoHideDuration: DURATION_TIME,
                        })
                    );
                    // logger.error("Payment", message);
                } else {
                    history.push(PATH_PAYMENT_FAIL);
                }
            } else {
                dispatch(setCurrentStep(CartConstants.PAYMENT));
                dispatch(setStep(CartConstants.PAYMENT));
                dispatch(
                    openToast({
                        type: "error",
                        message,
                        autoHideDuration: DURATION_TIME,
                    })
                );
                // logger.error("Payment", message);
            }

            history.push(PATH_CART);
        } else {
            history.push(PATH_HOME);
        }
    }, []);

    return <LoadingCommon />;
};
