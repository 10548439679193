import InfiniteScroll from "react-infinite-scroll-component";
import { useState, useEffect } from "react";

import { BreadcrumbsCommon, MenuAccountComponent, StyledFavoritesPage } from "@components";
import { AppModuleLayout } from "@layouts";
import { PATH_FAVORITES, PATH_HOME } from "@routes";
import { selectFavoriteTours, useAppSelector } from "@redux";
import { PaginationModule, TourElementModule } from "@module";
import { useWindowDimensions } from "@utilities";
import { IFavoriteTour, IPagination } from "@interfaces";
import { PAGINATION } from "@constants";

export const Favorites = () => {
    const favoritesTour = useAppSelector(selectFavoriteTours).tours;
    const [pagingFavoritesTour, setPagingFavoritesTour] = useState<IFavoriteTour[]>(favoritesTour.slice(0, PAGINATION));
    const [currentPage, setCurrentPage] = useState<number>(1);
    const isShowPagination = favoritesTour.length > 10;
    const { width } = useWindowDimensions();
    const listPagePoints: IPagination[] = [];

    const pages = Math.ceil(favoritesTour.length / PAGINATION);
    // xử lý số page cần có để hiển thị dữ liệu
    for (let i = 1; i <= pages; i++) {
        listPagePoints.push({ id: i });
    }

    useEffect(() => {
        if (favoritesTour.length <= 10) {
            setCurrentPage(1);
        }
    }, [favoritesTour]);


    useEffect(() => {
        const startPoint = (currentPage - 1) * PAGINATION;
        const endPoint = currentPage * PAGINATION;
        setPagingFavoritesTour([...favoritesTour.slice(startPoint, endPoint)]);
    }, [currentPage, favoritesTour]);

    const breadcrumbsData = [
        {
            title: "Home",
            link: PATH_HOME,
        },
        {
            title: "Favourites",
            link: PATH_FAVORITES,
        },
    ];

    const handleLoadMoreMobile = () => {
        if (pagingFavoritesTour.length === favoritesTour.length) {
            return;
        }
        setPagingFavoritesTour([
            ...pagingFavoritesTour, 
            ...favoritesTour.slice(pagingFavoritesTour.length, pagingFavoritesTour.length + PAGINATION)
        ]);
    };

    const handleSetCurrentPage = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    return (
        <AppModuleLayout>
            <StyledFavoritesPage>
                <div className="wrapper">
                    <div className="breadCrumbs">
                          {/* xử lý breadcrumbs */}
                        <BreadcrumbsCommon data={breadcrumbsData} />
                    </div>
                    <div className="menu">
                        <MenuAccountComponent />
                    </div>
                    <div className="listProduct">
                        <p className="heading1">Favourites</p>
                        <div className="row">
                            {width >= 767 ? pagingFavoritesTour.map((tour) => {
                                return (
                                    <div className="col c-12 m-6 l-6" key={tour.productId}>
                                        <div className="destinationWrapper">
                                            <TourElementModule data={tour} />
                                        </div>
                                    </div>
                                );
                            }) : (
                                <InfiniteScroll
                                    dataLength={pagingFavoritesTour.length}
                                    next={handleLoadMoreMobile}
                                    hasMore={true}
                                    loader={<></>}
                                >
                                    {pagingFavoritesTour.map((tour) => {
                                        return (
                                            <div className="col c-12 m-6 l-6" key={tour.productId}>
                                                <div className="destinationWrapper">
                                                    <TourElementModule data={tour} />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </InfiniteScroll>
                            )}
                        </div>
                    </div>
                </div>
                {isShowPagination && width >= 767 ? (
                    <div className="pagination">
                        <PaginationModule
                            onSetCurrentPage={handleSetCurrentPage}
                            listPagePoints={listPagePoints}
                            currentPage={currentPage}
                        />
                    </div>
                ) : (
                    <></>
                )}
            </StyledFavoritesPage>
        </AppModuleLayout>
    );
};
