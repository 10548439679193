import { useRef, useState, useEffect } from "react";

import { InfoBoxComponent } from "@components";
import { IDescription } from "@interfaces";

interface IProps {
    data: IDescription[];
}
export const InfoBoxModule = (props: IProps) => {
    const [currentTagIndex, setCurrentTagIndex] = useState(0);
    const [isActiveNav, setIsActiveNav] = useState(false);
    const listNavItemRef = useRef({});
    const infoBoxRef = useRef(null);
    const { data } = props;
    const handleEnter = (index: number) => {
        setCurrentTagIndex(index);
    };
    // useEffect(() => {
    //     //@ts-ignore
    //     if (listNavItemRef.current[currentTagIndex]) {
    //         //@ts-ignore
    //         // listNavItemRef.current[currentTagIndex].scrollIntoView();
    //     }
    // }, [currentTagIndex]);
    
    useEffect(() => {
        const handleScroll = () => {
            if (!infoBoxRef.current) {
                return;
                //@ts-ignore
            } else if (window.scrollY >= infoBoxRef.current.offsetTop) {
                setIsActiveNav(true);
            } else {
                setIsActiveNav(false);
            }
            if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 2) { // Không scroll được chính xác xuống bottom do sai số
                setCurrentTagIndex(data.length - 1);
            }
        };
        document.addEventListener("scroll", handleScroll);
        return () => {
            document.removeEventListener("scroll", handleScroll);
        };
    }, []);
    const handleGetInfoBoxRef = (ref: any) => {
        infoBoxRef.current = ref;
    };
    const handleGetNavItemRef = (index: number, ref: any) => {
        //@ts-ignore
        listNavItemRef.current[index] = ref;
    };
    return (
        <InfoBoxComponent 
            data={data} 
            currentTagIndex={currentTagIndex}
            _onEnter={handleEnter}
            isActiveNav={isActiveNav}
            onGetInfoBoxRef={handleGetInfoBoxRef}
            onGetNavItemRef={handleGetNavItemRef}
        />
    );
};