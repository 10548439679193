import { isObject } from "lodash";
import { PATH_DETAIL_TOUR } from "./../Routes/index";
import { phpHelper } from "src/Utilities/phpHelper";

import { lsEmail } from "@utilities";
import { IAgentInfo, ISendLogEmail } from "@interfaces";

const email = "support@freelancetravel.com";
// const email = "james.nguyen@adamosoft.com";

const jsonLine = /^( *)("[\w]+": )?("[^"]*"|[\w.+-]*)?([,[{])?$/gm;

const replacer = (match: string, pIndent: string, pKey: string, pVal: any, pEnd: string) => {
    const key = "<span class=json-key>";
    const val = "<span class=json-value>";
    const str = "<span class=json-string>";
    let r = pIndent || "";
    if (pKey) r = r + key + pKey.replace(/[": ]/g, "") + "</span>: ";
    if (pVal) {
        r = r + (pVal[0] === '"' ? str : val) + pVal + "</span>";
    }
    return r + (pEnd || "");
};
const prettyPrint = (obj: any) => {
    return JSON.stringify(obj, null, 3)
        .replace(/&/g, "&amp;")
        .replace(/\\"/g, "&quot;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(jsonLine, replacer);
};

export const logger = {
    error: (context: string, message: string) => {
        const title = "Freelance Travel Error new";
        const mail = `There is an error in <b>${context}</b>: <br/>
            <pre style="font-weight:bold;"><code>${
                isObject(message) && prettyPrint(JSON.parse(message))
            }</code></pre>`;
        const payload: ISendLogEmail = {
            title,
            mail,
            email,
        };
        phpHelper.sendLogEmail(payload);
    },
    apiError: (url: string, response: any) => {
        const title = "Freelance Travel Error";
        const mail = `There is an API error in the call of: <span style="color:red">${url}</span> <br/>
            <pre style="font-weight:bold;"><code>${prettyPrint(response)}</code></pre>`;
        const payload: ISendLogEmail = {
            title,
            mail,
            email,
        };
        phpHelper.sendLogEmail(payload);
    },
    agentInfo: (agent: IAgentInfo) => {
        const agentEmail = lsEmail.getItem();
        const title = "Freelance Travel Updated Agent info";
        const mail = `New agent info updated:<br/>
            <b>Agent email:</b> ${agentEmail}<br/>
            <b>Agent trading name:</b> ${agent.tradingname}<br/>
            <b>Agent business number:</b> ${agent.businessnumber}<br/>
            <b>Agent bank account:</b> ${agent.bankAccount}<br/>
            <b>Agent bank BSB:</b> ${agent.bankBsb}<br/>`;
        const payload: ISendLogEmail = {
            title,
            mail,
            email,
        };

        phpHelper.sendLogEmail(payload);
    },
    getFareError: (
        name: string,
        productId: string,
        fareName: string,
        productPricesDetailsId: string
    ) => {
        const origin = window.location.origin;
        const title = "Freelance Error - Product Details / Fare";
        const mail = `Please note there is a problem with loading this product / fare<br/>
            <b>Product Name:</b> ${name}<br/>
            <b>Product Id:</b> ${productId}<br/>
            <b>Fare Name:</b> ${fareName}<br/>
            <b>Product Price Details ID:</b> ${productPricesDetailsId}<br/>
            <b>Product Link:</b> ${origin}${PATH_DETAIL_TOUR}/${productId}<br/>`;
        const payload: ISendLogEmail = {
            title,
            mail,
            email,
        };

        phpHelper.sendLogEmail(payload);
    },
    cancelBooking: (
        agentEmail: string,
        agentName: string,
        orderId: string,
        bookingReference: string,
        customerName: string,
        customerEmail: string,
        quantity: string,
        reason: string,
        supplierName: string,
        productName: string,
        fareName: string,
        cancelDes: string,
        cancelRules: {
            daysBeforeTravelDate: string;
            feeAmount: string;
        }[]
    ) => {
        const title = "Freelance Refund Request";
        const rule = cancelRules?.map(
            (rule) =>
                `<p><b>Cancellation Fee Policy Rules</b>: 
                Cancelled ${rule.daysBeforeTravelDate} days before travel:
                ${rule.feeAmount}% fee
            </p>`
        );
        const mail = `An agent has requested a refund on behalf of the customer.
        <br/>
        <p><b>Agent Email</b>: ${agentEmail}</p>
        <p><b>Agent Name</b>: ${agentName}</p>
        <br/>
        <p><b>Order ID</b>: ${orderId}</p>
        <p><b>Order Url</b>: https://tdms.websitetravel.com/#order/${orderId}</p>
        <p><b>BookingReference</b>: ${bookingReference}</p>
        <br/>
        <p><b>Customer Names</b>: ${customerName}</p>
        <p><b>Customer Email</b>: ${customerEmail}</p>
        <p><b>Quantity to Cancel</b>: ${quantity}</p>
        <p><b>Reason to Cancel</b>: ${reason}</p>
        <br/>
        <p><b>Supplier</b>: ${supplierName}</p>
        <p><b>Product</b>: ${productName}</p>
        <p><b>Fare</b>: ${fareName}</p>
        <br/>
        <p><b>Cancellation Fee Policy Description</b>: ${cancelDes}</p>
        ${rule}
        `;
        const payload: ISendLogEmail = {
            title,
            mail,
            email,
        };

        phpHelper.sendLogEmail(payload);
    },
};
