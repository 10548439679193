import { memo } from "react";
import styled from "styled-components";

import { IAccountInfo } from "@interfaces";
import { IHandleBlur, IHandleChange, IHandleSubmit } from "@interfaces";
import { InputCommon, StyledButton } from "@components";
import { breakpoint } from "@utilities";

interface IProps {
    values: IAccountInfo;
    handleChange: IHandleChange;
    handleBlur: IHandleBlur;
    handleSubmit: IHandleSubmit;
    isUpdateAccount?: boolean;
    isSubmitting?: boolean;
}
export const AccountComponent = memo((props: IProps) => {
    const { values, handleChange, handleSubmit, handleBlur, isUpdateAccount, isSubmitting } = props;
    return (
        <Container onSubmit={handleSubmit}>
            <h2 className="title__account">
                {isUpdateAccount ? "Update My Account Details" : "Account Details"}
            </h2>
            <div className="Wrapper">
                <div className="col_48">
                    <InputCommon
                        name="firstName"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.firstName}
                        label="First name *"
                        type="text"
                        required
                    />
                </div>
                <div className="col_48">
                    <InputCommon
                        name="lastName"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        required
                        value={values.lastName}
                        label="Last name *"
                        type="text"
                    />
                </div>
            </div>

            <div className="Wrapper">
                <div className="col_48 m_100">
                    <InputCommon
                        name="tradingname"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.tradingname}
                        label="Registered Trading Name *"
                        required
                        type="text"
                    />
                </div>
                <div className="col_48 m_100">
                    <InputCommon
                        name="businessnumber"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.businessnumber}
                        label="Registered Business Number *"
                        required
                        type="text"
                    />
                </div>
            </div>

            <h2 className="title__account form">
                {isUpdateAccount
                    ? "Update My Commission Payout Details"
                    : "Commission Payout Details"}
            </h2>

            <div className="Wrapper">
                <div className="col_48 m_100">
                    <InputCommon
                        name="bankBsb"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.bankBsb}
                        label="BSB (example: 999-999) *"
                        required
                        type="text"
                    />
                </div>
                <div className="col_48 m_100">
                    <InputCommon
                        name="bankAccount"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.bankAccount}
                        label="Account *"
                        required
                        type="text"
                    />
                </div>
            </div>

            <StyledButton className="submit_account" disabled={isSubmitting} type="submit">
                {isUpdateAccount ? "Update Details" : "Submit"}
            </StyledButton>
            <div className="note">
                <span>
                    *To change your email address please contact{" "}
                    <a href="mailto:support@freelancetravel.com">support@freelancetravel.com</a>.
                    Please note this will also update your account login details.
                </span>
            </div>
        </Container>
    );
});

const Container = styled.form`
    .note {
        color: ${(props) => props.theme.colors.gray_3};
        font-size: 14px;
        margin-top: 1em;
        line-height: 1.25em;
        a {
            color: ${(props) => props.theme.colors.main};
        }
    }

    .Wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .col_48 {
            width: 48%;
        }
        ${breakpoint.breakOnlyMobile`
        .col_48.m_100 {
            width: 100%;
         }
        `};
    }
    .submit_account {
        width: 40%;
        margin: auto;
        margin-top: 50px;
        ${breakpoint.breakOnlyMobile`
        width: 100%;
        margin-top: 30px;
        `};
    }
    .MuiFormControl-root {
        height: 65px;
        margin: 0 !important;
    }
    .title__account {
        margin-top: 28px;
        font-weight: bold;
        margin-bottom: 12px !important;
    }
    .MuiFormControl-root .gFTEKm {
        margin-top: 0 !important;
    }
    label {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
`;
