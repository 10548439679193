import styled from "styled-components";

import { breakpoint } from "@utilities";

interface IFooterContactItem {
    img: string;
    title: string;
}

const FooterContactItem = (props: IFooterContactItem) => {
    const { img, title } = props;

    return (
        <StyledContactItem>
            <p className="icon">
                <img src={img} alt={title} />
            </p>
            <span className="contactTitle">{title}</span>
        </StyledContactItem>
    );
};

const StyledContactItem = styled.div`
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    .icon {
        img {
            width: 15px;

            ${breakpoint.breakIpadPro`
                width: 18px;
            `}

            ${breakpoint.breakLarge`
                width: 24px;
            `}
        }
    }
    .contactTitle {
        color: #fff;
        margin-left: 1em;
        font-size: 10px;
        font-weight: 400;

        ${breakpoint.breakIpadPro`
            font-size: 12px;
        `}

        ${breakpoint.breakLarge`
            font-size: 15px;
        `}
    }
`;

export default FooterContactItem;
