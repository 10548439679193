import styled from "styled-components";

import { breakpoint } from "@utilities";

export const StyledSearchResultDesktop = styled.div<{
    isShowSortMenu: boolean;
}>`
    display: none;
    background-color: #F2F2F2;
    margin-bottom: -40px;

    ${breakpoint.breakTablet`
        display: block;
    `}

    .wrapper {
        padding-top: 61px;
        display: flex;
        margin: 0 auto;
        width: ${(props) => props.theme.widths.wide_700};

        ${breakpoint.breakIpadPro`
            margin: 0 auto;
            width: ${(props) => props.theme.widths.wide_900};
        `}

        ${breakpoint.breakLarge`
            width: ${(props) => props.theme.widths.wide_1110};
        `}

        .filter {
            flex: 3;
            position: relative;

            .breadcrumbs {
                position: absolute;
                bottom: calc(100% + 10px);
            }
        }

        .notFoundTour {
            text-align: center;
            font-weight: 700;
            font-size: 20px;
            padding-top: 12px;
        }

        .result {
            flex: 6;
            min-height: 1200px;
            display: flex;
            flex-direction: column;

            .listSearchResult {
                flex: 1;
            }

            .summary {
                display: flex;
                justify-content: space-between;

                .numberOfResult {
                    font-weight: 700;
                    font-size: 19px;
                    margin-bottom: 8px;
                    margin-top: 5px;

                    ${breakpoint.breakLarge`
                        margin-bottom: 16px;
                        margin-top: 25px;
                    `}
                }

                .sortBy {
                    display: flex;
                    align-items: center;
                    font-weight: 700;
                    font-size: 14px;
                }
            }

            .sortBy {
                cursor: pointer;
                position: relative;

                img {
                    margin-right: 6px;
                }

                .overlay {
                    background-color: transparent;
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100vw;
                    height: 100vh;
                    z-index: 2;
                }
            }
        }
    }

    .sortMenu {
        position: absolute;
        display: ${(p) => (p.isShowSortMenu ? "block" : "none")};
        top: 100%;
        left: 0;
        width: 100%;
        z-index: 2;
        overflow: hidden;
        box-shadow: 8px 22px 35px rgba(0, 0, 0, 0.16);

        .menuItem {
            cursor: pointer;
            font-size: 20px;
            text-align: center;
            height: 25px;
            line-height: 25px;
            z-index: 2;
            font-weight: 400;
            ${breakpoint.breakTablet`
                font-size: 14px;
            `}

            ${breakpoint.breakIpadPro`
                font-size: 16px;
            `}

            ${breakpoint.breakLarge`
                font-size: 18px;
            `}
        }

    }

    .breakPagination {
        margin-left: 10px;
        display: flex;
        align-items: center;
        ${breakpoint.breakIpadPro`
            margin-right: -5px;
            margin-left: 15px;
        `}
    }
`;